import {
  CurrencyField,
  GiftItemType,
  IGiftItemResponse,
  IOrganisationResponse,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { valueTest } from '../../../../helpers/GiftItemValidityHelper'
import { IGiftItemPostBody } from '../../../../types/GiftItemTypes'

interface IItemPriceProps {
  label: string
  giftItem: IGiftItemResponse
  changeItem: (changes: IGiftItemPostBody) => void
  isEditing: boolean
  organisation: IOrganisationResponse
}

export default function ItemPrice(props: IItemPriceProps): ReactElement {
  const {
    label, giftItem, changeItem, isEditing, organisation,
  } = props

  const priceMessage = giftItem?.type === GiftItemType.Monetary
    ? 'Add the values you wish to display, separated by commas, e.g. 10,20,30,40 etc.'
    : 'Price including VAT'

  const priceMultiValueError = giftItem?.type === GiftItemType.Monetary
    && giftItem?.value?.includes('.')
    && 'Monetary vouchers should have whole numbers only'

  const priceError = Boolean(priceMultiValueError)
    || (isEditing && Boolean(valueTest({ giftItem, organisation })))

  const priceSubtitle = valueTest({ giftItem, organisation })
    || priceMultiValueError
    || priceMessage

  return (
    <CurrencyField
      label={label}
      currency={organisation.currencyCode}
      onChange={(value): void => changeItem({ value })}
      initValue={giftItem.value}
      isSorting={true}
      isMulti={giftItem.type === GiftItemType.Monetary}
      isError={priceError}
      subtitle={priceSubtitle}
      className="input-short"
    />
  )
}
