import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { alertSuccess, Datepicker } from '@one-tree/library'
import { IEditGiftCardProps } from '../../../../types/API'
import EditableTableRow from './EditableTableRow'
import { extendGiftCard } from '../../../../helpers/APIHelper'

function ExtendExpiryDate(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props

  const [expiryDate, setExpiryDate] = useState(giftCard.expiryDate)
  const [extendExpiryReason, setExtendExpiryReason] = useState('')
  const [extendExpiryIsEditing, setExtendExpiryIsEditing] = useState(false)
  const handleExtendExpiry = async (): Promise<void> => {
    const result = await extendGiftCard({
      cardId: giftCard.id,
      reason: extendExpiryReason,
      expiryDate,
    })
    if (result) {
      alertSuccess('Expiry date changed')
      setExpiryDate(result.expiryDate)
      setExtendExpiryReason('')
      setExtendExpiryIsEditing(false)
      setGiftCard(result)
    }
  }

  return (
    <EditableTableRow
      title="Expiry date"
      isEditing={extendExpiryIsEditing}
      setIsEditing={setExtendExpiryIsEditing}
      editReason={extendExpiryReason}
      setEditReason={setExtendExpiryReason}
      saveAction={(): Promise<void> => handleExtendExpiry()}
      cancelAction={(): void => {
        setExtendExpiryReason('')
        setExpiryDate(giftCard.expiryDate)
      }}
    >
      <Datepicker
        value={moment.unix(expiryDate)}
        onChange={(date): void => setExpiryDate(moment(date).unix())}
        isLong={true}
        futureOnly={true}
      />
    </EditableTableRow>
  )
}
export default ExtendExpiryDate
