import React, {
  ReactElement, SyntheticEvent, useEffect, useState,
} from 'react'
import { GridItem } from 'react-grid-dnd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  FiMove as MoveIcon,
  FiCornerDownRight as IndentedIcon,
} from 'react-icons/fi'
import download from 'downloadjs'
import {
  Button,
  ButtonSize,
  ButtonStyle,
  capitalize,
  colors,
  Switch,
  useNotFirstRender,
} from '@one-tree/library'
import { CategoryStatus, ICategoryResponse } from '../../../types/API'
import { RoutePath } from '../../../types/Routes'
import { StateAction } from '../../../types/Aliases'
import { patchCategory } from '../../../helpers/APIHelper'
import { requiresImage } from '../../../helpers/CategoryHelper'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { OrganisationFormat } from '../../../types/Types'
import { getCategoryGuestlist } from '../../../helpers/APIItemHelper'

const StyledGridItem = styled(GridItem)`
  border-radius: 4px;
  display: flex;
  align-items: center;
  max-width: calc(100% - 20px);
  max-height: 40px;
  user-select: none;
  svg {
    height: 25px;
    width: 50px;
    color: ${colors.black};
  }
  cursor: default !important;
  &.category-dragging {
    cursor: grabbing !important;
    transform-origin: left;
  }
  .category-bar {
    transition: background-color 0.2s;
    background-color: ${colors.white};
    padding-left: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  &.category-unpublished .category-bar {
    background-color: ${colors.lightGray};
  }
`

const DragHandle = styled.div`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 40px;
  width: 40px;
  background: ${colors.gray};
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 25px;
    width: 25px;
    color: ${colors.white};
  }
`

const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  column-gap: 10px;
  button:last-child {
    margin-right: 12px;
  }
`

interface ICategoryGridItemProps {
  category: ICategoryResponse
  setIsHovering: StateAction<boolean>
  disabled: boolean
  getIsIndented: (indentation: boolean) => void
  getCurrentDrag: (categoryId: number) => void
  fetchCategories: () => void
  setCategoryToRedeem: (id: number) => void
}

function CategoryGridItem(props: ICategoryGridItemProps): ReactElement {
  const {
    category,
    setIsHovering,
    disabled,
    getIsIndented,
    getCurrentDrag,
    fetchCategories,
    setCategoryToRedeem,
  } = props
  const history = useHistory()
  const notFirstRender = useNotFirstRender()
  const { organisation } = useOrganisation()
  const [dragging, setDragging] = useState(false)
  const indentedInit = Boolean(category.parent) && !category.isParent
  const [isIndented, setIsIndented] = useState<boolean>(indentedInit)

  useEffect(() => {
    if (notFirstRender) {
      getIsIndented(isIndented)
      getCurrentDrag(category.id)
    }
  }, [isIndented, dragging])

  useEffect(() => {
    // if the categories change, reset
    setDragging(false)
    setIsIndented(indentedInit)
  }, [category])

  const [canIndent, setCanIndent] = useState(true)
  const debounceIndent = (indent: boolean): void => {
    // if we are changing the indent...
    if (canIndent && indent !== isIndented) {
      setIsIndented(indent)
      // ...don't allow another change for a second
      setCanIndent(false)
      setTimeout(() => setCanIndent(true), 1000)
    }
  }

  // define our outer limits for indenting/outdenting by dragging the mouse
  const MAX_INDENT_PIXELS = 60
  const MIN_INDENT_PIXELS = 0
  // set default as our max, to help keep indented things indented
  const [mousePositionArray, setMousePositionArray] = useState<number[]>([
    MAX_INDENT_PIXELS,
  ])
  const nestingController = (mousePosition: number): void => {
    // take the last 50 mouse positions and save them in an array
    const sampleSize = 50
    setMousePositionArray((prevState) => [mousePosition, ...prevState].slice(0, sampleSize))
    const sum = mousePositionArray.reduce((a, b) => a + b)
    const averageMousePosition = sum / sampleSize

    // if it has a parent, make it harder to remove indent
    if (category.parent !== 0) {
      if (averageMousePosition > MIN_INDENT_PIXELS) {
        debounceIndent(true)
      } else {
        debounceIndent(false)
      }
    }
    // if it has no parent, make it harder to add indent (also cannot nest parents)
    if (category.parent === 0) {
      if (averageMousePosition > MAX_INDENT_PIXELS && !category.isParent) {
        debounceIndent(true)
      } else {
        debounceIndent(false)
      }
    }
  }

  const handlePublishSwitch = async (publish: boolean): Promise<void> => {
    await patchCategory({
      categoryId: category.id,
      patchOptions: {
        status: publish ? CategoryStatus.Published : CategoryStatus.Unpublished,
      },
    })
    fetchCategories()
  }

  const handleGuestlist = async (): Promise<void> => {
    const res = await getCategoryGuestlist({ id: category.id })
    if (res) {
      const notChars = /[_\W]+/g
      download(res, `guestlist-${category.title.replace(notChars, '')}.csv`)
    }
  }

  const dragClass = dragging ? 'category-dragging' : ''
  const statusClass = category.status === CategoryStatus.Unpublished ? 'category-unpublished' : ''

  const categoryInvalid = requiresImage(category, organisation) && !category.imageUrl

  return (
    <StyledGridItem
      className={`${dragClass} ${statusClass}`}
      onMouseMove={(
        event: SyntheticEvent<HTMLDivElement, MouseEvent>,
      ): void => {
        if (dragging && !disabled) {
          // TODO "layerX" is non-standard and not supported, need to find alternative
          // @ts-ignore
          nestingController(event.nativeEvent.layerX)
        } else {
          setMousePositionArray([MAX_INDENT_PIXELS])
        }
      }}
      onMouseUp={(): void => setDragging(false)}
    >
      {isIndented && <IndentedIcon />}
      <DragHandle
        onMouseEnter={(): void => setIsHovering(true)}
        onMouseDown={(): void => setDragging(true)}
        onMouseLeave={(): void => setIsHovering(false)}
      >
        <MoveIcon />
      </DragHandle>
      <div className="category-bar">
        {category.title}
        <ButtonGroup>
          <Switch
            switchAction={(isOn): Promise<void> => handlePublishSwitch(isOn)}
            value={category.status === CategoryStatus.Published}
            label={categoryInvalid ? 'Requires image' : capitalize(category.status)}
            disabled={
              category.status === CategoryStatus.Unpublished && categoryInvalid
            }
          />
          {organisation?.format === OrganisationFormat.Tickets && (
            <>
              <Button
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Mini}
                onClick={handleGuestlist}
              >
                Download guestlists
              </Button>
              <Button
                buttonStyle={ButtonStyle.Secondary}
                buttonSize={ButtonSize.Mini}
                onClick={(): void => setCategoryToRedeem(category.id)}
              >
                Redeem all
              </Button>
            </>
          )}
          <Button
            buttonStyle={ButtonStyle.Primary}
            buttonSize={ButtonSize.Mini}
            onClick={(): void => history.push(`${RoutePath.ManageCategories}/${category.id}`)}
          >
            Edit
          </Button>
        </ButtonGroup>
      </div>
    </StyledGridItem>
  )
}
export default CategoryGridItem
