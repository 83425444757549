import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonStyle } from '@one-tree/library'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { get } from '../../helpers/APIHelper'
import { sortCategories } from '../../helpers/CategoryHelper'
import { capitalize } from '../../helpers/DataTransformer'
import { itemWord } from '../../helpers/FormatHelper'
import { ICategoryResponse, Resource } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import CategoryGrid from './categoryGrid/CategoryGrid'

function ManageCategories(): ReactElement {
  const history = useHistory()
  const { organisation } = useOrganisation()
  const [categories, setCategories] = useState<ICategoryResponse[]>([])
  const [loading, setLoading] = useState(false)

  if (organisation && !organisation.categoriesEnabled) {
    history.push(RoutePath.ManageItems)
  }

  const fetchCategories = async (): Promise<void> => {
    setLoading(true)
    const res = await get<ICategoryResponse[]>({ resource: Resource.Categories })
    if (res) {
      setCategories(sortCategories(res))
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <>
      <CardHeader title="Manage categories">
        <Button
          buttonStyle={ButtonStyle.Action}
          onClick={(): void => history.push(RoutePath.CreateCategory)}
        >
          Create new category
        </Button>
      </CardHeader>
      <CardContent>
        <p>
          {`Categories are a way to display ${itemWord(
            organisation,
          )}s in custom groups on your web shop. Once you create categories here, you can assign ${itemWord(
            organisation,
          )}s to them.`}
        </p>
        <ol>
          <li>Create required categories</li>
          <li>
            {`Navigate to the '${capitalize(
              itemWord(organisation),
            )}s' tab above, and create a new ${itemWord(
              organisation,
            )} (or edit an existing ${itemWord(organisation)})`}
          </li>
          <li>
            {`Select the categories where the ${itemWord(
              organisation,
            )} should appear from the drop-down list`}
          </li>
        </ol>
        <p>
          You can re-arrange the order the categories appear on your web shop by
          dragging them up and down the list below. You can also create
          sub-categories by dragging a category name to the right.
        </p>
        <CategoryGrid
          categories={categories}
          setCategories={setCategories}
          fetchCategories={fetchCategories}
          loading={loading}
          setLoading={setLoading}
        />
      </CardContent>
    </>
  )
}
export default ManageCategories
