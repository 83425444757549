import {
  Checkbox,
  GiftItemStatus,
  IGiftItemResponse,
  ITicketQuestion,
  StateAction,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IGiftItemPostBody } from '../../../../../types/GiftItemTypes'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-bottom: 3px;
`
type Props = {
  question: ITicketQuestion
  questions: number[]
  ticket: IGiftItemResponse
  changeTicket: (changes: IGiftItemPostBody) => void
  setDisableDelete: StateAction<number[]>
}
export default function EnableQuestion(props: Props): ReactElement {
  const {
    question, questions, ticket, changeTicket, setDisableDelete,
  } = props
  const { prices } = ticket

  const activePrices = ticket.prices
    && [...ticket.prices].filter(
      ({ status }) => status !== GiftItemStatus.Archived,
    )

  const enableQuestionOnParent = (): void => {
    changeTicket({ enabledQuestionIds: [...questions, question.id] })
    setDisableDelete((p) => [...p, question.id])
  }
  const disableQuestionOnParent = (): void => {
    const filtered = questions.filter((id) => id !== question.id)
    changeTicket({ enabledQuestionIds: [...filtered] })
    setDisableDelete((p) => p.filter((id) => id !== question.id))
  }

  // If this Ticket has active Prices:
  if (prices?.length && activePrices?.length) {
    // Disable this question on the Ticket itself - Prices have their own questions
    if (ticket.enabledQuestionIds?.includes(question.id)) {
      disableQuestionOnParent()
    }

    return (
      <Styles>
        {activePrices.map((price) => {
          const { id, name, enabledQuestionIds } = price

          const handleChange = (value: boolean): void => {
            const priceIndex = prices.findIndex((each) => each.id === id)
            const updatedPrices = [...prices]

            if (value) {
              const updatedPrice = {
                ...price,
                enabledQuestionIds: [
                  ...(enabledQuestionIds || []),
                  question.id,
                ],
              }

              updatedPrices[priceIndex] = updatedPrice

              setDisableDelete((p) => [...p, question.id])
            } else {
              const updatedPrice = {
                ...price,
                enabledQuestionIds: enabledQuestionIds?.filter(
                  (questionId) => questionId !== question.id,
                ),
              }

              updatedPrices[priceIndex] = updatedPrice

              const noPriceHasThisQuestion = !updatedPrices
                .map((each) => each.enabledQuestionIds?.includes(question.id))
                .some((each) => each === true)

              if (noPriceHasThisQuestion) {
                setDisableDelete((p) => p.filter((each) => each !== question.id))
              }
            }

            changeTicket({ prices: updatedPrices })
          }

          return (
            <Checkbox
              key={`${name}${id}`}
              label={name}
              value={enabledQuestionIds?.includes(question.id) || false}
              onChange={handleChange}
            />
          )
        })}
      </Styles>
    )
  }

  // If Ticket does not have active Prices, disable questions on inactive prices and render normally
  if (prices) {
    prices.forEach((each, index) => {
      if (each.enabledQuestionIds?.includes(question.id)) {
        const updatedPrices = [...prices]

        const updatedPrice = {
          ...each,
          enabledQuestionIds: each.enabledQuestionIds.filter(
            (questionId) => questionId !== question.id,
          ),
        }

        updatedPrices[index] = updatedPrice

        changeTicket({ prices: updatedPrices })
      }
    })
  }

  return (
    <Checkbox
      value={questions.includes(question.id) || false}
      onChange={(value): void => (value ? enableQuestionOnParent() : disableQuestionOnParent())}
    />
  )
}
