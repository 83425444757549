import {
  IGiftItemResponse,
  EXAMPLE_GIFT_ITEM_IMAGE_URL,
  GiftItemStatus,
} from '@one-tree/library'

export const ExampleGiftItem: IGiftItemResponse = {
  value: '10',
  name: 'Header text - this is the name of the gift',
  description:
    'This is the body text which appears on your website for each gift. It also appears on the voucher.',
  details: 'This is where the long description of the gift is displayed.',
  terms: 'This is the extended description, for any further details.',
  imageUrl: EXAMPLE_GIFT_ITEM_IMAGE_URL,
  status: GiftItemStatus.Published,
}

export const ExampleSpecialOfferItem: IGiftItemResponse = {
  value: '10',
  validity: 12,
  name: 'Header text - this is the name of the special offer',
  startDate: 1600000000,
  terms: 'This is the description of the special offer.',
  imageUrl: EXAMPLE_GIFT_ITEM_IMAGE_URL,
  expiryDate: 1700000000,
  restrictions: 'This is important information regarding the special offer.',
  showRemaining: true,
  quantity: 3,
  originalPrice: 20,
  quantitySold: 0,
  status: GiftItemStatus.Published,
}

export const ExampleTicketItem: IGiftItemResponse = {
  value: '10',
  name: 'Header text - the name of the ticket',
  terms: 'This is the description of the ticket.',
  imageUrl: EXAMPLE_GIFT_ITEM_IMAGE_URL,
  showRemaining: true,
  quantity: 3,
  startInfo: '1st January 2024',
  quantitySold: 0,
  status: GiftItemStatus.Published,
}

export const ExampleGenericItem: IGiftItemResponse = {
  id: 0,
  type: 'test',
  value: '10',
  validity: 0,
  name: 'Header text - the name of the gift',
  startDate: 1600000000,
  description:
    'This is the body text which appears on your website for each gift. It also appears on the voucher.',
  terms: '-',
  details: '-',
  imageUrl: EXAMPLE_GIFT_ITEM_IMAGE_URL,
  imageFileId: 0,
  order: 0,
  expiryDate: 1700000000,
  restrictions: '-',
  publishDate: 0,
  unpublishDate: 0,
  showRemaining: false,
  maxPerOrder: 0,
  quantity: 0,
  headerImageUrl: '-',
  originalPrice: 20,
  status: GiftItemStatus.Published,
  categoryIds: [0],
  enabledQuestionIds: [0],
  embedUrl: 'https://development.one-tree.net/embeditem-TESTjs',
  startInfo: '1st January 2024',
  quantitySold: 0,
}
