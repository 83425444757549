import moment from 'moment'
import React, { ReactElement, useState } from 'react'
import { alertSuccess, Datepicker } from '@one-tree/library'
import { IEditGiftCardProps } from '../../../../types/API'
import EditableTableRow from './EditableTableRow'
import { patchGiftCard } from '../../../../helpers/APIHelper'

function ChangeDispatchDate(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props

  const [dispatchDate, setDispatchDate] = useState(giftCard.deliveryDate)
  const [editReason, setEditReason] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const handleChange = async (): Promise<void> => {
    const result = await patchGiftCard({
      cardId: giftCard.id,
      patchOptions: {
        deliveryDate: dispatchDate,
        reason: editReason,
      },
    })

    if (result) {
      alertSuccess('Dispatch date changed')
      setDispatchDate(result.deliveryDate)
      setEditReason('')
      setIsEditing(false)
      setGiftCard(result)
    }
  }

  return (
    <EditableTableRow
      title="Dispatch date"
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      editReason={editReason}
      setEditReason={setEditReason}
      saveAction={handleChange}
      cancelAction={(): void => {
        setEditReason('')
        setDispatchDate(giftCard.deliveryDate)
      }}
    >
      <Datepicker
        value={moment.unix(dispatchDate)}
        onChange={(date): void => setDispatchDate(moment(date).unix())}
        isLong={true}
        futureOnly={true}
      />
    </EditableTableRow>
  )
}
export default ChangeDispatchDate
