import { theme, ZIndex } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  width: 100%;
  position: fixed;
  z-index: ${ZIndex.AboveContent - 1};
  &.up-fast {
    top: ${theme.headerHeight};
    height: 40px;
  }
  &.up-slow {
    top: calc(${theme.headerHeight} + 40px);
    height: 40px;
  }
  &.down-slow {
    bottom: 40px;
    height: 40px;
  }
  &.down-fast {
    bottom: 0;
    height: 40px;
  }
`

export enum ScrollSpeed {
  Slow = 'slow',
  Fast = 'fast',
}
export enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}
interface IScrollZoneProps {
  disabled: boolean
  speed: ScrollSpeed
  direction: ScrollDirection
}

const getVelocity = (
  speed: ScrollSpeed,
  direction: ScrollDirection,
): number => {
  if (speed === ScrollSpeed.Fast) {
    if (direction === ScrollDirection.Up) {
      return -50
    }
    return 50
  }
  if (direction === ScrollDirection.Up) {
    return -10
  }
  return 10
}

const interval = {
  intervals: new Set(),
  create(task: () => void, time: number): void {
    const newInterval = setInterval(task, time)
    this.intervals.add(newInterval)
  },
  clearAll(): void {
    this.intervals.forEach((id) => {
      if (typeof id === 'number') clearInterval(id)
    })
  },
}

function ScrollZone(props: IScrollZoneProps): ReactElement {
  const { disabled, speed, direction } = props

  const startScroll = (): void => {
    if (!disabled) {
      interval.create(() => {
        window.scroll(0, window.scrollY + getVelocity(speed, direction))
      }, 20)
    } else {
      interval.clearAll()
    }
  }

  return (
    <Styles
      className={`${direction}-${speed}`}
      onMouseEnter={startScroll}
      onMouseLeave={(): void => interval.clearAll()}
    />
  )
}
export default ScrollZone
