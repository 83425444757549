import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Hyperlink,
  Loading,
  alertError,
  InvoiceAddress,
  InvoiceGrid,
  InvoiceDetail,
  InvoiceItem,
  CurrencyCode,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import { useOrganisation } from '../../context/OrganisationProvider'
import { get } from '../../helpers/APIHelper'
import { capitalize, parseString } from '../../helpers/DataTransformer'
import { formatInvoiceDateWithTime } from '../../helpers/DateHelper'
import Page from '../../hoc/Page'
import { IInvoiceItem, IInvoiceResponse, Resource } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import { IIDParam } from '../../types/Types'
import InvoiceHeader from './InvoiceHeader'
import { voucherWord } from '../../helpers/FormatHelper'

function OrderInvoice(): ReactElement {
  const [invoice, setInvoice] = useState<IInvoiceResponse | null>(null)
  const { id }: IIDParam = useParams()
  const { organisation } = useOrganisation()
  const { currencyCode } = organisation || { currencyCode: CurrencyCode.GBP }

  useEffect(() => {
    (async (): Promise<void> => {
      if (id) {
        setInvoice(
          (await get<IInvoiceResponse>({
            resource: Resource.OrderInvoices,
            urlPath: [id],
          })) || null,
        )
      } else {
        alertError('No invoice ID specified')
      }
    })()
  }, [])

  return (
    <Page>
      <Card cardSize={CardSize.Medium}>
        {!invoice || !organisation ? (
          <Loading fullPage={true} />
        ) : (
          <>
            <InvoiceHeader
              id={invoice.id}
              url={invoice.pdfUrl}
              backLink={RoutePath.Invoices}
            />
            <CardContent>
              <InvoiceGrid>
                <section className="billingFrom">
                  <InvoiceAddress
                    name={organisation.name}
                    lineOne={organisation.addressLineOne}
                    lineTwo={organisation.addressLineTwo}
                    town={organisation.addressTown}
                    county={organisation.addressCounty}
                    postcode={organisation.addressPostcode}
                  />
                </section>
                <section className="details">
                  <InvoiceDetail header="Order ID" detail={invoice.id} />
                  <InvoiceDetail
                    header="Order date"
                    detail={formatInvoiceDateWithTime(invoice.date)}
                  />
                  <InvoiceDetail
                    header="Order type"
                    detail={invoice.type && capitalize(invoice.type)}
                  />
                  {invoice.manualUser && (
                    <InvoiceDetail
                      header="Issued by"
                      detail={invoice.manualUser}
                    />
                  )}
                  <InvoiceDetail
                    header="Payment ref"
                    detail={invoice.paymentReference}
                  />
                  <InvoiceDetail
                    header="Status"
                    detail={capitalize(invoice.status)}
                  />
                </section>
                <section className="billingTo">
                  <div>Bill to:</div>
                  <InvoiceAddress
                    name={`${invoice.purchaserFirstName} ${invoice.purchaserLastName}`}
                    lineOne={invoice.purchaserAddressLineOne}
                    lineTwo={invoice.purchaserAddressLineTwo}
                    town={invoice.purchaserAddressTown}
                    county={invoice.purchaserAddressCounty}
                    postcode={invoice.purchaserAddressPostcode}
                  />
                </section>
                <section className="items">
                  <div className="description">
                    <strong>Description</strong>
                  </div>
                  <div className="amount">
                    <strong>Amount</strong>
                  </div>
                  {invoice.items?.map((item: IInvoiceItem) => (
                    <InvoiceItem
                      description={(
                        <Hyperlink
                          href={`${RoutePath.VoucherDetail}/${item.id}`}
                        >
                          {parseString(item.description)}
                        </Hyperlink>
                      )}
                      amount={item.value}
                      currencyCode={currencyCode}
                    />
                  ))}
                  <InvoiceItem
                    description={`${capitalize(
                      voucherWord(organisation),
                    )} total`}
                    amount={invoice.subtotal || 0}
                    currencyCode={currencyCode}
                  />
                  <InvoiceItem
                    description="Delivery total"
                    amount={invoice.delivery || 0}
                    currencyCode={currencyCode}
                  />
                  <InvoiceItem
                    description="Total"
                    amount={invoice.total || 0}
                    currencyCode={currencyCode}
                  />
                </section>
              </InvoiceGrid>
            </CardContent>
          </>
        )}
      </Card>
    </Page>
  )
}
export default OrderInvoice
