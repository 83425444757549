import React, { ReactElement } from 'react'
import {
  DeliverTo, DeliveryFormat, GiftCardStatus, hasRole, IGiftCardResponse, ISelectOption, Role,
} from '@one-tree/library'
import {
  OrganisationFormat,
  OrganisationUser,
} from '../types/Types'
// eslint-disable-next-line import/no-cycle
import GiftCardStatusBox, {
  StatusBoxSize,
} from '../components/GiftCardStatusBox'
import {
  IGiftCardPatchBody,
} from '../types/API'
import { stripEmptyFromObject, toSentence } from './DataTransformer'

export const isGiftCardValid = (status: GiftCardStatus): boolean => {
  if (
    status === GiftCardStatus.Paid
    || status === GiftCardStatus.Delivered
    || status === GiftCardStatus.Booked
  ) {
    return true
  }
  return false
}

const wordingMap = new Map<OrganisationFormat, string>([
  [OrganisationFormat.GiftVouchers, 'gift voucher'],
  [OrganisationFormat.SpecialOffers, 'special offer'],
  [OrganisationFormat.Tickets, 'ticket'],
])
export const getGiftCardInvalidReason = (
  status: GiftCardStatus,
  format?: OrganisationFormat,
): ReactElement => {
  const cardWording = (format && wordingMap.get(format)) || 'item'
  const statusBox = (
    <GiftCardStatusBox status={status} size={StatusBoxSize.Inline} />
  )
  return (
    <>
      {`The ${cardWording} has `}
      {status !== GiftCardStatus.Expired && 'been '}
      {statusBox}
    </>
  )
}

const statusMap = new Map<GiftCardStatus, string>([
  [GiftCardStatus.Paid, 'Paid'],
  [GiftCardStatus.Delivered, 'Dispatched'],
  [GiftCardStatus.Redeemed, 'Redeemed'],
  [GiftCardStatus.Expired, 'Expired'],
  [GiftCardStatus.Cancelled, 'Cancelled'],
  [GiftCardStatus.Booked, 'Booked'],
])
export const displayStatus = (status: GiftCardStatus): string => statusMap.get(status) || ''

export const statusEnumToOptions = (
  enumObject: typeof GiftCardStatus,
): ISelectOption[] => Object.values(enumObject)
  // remove "booked" from the options as this is a legacy status
  .filter((value) => value !== GiftCardStatus.Booked)
  .map((value) => ({
    value,
    label: <GiftCardStatusBox status={value} size={StatusBoxSize.Dropdown} />,
  }))

export const createStatusOptions = (
  options: GiftCardStatus[],
): ISelectOption[] => {
  const optionsArray: ISelectOption[] = []
  options.forEach((option: GiftCardStatus) => {
    optionsArray.push({
      value: option,
      label: (
        <GiftCardStatusBox status={option} size={StatusBoxSize.Dropdown} />
      ),
    })
  })
  return optionsArray
}

export const isResendingAllowed = (
  user: OrganisationUser,
  giftCard: IGiftCardResponse,
): boolean => {
  if (user && user.role) {
    return (
      hasRole(user.role, Role.OrganisationUser)
      && giftCard.deliveryFormat === DeliveryFormat.Email
    )
  }
  return false
}

export const getAddressFromCard = (
  giftCard: IGiftCardResponse,
  deliverTo: DeliverTo,
): IGiftCardPatchBody => {
  const name = deliverTo === DeliverTo.Recipient
    ? {
      deliveryTitle: giftCard.deliveryTitle,
      deliveryFirstName: giftCard.deliveryFirstName,
      deliveryLastName: giftCard.deliveryLastName,
    }
    : {
      purchaserTitle: giftCard.purchaserTitle,
      purchaserFirstName: giftCard.purchaserFirstName,
      purchaserLastName: giftCard.purchaserLastName,
    }

  return stripEmptyFromObject({
    ...name,
    deliveryAddressLineOne: giftCard.deliveryAddressLineOne,
    deliveryAddressLineTwo: giftCard.deliveryAddressLineTwo,
    deliveryAddressTown: giftCard.deliveryAddressTown,
    deliveryAddressCounty: giftCard.deliveryAddressCounty,
    deliveryAddressPostcode: giftCard.deliveryAddressPostcode,
  })
}

export const renderAddress = (
  address: IGiftCardPatchBody,
  deliverTo: DeliverTo,
): ReactElement => {
  const recipientName = toSentence(
    address.deliveryTitle,
    address.deliveryFirstName,
    address.deliveryLastName,
  )
  const purchaserName = toSentence(
    address.purchaserTitle,
    address.purchaserFirstName,
    address.purchaserLastName,
  )
  return (
    <>
      <div>
        {deliverTo === DeliverTo.Recipient ? recipientName : purchaserName}
      </div>
      <div>{address.deliveryAddressLineOne}</div>
      <div>{address.deliveryAddressLineTwo}</div>
      <div>{address.deliveryAddressTown}</div>
      <div>{address.deliveryAddressCounty}</div>
      <div>{address.deliveryAddressPostcode}</div>
    </>
  )
}
