import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import {
  alertError,
  alertSuccess,
  AsyncButton,
  Button,
  ButtonSize,
  ButtonStyle,
  colors,
  GiftItemStatus,
  GiftItemType,
  IGiftItemResponse,
  itemWord,
  Switch,
  theme,
  ZIndex,
} from '@one-tree/library'
import { RoutePath } from '../../../types/Routes'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { patchGiftItem } from '../../../helpers/APIItemHelper'
import { StateAction } from '../../../types/Aliases'
import { capitalize } from '../../../helpers/DataTransformer'
import { isGiftItemValid } from '../../../helpers/GiftItemValidityHelper'
import { publishFreeTicketWarning } from '../giftItem/designElements/TicketPrices/pricesHelper'

const Styles = styled.div`
  position: relative;
  background-color: ${colors.white};
  height: ${`${theme.giftItemControlPanelHeightPixels}px`};
  border: 1px solid ${colors.gray};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  z-index: ${ZIndex.AboveContent + 1};
`
const CompLabel = styled.div`
  color: ${colors.darkerGray};
`

interface IGiftItemControlPanelProps {
  giftItem: IGiftItemResponse
  setGiftItem: StateAction<IGiftItemResponse>
  fetchGiftItems: () => Promise<void>
  setLoading: StateAction<boolean>
}

function GiftItemControlPanel(props: IGiftItemControlPanelProps): ReactElement {
  const {
    giftItem, setGiftItem, fetchGiftItems, setLoading,
  } = props
  const history = useHistory()
  const { organisation } = useOrganisation()

  const handlePublishSwitch = async (publish: boolean): Promise<void> => {
    const result: IGiftItemResponse | false = (organisation
      && giftItem.id
      && (await patchGiftItem({
        format: organisation.format,
        itemId: giftItem.id,
        patchOptions: {
          status: publish
            ? GiftItemStatus.Published
            : GiftItemStatus.Unpublished,
        },
      })))
      || false

    if (result) setGiftItem(result)
  }

  const handleRestoreItem = async (): Promise<void> => {
    setLoading(true)
    let response: IGiftItemResponse | false = false

    if (giftItem.id && organisation) {
      response = await patchGiftItem({
        format: organisation.format,
        itemId: giftItem.id,
        patchOptions: { status: GiftItemStatus.Unpublished },
      })
      if (response) {
        await fetchGiftItems()
      }
    }

    if (response) {
      alertSuccess(`${capitalize(itemWord(organisation?.format))} restored`)
    } else {
      alertError(`Error restoring ${itemWord(organisation?.format)}`)
    }
    setLoading(false)
  }

  const isValid = isGiftItemValid(giftItem, organisation)
  const isComplimentary = giftItem.type === GiftItemType.Complimentary
  const isArchived = giftItem.status === GiftItemStatus.Archived

  const hasPrices = giftItem.prices && giftItem.prices.length > 0

  const hasPricesButNoPublishedPrices = giftItem.prices
    && giftItem.prices.length > 0
    && giftItem.prices.every((price) => price.status !== GiftItemStatus.Published)

  const noPricesNoValue = !hasPrices && !giftItem.value

  const getText = (): string => {
    if (!isValid) return 'Item unfinished'
    if (isComplimentary) return 'Complimentary'
    if (noPricesNoValue) return 'Requires value'
    if (hasPricesButNoPublishedPrices) return 'Requires price'

    return capitalize(giftItem.status)
  }

  const renderSwitch = isComplimentary ? (
    <CompLabel>{getText()}</CompLabel>
  ) : (
    <Switch
      label={getText()}
      value={giftItem.status === GiftItemStatus.Published}
      switchAction={handlePublishSwitch}
      disabled={
        giftItem.status === GiftItemStatus.Unpublished
        && (!isValid || noPricesNoValue || hasPricesButNoPublishedPrices)
      }
      showConfirmation={publishFreeTicketWarning(giftItem)}
      confirmationMessage="Are you sure you want to publish a free ticket?"
    />
  )

  const renderControls = isArchived ? (
    <AsyncButton
      buttonSize={ButtonSize.Mini}
      buttonStyle={ButtonStyle.Secondary}
      onClick={handleRestoreItem}
    >
      Restore
    </AsyncButton>
  ) : (
    renderSwitch
  )

  return (
    <Styles>
      <Button
        buttonStyle={ButtonStyle.Primary}
        onClick={(): void => history.push(`${RoutePath.ManageItems}/${giftItem.id}`)}
        buttonSize={ButtonSize.Mini}
      >
        Edit
      </Button>
      {renderControls}
    </Styles>
  )
}
export default GiftItemControlPanel
