import { GiftCardStatus } from '@one-tree/library'
import React, { ReactElement } from 'react'
import {
  transformDate,
  TransformDateFormat,
} from '../../../../helpers/DateHelper'

interface IRedeemedByRowProps {
  status: GiftCardStatus
  redeemUser: string
  redeemDate: number
}

function RedeemedByRow(props: IRedeemedByRowProps): ReactElement | null {
  const { status, redeemUser, redeemDate } = props

  return status === GiftCardStatus.Redeemed ? (
    <>
      <tr className="gift-card-email">
        <th>Redeemed by</th>
        <td>{redeemUser}</td>
      </tr>
      <tr>
        <th>Redeemed on</th>
        <td>{transformDate(redeemDate, TransformDateFormat.ShortWithTime)}</td>
      </tr>
    </>
  ) : null
}
export default RedeemedByRow
