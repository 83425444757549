import React, {
  createContext,
  useContext,
  useState,
  ReactElement,
  useMemo,
} from 'react'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import axios from 'axios'
import { IOrganisationResponse, Role } from '@one-tree/library'
import { IDecodedToken, Resource } from '../types/API'
import { get, getOrgToken } from '../helpers/APIHelper'
import { useAuth } from './AuthProvider'
import { ITokenPair, OrganisationUser } from '../types/Types'
import { StateAction } from '../types/Aliases'

interface IState {
  organisations: IOrganisationResponse[]
  saveOrganisations: (organisations: IOrganisationResponse[]) => void
  organisation: IOrganisationResponse | null
  selectOrganisation: (
    targetOrgId: number,
    tokenPair?: ITokenPair,
  ) => Promise<boolean>
  organisationUser: OrganisationUser
  setOrganisationUser: StateAction<OrganisationUser>
  organisationLoading: boolean
  setOrganisationLoading: StateAction<boolean>
  clearOrganisation: () => void
}

const TokenContext = createContext<IState | undefined>(undefined)

function OrganisationProvider({
  children,
}: {
  children: ReactElement
}): ReactElement {
  const [organisations, setOrganisations] = useState<IOrganisationResponse[]>(
    JSON.parse(sessionStorage.getItem('organisations') || '[]'),
  )
  const [organisation, setOrganisation] = useState<IOrganisationResponse | null>(
    JSON.parse(sessionStorage.getItem('organisation') || 'null'),
  )
  const [organisationUser, setOrganisationUser] = useState<{
    email: string
    role: Role
  } | null>(JSON.parse(sessionStorage.getItem('organisationUser') || 'null'))
  const [organisationLoading, setOrganisationLoading] = useState(false)

  const { saveAccessToken } = useAuth()

  const saveOrganisations = async (
    organisationsToSave: IOrganisationResponse[],
  ): Promise<void> => {
    setOrganisations(organisationsToSave)
    sessionStorage.setItem('organisations', JSON.stringify(organisationsToSave))
  }

  const saveOrganisationUser = (tokenPair: ITokenPair): void => {
    const decodedToken: IDecodedToken = jwt_decode(tokenPair.token)

    const organisationUserDetails = {
      email: decodedToken.email,
      role: decodedToken.role,
    }
    setOrganisationUser(organisationUserDetails)
    sessionStorage.setItem(
      'organisationUser',
      JSON.stringify(organisationUserDetails),
    )
  }

  // If tokenPair is passed in then use that rather than getOrgToken, this is used when masquerading
  const selectOrganisation = async (
    targetOrgId: number,
    tokenPair?: ITokenPair,
  ): Promise<boolean> => {
    setOrganisationLoading(true)

    const newOrgToken = tokenPair ?? (await getOrgToken({ orgId: targetOrgId }))

    if (newOrgToken) {
      // Replace the token in AuthProvider with the org token
      saveAccessToken({
        token: newOrgToken.token,
        refreshToken: newOrgToken.refreshToken,
      })

      // Set the token in Axios headers, we need to set this here so it's available for the API call
      axios.defaults.headers.common.Authorization = `Bearer ${newOrgToken.token}`

      const targetOrganisation = await get<IOrganisationResponse>({
        resource: Resource.Organisations,
        urlPath: [targetOrgId.toString()],
      })
      if (targetOrganisation) {
        // Set the organisation and organisationUser in local state and session for persistence
        setOrganisation(targetOrganisation)
        sessionStorage.setItem(
          'organisation',
          JSON.stringify(targetOrganisation),
        )

        saveOrganisationUser(newOrgToken)

        setOrganisationLoading(false)
        return true
      }
    }
    setOrganisationLoading(false)
    return false
  }

  const clearOrganisation = (): void => {
    sessionStorage.clear()

    setOrganisations([])
    setOrganisation(null)
    setOrganisationUser(null)
  }

  const value = useMemo(
    () => ({
      organisations,
      saveOrganisations,
      organisation,
      selectOrganisation,
      organisationUser,
      setOrganisationUser,
      organisationLoading,
      setOrganisationLoading,
      clearOrganisation,
    }),
    [organisations, organisation, organisationUser, organisationLoading],
  )

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
}

function useOrganisation(): IState {
  const context = useContext(TokenContext)
  if (context === undefined) {
    throw new Error(
      'useOrganisation must be used within a OrganisationProvider',
    )
  }
  return context
}

export { OrganisationProvider, useOrganisation }
