import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { colors, IOrganisationResponse, Animate } from '@one-tree/library'
import { useOrganisation } from '../../context/OrganisationProvider'
import { RoutePath } from '../../types/Routes'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { ReactComponent as SpecialOfferIcon } from '../../assets/icon-special_offers.svg'
import { ReactComponent as GiftVoucherIcon } from '../../assets/icon-voucher.svg'
import { ReactComponent as TicketIcon } from '../../assets/icon-ticket.svg'
import CardPlaceholder from './CardPlaceholder'
import CardOrgList from './CardOrgList'
import ContactGroup from '../../components/page/ContactGroup'
import { OrganisationFormat } from '../../types/Types'
import { getOrgsByFormat } from '../../helpers/FormatHelper'
import { isMobile } from '../../helpers/MobileHelper'
import useResponsive from '../../helpers/isResponsive'

const StyledDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  .select-org-logo {
    width: 300px;
    margin-bottom: 50px;
    fill: ${colors.logo};
  }
  .select-org-cards {
    display: flex;
  }
  .select-org-logo {
    ${(props): string => props.theme.isTablet && 'margin-top: 50px; margin-bottom: 50px;'}
  }
  .select-org-cards {
    ${(props): string => props.theme.isTablet && 'flex-direction: column;'}
    .card-organisation-list {
      ${(props): string => props.theme.isTablet && 'overflow-y: visible;'}
    }
  }
`

function SelectOrganisation(): ReactElement {
  const { organisations, selectOrganisation } = useOrganisation()
  const history = useHistory()

  const handleSelectOrganisation = async (
    organisation: IOrganisationResponse,
  ): Promise<void> => {
    if (await selectOrganisation(organisation.id)) {
      history.push(RoutePath.Home, { state: 'animate' })
      return
    }
    history.push(RoutePath.Login)
  }

  const specialOffersOrgs = getOrgsByFormat(
    organisations,
    OrganisationFormat.SpecialOffers,
  )
  const giftVouchersOrgs = getOrgsByFormat(
    organisations,
    OrganisationFormat.GiftVouchers,
  )
  const ticketsOrgs = getOrgsByFormat(organisations, OrganisationFormat.Tickets)

  const specialOfferIcon = (
    <SpecialOfferIcon style={{ fill: colors.specialOffers }} />
  )
  const giftVoucherIcon = (
    <GiftVoucherIcon style={{ fill: colors.giftVouchers }} />
  )
  const ticketIcon = <TicketIcon style={{ fill: colors.tickets }} />

  return (
    <Animate>
      <StyledDiv theme={useResponsive()}>
        <Logo className="select-org-logo" />

        <div className="select-org-cards">
          {specialOffersOrgs && specialOffersOrgs.length ? (
            <CardOrgList
              icon={specialOfferIcon}
              title="Special Offers"
              organisations={specialOffersOrgs}
              handleLogin={handleSelectOrganisation}
            />
          ) : (
            // only show the ads for the other products if not on mobile
            !isMobile() && (
              <CardPlaceholder
                icon={specialOfferIcon}
                title="Special Offers"
                placeholder={OrganisationFormat.SpecialOffers}
              />
            )
          )}

          {giftVouchersOrgs && giftVouchersOrgs.length ? (
            <CardOrgList
              icon={giftVoucherIcon}
              title="Gift Vouchers"
              organisations={giftVouchersOrgs}
              handleLogin={handleSelectOrganisation}
            />
          ) : (
            // only show the ads for the other products if not on mobile
            !isMobile() && (
              <CardPlaceholder
                icon={giftVoucherIcon}
                title="Gift Vouchers"
                placeholder={OrganisationFormat.GiftVouchers}
              />
            )
          )}

          {ticketsOrgs && ticketsOrgs.length ? (
            <CardOrgList
              icon={ticketIcon}
              title="Tickets"
              organisations={ticketsOrgs}
              handleLogin={handleSelectOrganisation}
            />
          ) : (
            // only show the ads for the other products if not on mobile
            !isMobile() && (
              <CardPlaceholder
                icon={ticketIcon}
                title="Tickets"
                placeholder={OrganisationFormat.Tickets}
              />
            )
          )}
        </div>
        <ContactGroup />
      </StyledDiv>
    </Animate>
  )
}

export default SelectOrganisation
