import styled from 'styled-components'
import React, { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { colors, IGiftCardResponse } from '@one-tree/library'
import Card from '../../components/page/Card'
import Page from '../../hoc/Page'
import RedeemerResultTable from './RedeemerResultTable'
import ResultTable from './resultTable/ResultTable'
import TableExpand from './resultTable/TableExpand'
import GiftCardRedeem from './GiftCardRedeem'
import useResponsive from '../../helpers/isResponsive'

const StyledCard = styled(Card)`
  color: ${colors.black};
  padding: 0;
  h2 {
    margin: 0;
    ${(props): string => props.theme.isMobile && 'padding-top: 50px;'}
  }
  width: 650px;
  & :first-letter {
    text-transform: uppercase;
  }
  & .gift-card-email td:first-letter {
    text-transform: lowercase;
  }
  ${(props): string => props.theme.isMobile && 'width: 100%;'}
`

const ActionsContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  button {
    margin-left: 10px;
  }
`

function RedeemerResult(): ReactElement {
  const location = useLocation<{ giftCard: IGiftCardResponse }>()
  const {
    state: { giftCard },
  } = location

  // if card has just been Redeemed, though the card is now invalid, it should read 'Redeemed'
  const [justRedeemed, setJustRedeemed] = useState<boolean>(false)

  const [tableExpanded, setTableExpanded] = useState<boolean>(false)
  const toggleTable = (): void => {
    setTableExpanded((prevState) => !prevState)
  }

  const responsive = useResponsive()

  return (
    <Page>
      {giftCard && (
        <StyledCard theme={responsive}>
          <ActionsContainer>
            {!justRedeemed && (
              <GiftCardRedeem
                giftCard={giftCard}
                setJustRedeemed={setJustRedeemed}
              />
            )}
          </ActionsContainer>
          <ResultTable giftCard={giftCard} justRedeemed={justRedeemed}>
            <RedeemerResultTable
              giftCard={giftCard}
              tableExpanded={tableExpanded}
              justRedeemed={justRedeemed}
            />
            <TableExpand
              toggleTable={toggleTable}
              tableExpanded={tableExpanded}
            />
          </ResultTable>
        </StyledCard>
      )}
    </Page>
  )
}
export default RedeemerResult
