import {
  colors,
  IItemisedData,
  IItemisedReportResponse,
  ReportItemisation,
  ReportMetricType,
} from '@one-tree/library'
import moment from 'moment'
import { transparentize } from 'polished'
import { capitalize } from '../../../helpers/DataTransformer'

const itemiseMap = new Map<ReportItemisation, string>([
  [ReportItemisation.DispatchMethod, 'dispatch method'],
  [ReportItemisation.Type, 'type'],
  [ReportItemisation.Name, 'name'],
])
export const getItemiseName = (
  itemisation: ReportItemisation,
): string | undefined => itemiseMap.get(itemisation)

export const getReducedItemisationData = (
  dataToReduce: IItemisedData[],
): IItemisedData[] => {
  // sort the data
  dataToReduce.sort((a, b) => b.total - a.total)

  // get the top 5
  const topFive = dataToReduce.slice(0, 5)

  // combine the rest into a single item
  const restTotal = dataToReduce
    .slice(5)
    .reduce((runningTotal, current) => runningTotal + current.total, 0)

  // combine
  if (restTotal > 0) {
    topFive.push({
      label: 'Other',
      total: restTotal,
    })
  }

  return topFive
}

export const getItemisationColor = (
  index: number,
  hovered: number | undefined,
  length?: number,
): string => {
  // last item is the Total, should be black
  if (length && index === length - 1) {
    return colors.black
  }
  const colorArray = [
    colors.green,
    colors.blue,
    colors.orange,
    colors.pink,
    colors.red,
    colors.darkerGray,
  ]
  if ((hovered || hovered === 0) && index !== hovered) {
    return transparentize(0.8, colorArray[index])
  }
  return colorArray[index]
}

export const getGraphData = (
  data: IItemisedData[],
  maxYearsToShow: number,
): IItemisedData[] => {
  // if only this year, add an empty last year for aesthetics
  if (data.length === 1) {
    const lastYear = (moment().year() - 1).toString()
    return [{ label: lastYear, total: 0 }, ...data]
  }
  return data.slice(-maxYearsToShow)
}

export const getItemisationCsv = (
  title: string,
  data: IItemisedReportResponse,
  metricType: ReportMetricType,
): (string | number)[][] => {
  const header = [title, capitalize(metricType), `% ${capitalize(metricType)}`]

  const rows: (string | number)[][] = []
  data.itemisedData.forEach((item) => {
    const row = [
      item.label,
      item.total,
      Math.round((item.total / data.total) * 100),
    ]
    rows.push(row)
  })

  return [header, ...rows]
}
