import React, { ReactElement, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import {
  InputGroup,
  AsyncButton,
  Buttonlink,
  Datepicker,
  ISelectOption,
  SelectField,
  GiftCardStatus,
  ReportingWidget,
} from '@one-tree/library'
import download from 'downloadjs'
import { RoutePath } from '../../../types/Routes'
import { statusEnumToOptions } from '../../../helpers/GiftCardHelper'
import { OrganisationFormat } from '../../../types/Types'
import { exportGiftCards } from '../../../helpers/APIHelper'
import { getCSVExportTitle } from '../ReportingHelper'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { voucherWord } from '../../../helpers/FormatHelper'

const StyledInputGroup = styled(InputGroup)`
  align-items: flex-end;
`
function DataDownload(): ReactElement {
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, 'year').add(1, 'day'),
  )
  const [toDate, setToDate] = useState(moment().endOf('day'))
  const [status, setStatus] = useState<ISelectOption<GiftCardStatus>>()
  const history = useHistory()

  const fetchCSV = async (): Promise<void> => {
    const from = fromDate.unix()
    const to = toDate.unix()
    const res = await exportGiftCards({
      fromDate: from,
      toDate: to,
      status: status?.value,
    })
    if (res) download(res, getCSVExportTitle(from, to, status?.value))
  }

  const { organisation } = useOrganisation()

  const ifNotTickets = organisation?.format !== OrganisationFormat.Tickets

  return (
    <ReportingWidget title="CSV of data">
      <p>
        Select a date range and filter to download a CSV of sales data.{' '}
        {ifNotTickets
          && `This
        data includes more specific fields like 'redeemed by'.`}
      </p>
      <p>
        Go to the{' '}
        <Buttonlink onClick={(): void => history.push(RoutePath.Sales)}>
          Sales
        </Buttonlink>{' '}
        page and use the filters there to see an on-screen view of the table of
        individual {voucherWord(organisation)}s.
      </p>
      <StyledInputGroup>
        <Datepicker
          label="From date"
          onChange={(date): void => {
            if (date) setFromDate(date)
          }}
          value={fromDate}
        />
        <Datepicker
          label="To date"
          onChange={(date): void => {
            if (date) setToDate(date.endOf('day'))
          }}
          value={toDate}
        />
        <SelectField
          placeholder="Filter by status..."
          options={statusEnumToOptions(GiftCardStatus)}
          onChange={(option): void => {
            if (option) {
              setStatus(option)
            } else {
              setStatus(undefined)
            }
          }}
          value={status}
          isClearable={true}
        />
        <AsyncButton onClick={fetchCSV}>Download data</AsyncButton>
      </StyledInputGroup>
    </ReportingWidget>
  )
}
export default DataDownload
