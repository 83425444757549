import React, { ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Loading,
  alertError,
  InvoiceAddress,
  InvoiceDetail,
  InvoiceItem,
  InvoiceGrid,
  CurrencyCode,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import { useOrganisation } from '../../context/OrganisationProvider'
import { get } from '../../helpers/APIHelper'
import { capitalize, parseString } from '../../helpers/DataTransformer'
import { formatInvoiceDate } from '../../helpers/DateHelper'
import Page from '../../hoc/Page'
import { IInvoiceItem, IInvoiceResponse, Resource } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import { IIDParam } from '../../types/Types'
import InvoiceHeader from './InvoiceHeader'

function LicenseInvoice(): ReactElement {
  const [invoice, setInvoice] = useState<IInvoiceResponse | null>(null)
  const { id }: IIDParam = useParams()
  const { organisation } = useOrganisation()
  const { currencyCode } = organisation || { currencyCode: CurrencyCode.GBP }

  useEffect(() => {
    (async (): Promise<void> => {
      if (id) {
        setInvoice(
          (await get<IInvoiceResponse>({
            resource: Resource.LicenseInvoices,
            urlPath: [id],
          })) || null,
        )
      } else {
        alertError('No invoice ID specified')
      }
    })()
  }, [])

  const paymentMethod = invoice && invoice.paymentMethod === 'gocardless'
    ? 'GoCardless'
    : capitalize(invoice?.paymentMethod)

  return (
    <Page>
      <Card cardSize={CardSize.Medium}>
        {!invoice || !organisation ? (
          <Loading fullPage={true} />
        ) : (
          <>
            <InvoiceHeader
              id={invoice.id}
              url={invoice.pdfUrl}
              backLink={RoutePath.Billing}
            />
            <CardContent>
              <InvoiceGrid>
                <section className="billingFrom">
                  <InvoiceAddress
                    name="One Tree Software"
                    lineOne="Long Barn"
                    // eslint-disable-next-line spellcheck/spell-checker
                    lineTwo="Priston"
                    town="Bath"
                    postcode="BA2 9EE"
                  />
                </section>
                <section className="details">
                  <InvoiceDetail header="Invoice ID" detail={invoice.id} />
                  <InvoiceDetail
                    header="Invoice date"
                    detail={formatInvoiceDate(invoice.date)}
                  />
                  {!invoice.paidDate ? (
                    <InvoiceDetail
                      header="Due date"
                      detail={formatInvoiceDate(invoice.dueDate)}
                    />
                  ) : (
                    <InvoiceDetail
                      header="Paid date"
                      detail={formatInvoiceDate(invoice.paidDate)}
                    />
                  )}
                  <InvoiceDetail
                    header="Payment method"
                    detail={paymentMethod}
                  />
                  <div className="header">Status:</div>
                  <div className="info">{capitalize(invoice.status)}</div>
                </section>
                <section className="billingTo">
                  <div>Billing to:</div>
                  <InvoiceAddress
                    name={organisation.name}
                    lineOne={organisation.addressLineOne}
                    lineTwo={organisation.addressLineTwo}
                    town={organisation.addressTown}
                    county={organisation.addressCounty}
                    postcode={organisation.addressPostcode}
                  />
                </section>
                <section className="items">
                  <div className="description">
                    <strong>Description</strong>
                  </div>
                  <div className="amount">
                    <strong>Amount</strong>
                  </div>
                  {invoice.items?.map((item: IInvoiceItem) => (
                    <InvoiceItem
                      description={parseString(item.description)}
                      amount={item.value}
                      currencyCode={currencyCode}
                    />
                  ))}
                  <InvoiceItem
                    description="Sub-total"
                    amount={invoice.subtotal || 0}
                    currencyCode={currencyCode}
                  />
                  <InvoiceItem
                    description="VAT @ 20%"
                    amount={invoice.total - invoice.subtotal || 0}
                    currencyCode={currencyCode}
                  />
                  <InvoiceItem
                    description="Total"
                    amount={invoice.total || 0}
                    currencyCode={currencyCode}
                  />
                </section>
                Thank you for your business.
              </InvoiceGrid>
            </CardContent>
          </>
        )}
      </Card>
    </Page>
  )
}
export default LicenseInvoice
