import moment, { Moment } from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import download from 'downloadjs'
import styled from 'styled-components'
import {
  Button,
  ButtonStyle,
  alertSuccess,
  InputField,
  InputType,
  Datepicker,
  InputGroup,
  NumberField,
  ISelectOption,
  SelectField,
  Switch,
  CurrencyCode,
  Item,
  useNotFirstRender,
  ITicket,
  GiftItemStatus,
  IGiftItemResponse,
  Buttonlink,
  capitalize,
} from '@one-tree/library'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { publishSwitchHandler } from '../../../helpers/GiftItemHelper'
import { IGiftItemPostBody } from '../../../types/GiftItemTypes'
import ItemBanner from './designElements/ItemBanner'
import ItemExtendedDescription from './designElements/ItemExtendedDescription'
import ItemImage from './designElements/ItemImage'
import ItemName from './designElements/ItemName'
import {
  categoryTest,
  getGiftItemStatus,
  isGiftItemValid,
  startInfoTest,
  unpublishDateTest,
} from '../../../helpers/GiftItemValidityHelper'
import ItemPrice from './designElements/ItemPrice'
import {
  getCategoriesAsOptions,
  getGiftVoucherCategories,
} from '../../../helpers/CategoryHelper'
import { ICategoryResponse, Resource } from '../../../types/API'
import { get } from '../../../helpers/APIHelper'
import {
  getEventGuestlist,
  bulkRedeemTickets,
} from '../../../helpers/APIItemHelper'
import { itemWord } from '../../../helpers/FormatHelper'
import { getOrganisationColors } from '../../../helpers/OrganisationHelper'
import TicketQuestions from './designElements/TicketQuestions/TicketQuestions'
import TicketPrices from './designElements/TicketPrices/TicketPrices'
import { publishFreeTicketWarning } from './designElements/TicketPrices/pricesHelper'

const TicketManagement = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`
const PriceRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`

export interface ITicketDesignProps {
  ticket: ITicket
  changeTicket: (changes: IGiftItemPostBody) => void
  isEditing: boolean
  setGiftItem: (giftItem: IGiftItemResponse) => void
  changes: IGiftItemPostBody | null
  setChanges: (changes: IGiftItemPostBody | null) => void
}

function TicketDesign({
  ticket,
  changeTicket,
  isEditing,
  setGiftItem,
  changes,
  setChanges,
}: ITicketDesignProps): ReactElement {
  const { organisation } = useOrganisation()
  const [categories, setCategories] = useState<ICategoryResponse[]>([])
  const notFirstRender = useNotFirstRender()

  const [imagePreview, setImagePreview] = useState<{ [url: string]: string }>({
    imageUrl: ticket.imageUrl,
  })
  const [headerPreview, setHeaderPreview] = useState({
    headerImageUrl: ticket.headerImageUrl,
  })

  const giftItemWithPreview = { ...ticket, ...imagePreview, ...headerPreview }

  // if user publishes, but then invalidates, republish if user makes valid again
  const [forcePublish, setForcePublish] = useState(
    ticket.status === GiftItemStatus.Published,
  )

  const isValid = isGiftItemValid(ticket, organisation)

  useEffect(() => {
    const shouldUnpublish = !isValid && ticket.status === GiftItemStatus.Published
    const shouldRepublish = isValid && forcePublish
    if (shouldUnpublish) {
      changeTicket({
        status: GiftItemStatus.Unpublished,
      })
    }
    if (notFirstRender && shouldRepublish) {
      changeTicket({
        status: GiftItemStatus.Published,
      })
    }
  }, [isValid])

  useEffect(() => {
    if (organisation?.categoriesEnabled) {
      (async (): Promise<void> => {
        const res = await get<ICategoryResponse[]>({
          resource: Resource.Categories,
        })
        if (res) setCategories(res)
      })()
    }
  }, [])

  useEffect(() => {
    if (!isValid && ticket.status === GiftItemStatus.Published) {
      changeTicket({
        status: GiftItemStatus.Unpublished,
      })
    }
  }, [isValid])

  useEffect(() => {
    if (notFirstRender && ticket.value === '0') {
      changeTicket({
        status: GiftItemStatus.Unpublished,
      })
    }
  }, [ticket.value])

  const handleGuestlist = async (): Promise<void> => {
    const res = await getEventGuestlist({ id: ticket.id })
    if (res) {
      const notChars = /[_\W]+/g
      download(res, `guestlist-${ticket.name.replace(notChars, '')}.csv`)
    }
  }

  const handleDateChange = (
    date: Moment | null,
    key: keyof IGiftItemPostBody,
  ): void => {
    changeTicket({
      [key]: date ? moment(date).unix() : null,
    })
  }

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IGiftItemPostBody,
  ): void => {
    changeTicket({
      [key]: value,
    })
  }

  const setGiftVoucherCategories = (options: ISelectOption<number>[]): void => {
    changeTicket({
      categoryIds: options.map((option: ISelectOption<number>) => option.value),
    })
  }

  const handleRedeemAllTickets = async (): Promise<void> => {
    const res = await bulkRedeemTickets({ giftItemIds: [ticket.id] })
    if (res) {
      alertSuccess(`${res.length} ticket${res.length > 1 ? 's' : ''} redeemed`)
    }
  }

  const ticketRedeemMessage = 'Are you sure you want to redeem all tickets for this event? This cannot be undone.'

  const categoryOptions = getCategoriesAsOptions(categories)

  const hasPrices = ticket.prices && ticket.prices.length > 0
  const [showPrices, setShowPrices] = useState(hasPrices)

  const hasPricesButNoPublishedPrices = ticket.prices
    && ticket.prices.length > 0
    && ticket.prices.every((price) => price.status !== GiftItemStatus.Published)

  const noPricesNoValue = !hasPrices && !ticket.value

  const getPublishLabel = (): string => {
    if (noPricesNoValue) {
      return 'Cannot publish without value'
    }
    if (hasPricesButNoPublishedPrices) {
      return 'At least one price must be enabled to publish this item'
    }

    return capitalize(getGiftItemStatus(giftItemWithPreview, organisation))
  }

  return (
    <>
      <section className="gift-settings">
        <div className="gift-settings-styles">
          <h3>Ticket settings</h3>
          {ticket.quantitySold || ticket.quantitySold === 0 ? (
            <TicketManagement>
              {ticket.quantitySold > 0 && (
                <InputGroup>
                  <Button
                    buttonStyle={ButtonStyle.Secondary}
                    onClick={handleGuestlist}
                  >
                    Download guestlist
                  </Button>
                  <Button
                    buttonStyle={ButtonStyle.Secondary}
                    onClick={handleRedeemAllTickets}
                    showConfirmation={true}
                    confirmationMessage={ticketRedeemMessage}
                  >
                    Redeem all tickets
                  </Button>
                </InputGroup>
              )}
              {`${ticket.quantitySold} sold as of ${moment().format(
                'h:mma, DD/MM/YYYY',
              )}`}
            </TicketManagement>
          ) : null}
          <InputGroup>
            {organisation?.categoriesEnabled && (
              <SelectField
                placeholder={
                  categoryOptions.length
                    ? 'Select categories...'
                    : 'No categories found'
                }
                options={categoryOptions}
                onChange={(options): void => {
                  if (options) setGiftVoucherCategories(options)
                }}
                isMulti={true}
                isLong={true}
                value={getGiftVoucherCategories(ticket, categories)}
                isError={
                  isEditing
                  && Boolean(categoryTest({ giftItem: ticket, organisation }))
                }
              />
            )}
            <Switch
              label={getPublishLabel()}
              disabled={
                ticket.status === GiftItemStatus.Unpublished
                && (!isValid || noPricesNoValue || hasPricesButNoPublishedPrices)
              }
              flipLabel={true}
              value={ticket?.status === GiftItemStatus.Published}
              switchAction={(isOn): void => {
                publishSwitchHandler(isOn, changeTicket, setForcePublish)
              }}
              showConfirmation={publishFreeTicketWarning(ticket)}
              confirmationMessage={`Are you sure you want to publish a free ${itemWord(
                organisation,
              )}?`}
            />
          </InputGroup>
          <InputGroup subtitle="Unpublish date should typically be the day after the event finishes.">
            <Datepicker
              label="Publish date (optional)"
              onChange={(date): void => {
                handleDateChange(date, 'publishDate')
              }}
              value={
                ticket.publishDate ? moment.unix(ticket.publishDate) : null
              }
              isClearable={true}
              futureOnly={true}
            />
            <Datepicker
              label="Unpublish date (optional)"
              onChange={(date): void => {
                handleDateChange(date, 'unpublishDate')
              }}
              value={
                ticket.unpublishDate ? moment.unix(ticket.unpublishDate) : null
              }
              isClearable={true}
              futureOnly={true}
              isError={
                isEditing
                && Boolean(unpublishDateTest({ giftItem: ticket, organisation }))
              }
            />
          </InputGroup>
          <InputGroup>
            <NumberField
              label="Maximum per order"
              placeholder="Default value is 100"
              onChange={(number): void => {
                handleChange(number, 'maxPerOrder')
              }}
              value={ticket.maxPerOrder}
            />
            <NumberField
              label="Quantity"
              placeholder="Default is unlimited"
              onChange={(number): void => {
                handleChange(number, 'quantity')
              }}
              value={ticket.quantity}
            />
            <Switch
              label="Show quantity remaining?"
              switchAction={(action): void => {
                handleChange(action, 'showRemaining')
              }}
              value={ticket.showRemaining}
              flipLabel={true}
              labelOffset={true}
            />
          </InputGroup>
          {organisation && !showPrices && (
            <PriceRow>
              <ItemPrice
                label="Price"
                giftItem={ticket}
                changeItem={changeTicket}
                organisation={organisation}
                isEditing={isEditing}
              />
              {!showPrices && (
                <Buttonlink
                  onClick={(): void => setShowPrices(true)}
                  style={{ fontSize: '13px' }}
                >
                  Add multiple prices
                </Buttonlink>
              )}
            </PriceRow>
          )}
        </div>
        {showPrices && (
          <TicketPrices
            ticket={ticket}
            setGiftItem={setGiftItem}
            hide={(): void => setShowPrices(false)}
            changes={changes}
            setChanges={setChanges}
          />
        )}
        <TicketQuestions ticket={ticket} changeTicket={changeTicket} />
      </section>
      <section className="gift-design">
        <h3>Ticket design</h3>
        <ItemName
          label="Ticket name"
          value={ticket.name}
          giftItem={ticket}
          changeItem={changeTicket}
          organisation={organisation}
          isEditing={isEditing}
        />
        <ItemImage
          label="Ticket image"
          previewUrl={imagePreview.imageUrl}
          giftItem={ticket}
          changeItem={changeTicket}
          changePreview={setImagePreview}
          organisation={organisation}
          isEditing={isEditing}
        />
        <ItemBanner
          label="Ticket header"
          previewUrl={headerPreview.headerImageUrl}
          changeItem={changeTicket}
          changePreview={setHeaderPreview}
        />
      </section>
      <section className="preview-design">
        <h3>Web preview</h3>
        <Item
          giftItem={giftItemWithPreview}
          currency={organisation?.currencyCode || CurrencyCode.GBP}
          organisationColors={getOrganisationColors(organisation)}
        />
      </section>
      <section className="gift-description">
        <h3>Ticket description</h3>
        <InputField
          type={InputType.Text}
          label="Date (and time)"
          onChange={(value): void => {
            handleChange(value, 'startInfo')
          }}
          value={ticket.startInfo}
          subtitle={
            (isEditing && startInfoTest({ giftItem: ticket, organisation }))
            || 'Enter the date (including time if applicable) as you would like it to appear on your event tickets, e.g. 9:00am on 20th June 2025.'
          }
          isError={
            isEditing
            && Boolean(startInfoTest({ giftItem: ticket, organisation }))
          }
        />
        <ItemExtendedDescription
          value={ticket.terms}
          giftItem={ticket}
          changeItem={changeTicket}
          label="Description"
          isEditing={isEditing}
          organisation={organisation}
          maxChars={1000}
          placeholder="Add a description of the event"
        />
      </section>
      <section className="preview-description">
        <h3>Web preview (details)</h3>
        <Item
          giftItem={giftItemWithPreview}
          currency={organisation?.currencyCode || CurrencyCode.GBP}
          setDetails={true}
          organisationColors={getOrganisationColors(organisation)}
        />
      </section>
    </>
  )
}
export default TicketDesign
