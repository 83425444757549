import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { FiMove as MoveIcon } from 'react-icons/fi'
import {
  colors,
  CurrencyCode,
  GiftItemType,
  IGiftItemResponse,
  Item,
  ZIndex,
} from '@one-tree/library'
import GiftItemControlPanel from './GiftItemControlPanel'
import { StateAction } from '../../../types/Aliases'
import { isGiftItemValid } from '../../../helpers/GiftItemValidityHelper'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { getOrganisationColors } from '../../../helpers/OrganisationHelper'

const Styles = styled.div`
  position: relative;
  margin: 20px;
  min-width: 237px;
  max-width: 237px;
  &:hover {
    .gift-item-drag-box {
      opacity: 0.9;
      pointer-events: all;
    }
  }
`
const DragBox = styled.div`
  user-select: none;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${colors.darkerGray};
  width: 42px;
  height: 42px;
  border-top-left-radius: 30px;
  z-index: ${ZIndex.AboveContent + 1};
  svg {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 26px;
    height: auto;
    color: ${colors.white};
  }
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
`
const Cover = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${colors.shadow};
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: ${ZIndex.AboveContent};
  &.gift-item-unpublished {
    opacity: 1;
  }
`

export interface IGiftItemGridItemProps {
  item: IGiftItemResponse
  setHovering: StateAction<boolean>
  loading: boolean
  setLoading: StateAction<boolean>
  setIsDragging: StateAction<boolean>
  showControls?: boolean
  fetchGiftItems: () => Promise<void>
}

function GiftItemGridItem(props: IGiftItemGridItemProps): ReactElement {
  const {
    item,
    setHovering,
    loading,
    setLoading,
    setIsDragging,
    showControls,
    fetchGiftItems,
  } = props
  const [giftItem, setGiftItem] = useState<IGiftItemResponse>(item)

  useEffect(() => {
    // if the item is part of a bulk status update, update this giftItem to reflect the changes
    if (giftItem.status !== item.status) {
      setGiftItem(item)
    }
  }, [item.status])

  useEffect(() => {
    // if we publish/unpublish a single item, update from server to ensure correct data shown
    if (giftItem.status !== item.status) {
      fetchGiftItems()
    }
  }, [giftItem.status])

  const { organisation } = useOrganisation()
  const isValid = isGiftItemValid(giftItem, organisation)
  const isComp = giftItem.type === GiftItemType.Complimentary

  const className = isComp && isValid ? 'gift-item-published' : `gift-item-${giftItem.status}`

  return (
    <Styles>
      {showControls && (
        <GiftItemControlPanel
          giftItem={giftItem}
          setGiftItem={setGiftItem}
          fetchGiftItems={fetchGiftItems}
          setLoading={setLoading}
        />
      )}
      <Cover className={className} />
      <Item
        giftItem={giftItem}
        currency={organisation?.currencyCode || CurrencyCode.GBP}
        organisationColors={getOrganisationColors(organisation)}
      />
      {!loading && showControls && (
        <DragBox
          className="gift-item-drag-box"
          onMouseEnter={(): void => setHovering(true)}
          onMouseDown={(): void => setIsDragging(true)}
          onMouseUp={(): void => setIsDragging(false)}
          onMouseLeave={(): void => setHovering(false)}
        >
          <MoveIcon />
        </DragBox>
      )}
    </Styles>
  )
}
export default GiftItemGridItem
