import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { CgChevronDoubleDownO as ExpandIcon } from 'react-icons/cg'
import { colors } from '@one-tree/library'

const StyledDiv = styled.div`
  background-color: ${colors.gray};
  color: ${colors.darkerGray};
  font-size: 0.9rem;
  font-weight: bold;
  padding: 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  svg {
    width: 18px;
    height: 18px;
    padding: 0 4px;
    fill: ${colors.darkerGray};
    &.expand-icon-rotate {
      transform: rotate(180deg);
    }
  }
  transition: background-color 0.2s;
  :hover {
    background-color: ${colors.gray};
  }
`
interface ITableExpand {
  toggleTable: () => void
  tableExpanded: boolean
}
function TableExpand(props: ITableExpand): ReactElement {
  const { toggleTable, tableExpanded } = props

  return (
    <tbody>
      <tr>
        <td colSpan={100} style={{ padding: '0' }}>
          <StyledDiv
            onClick={toggleTable}
            onKeyPress={toggleTable}
            role="button"
            tabIndex={0}
            aria-label="Toggle menu"
          >
            <ExpandIcon className={tableExpanded ? 'expand-icon-rotate' : ''} />
            {tableExpanded ? 'Collapse' : 'Expand'}
          </StyledDiv>
        </td>
      </tr>
    </tbody>
  )
}
export default TableExpand
