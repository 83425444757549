import React, { FormEvent, ReactElement, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  ButtonStyle,
  Buttonlink,
  InputField,
  InputType,
} from '@one-tree/library'
import { RoutePath } from '../../types/Routes'
import { changePassword } from '../../helpers/APIHelper'
import { validatePassword } from '../../helpers/ValidationHelper'
import { ILoginMessages, IMessagingProps } from '../../types/Types'

function ChangePassword(props: IMessagingProps<ILoginMessages>): ReactElement {
  const { sendMessages, receiveMessages } = props
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  const handleChangePassword = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()

    sendMessages()
    if (validatePassword(password)) {
      sendMessages({ password: validatePassword(password) })
      return
    }
    if (password !== passwordRepeat) {
      sendMessages({ passwordRepeat: 'Passwords need to match' })
      return
    }
    const hash = location.search.slice(1)
    if (!hash) {
      sendMessages({ error: 'Link broken, send another request' })
      return
    }

    setLoading(true)
    const result: { message: string } | false = await changePassword({
      hash,
      password,
      confirmation: passwordRepeat,
    })

    if (result) {
      sendMessages({ success: 'Password changed' })
      history.push(RoutePath.Login)
    } else {
      sendMessages({ error: 'Error changing password' })
    }
    setLoading(false)
  }

  return (
    <>
      <form className="form" noValidate={true} onSubmit={handleChangePassword}>
        <InputField
          type={InputType.Password}
          onChange={setPassword}
          value={password}
          isError={Boolean(receiveMessages.password)}
          placeholder="Password"
        />
        <InputField
          type={InputType.Password}
          onChange={setPasswordRepeat}
          value={passwordRepeat}
          isError={Boolean(receiveMessages.passwordRepeat)}
          placeholder="Repeat password"
        />
        <Button
          type="submit"
          buttonStyle={ButtonStyle.Primary}
          loading={loading}
        >
          Change password
        </Button>
      </form>
      <Link
        to={RoutePath.Login}
        onClick={(): void => {
          sendMessages()
          setPassword('')
          setPasswordRepeat('')
        }}
      >
        <Buttonlink>Back to login</Buttonlink>
      </Link>
    </>
  )
}

export default ChangePassword
