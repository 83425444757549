export const goToInput = (id: number): void => {
  const target = document.getElementById(`code-field-${id}`)
  if (target instanceof HTMLInputElement && target !== null) target.focus()
}

export const handleNextInput = (id: string): void => {
  const thisFieldNumber = parseInt(id.slice(-1), 10)
  const nextField = document.getElementById(`code-field-${thisFieldNumber + 1}`)
  if (nextField instanceof HTMLInputElement && nextField !== null) {
    if (nextField.value.length < 4) nextField.focus()
  }
}

export const handlePrevInput = (id: string): void => {
  const thisFieldNumber = parseInt(id.slice(-1), 10)
  const prevField = document.getElementById(`code-field-${thisFieldNumber - 1}`)
  if (prevField instanceof HTMLInputElement && prevField !== null) prevField.focus()
}

export const notAllowed = /[^0-9a-zA-Z]/g

export const cleanInput = (input: string): string => input.replace(notAllowed, '').toUpperCase().substring(0, 16)

export const stringToCode = (string: string): string[] => {
  const code = string.split('-')

  const array = Array(4).fill('')
  if (!code) return array

  return array.map((_, index) => code[index] || '')
}
