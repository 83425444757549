import { useState, useEffect } from 'react'

const MAX_MOBILE = 479
const MAX_TABLET = 991
const MAX_SMALL_DESKTOP = 1399

const checkIsMobile = (): boolean => {
  if (window.innerWidth < MAX_MOBILE) return true
  return false
}

const checkIsTablet = (): boolean => {
  if (window.innerWidth < MAX_TABLET) return true
  return false
}

const checkIsSmallDesktop = (): boolean => {
  if (window.innerWidth < MAX_SMALL_DESKTOP) return true
  return false
}

interface IResponsive {
  isMobile: boolean
  isTablet: boolean
  isSmallDesktop: boolean
}
export default function useResponsive(): IResponsive {
  const [isMobile, setIsMobile] = useState(checkIsMobile)
  const [isTablet, setIsTablet] = useState(checkIsTablet)
  const [isSmallDesktop, setIsSmallDesktop] = useState(checkIsSmallDesktop)

  const handleResize = (): void => {
    setIsMobile(checkIsMobile)
    setIsTablet(checkIsTablet)
    setIsSmallDesktop(checkIsSmallDesktop)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return (): void => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile, isTablet, isSmallDesktop }
}
