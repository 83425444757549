import {
  alertError,
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  CurrencyCode,
  CurrencyField,
  IGiftItemResponse,
  InputField,
  Modal,
  roundDecimalValue,
} from '@one-tree/library'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../../../../context/OrganisationProvider'
import { addTicketPrice } from '../../../../../helpers/APIHelper'

const Styles = styled.div`
  display: grid;
  row-gap: 12px;
  justify-items: start;
`
const ModalContents = styled.div`
  width: 340px;
  display: grid;
  row-gap: 20px;
  justify-items: start;
  p {
    margin: 0;
  }
`
const StyledInputGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
interface ITicketPricesProps {
  ticket: IGiftItemResponse
  setGiftItem: (giftItem: IGiftItemResponse) => void
}
export default function TicketAddPrices(
  props: ITicketPricesProps,
): ReactElement {
  const { ticket, setGiftItem } = props
  const [modal, setModal] = useState(false)
  const { organisation } = useOrganisation()

  const [name, setName] = useState('')
  const [value, setValue] = useState('')

  const handleCancel = (): void => {
    setName('')
    setValue('')
    setModal(false)
  }

  const handleAdd = async (): Promise<void> => {
    if (!ticket.id || !organisation) return

    if (!name || !value) {
      alertError('You must add both a name and a value')
      return
    }

    const res = await addTicketPrice({
      ticketId: ticket.id,
      price: { name, value: roundDecimalValue(value) },
    })

    if (res) {
      setGiftItem(res)
      handleCancel()
      alertSuccess('Price added')
    } else {
      alertError('Error adding price')
    }
  }

  return (
    <>
      <Styles>
        {!ticket.id && (
          <span>Multiple prices can be added after ticket creation.</span>
        )}
        <AsyncButton onClick={(): void => setModal(true)} disabled={!ticket.id}>
          Add price
        </AsyncButton>
      </Styles>
      <Modal modalOpen={modal} onModalClose={handleCancel}>
        <ModalContents>
          <h3>Add a price</h3>
          <p>
            You can add multiple prices to a single ticket item. This is useful
            to allow, for example, separate prices for Children and Adults.
          </p>
          <InputField
            label="Name"
            value={name}
            onChange={setName}
            style={{ minWidth: '200px' }}
            placeholder="e.g. 'Child' or 'Adult'"
          />
          <CurrencyField
            label="Price"
            currency={organisation?.currencyCode || CurrencyCode.GBP}
            onChange={setValue}
            initValue={value}
          />
          <StyledInputGroup>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              onClick={(): void => setModal(false)}
            >
              Cancel
            </Button>
            <AsyncButton buttonStyle={ButtonStyle.Action} onClick={handleAdd}>
              Add
            </AsyncButton>
          </StyledInputGroup>
        </ModalContents>
      </Modal>
    </>
  )
}
