import {
  api, Status,
  IHistoricData,
  IItemisedData,
  IItemisedReportResponse,
  ReportItemisation,
  ReportMetric,
  ReportMetricType,
} from '@one-tree/library'
import axios from 'axios'
import { Resource } from '../types/API'

const {
  Reports, ItemisedReports, AllTimeSales, MailingList,
} = Resource

const { OK } = Status

export const getReport = async (data: {
  fromMonth: number
  fromYear: number
}): Promise<IHistoricData[] | false> => {
  const request = axios.get(Reports, { params: data })
  return api(request, OK)
}

export const getItemisedReport = async (data: {
  fromDate: number
  toDate: number
  metric: ReportMetric
  metricType: ReportMetricType
  itemiseBy: ReportItemisation
}): Promise<IItemisedReportResponse | false> => {
  const request = axios.get(ItemisedReports, { params: data })
  return api(request, OK)
}

export const getAllTimeSales = async (): Promise<IItemisedData[] | false> => {
  const request = axios.get(AllTimeSales)
  return api(request, OK)
}

export const getMailingList = async (): Promise<string | false> => {
  const request = axios.get(MailingList)
  const res = await api(request, OK)
  return res.filename
}
