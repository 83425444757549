import IframeResizer from 'iframe-resizer-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Loading, Button, ButtonStyle, colors,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getOrgAccessToken } from '../../helpers/APIHelper'
import Page from '../../hoc/Page'
import { RoutePath } from '../../types/Routes'

const StyledIframeResizer = styled(IframeResizer)`
  border: 1px solid ${colors.gray};
  border-radius: 4px;
  min-width: 100%;
`

function ManualOrder(): ReactElement {
  const history = useHistory()
  const { organisation } = useOrganisation()

  const [iframeSrc, setIframeSrc] = useState<string>('')

  const fetchAccessToken = async (): Promise<void> => {
    const token = await getOrgAccessToken()

    if (token && organisation) {
      const { id } = organisation

      const backend = process.env.REACT_APP_PURCHASE_URL

      setIframeSrc(`${backend}/${id}?token=${token}`)
    }
  }

  useEffect(() => {
    fetchAccessToken()
  }, [])

  return (
    <Page>
      <Card cardSize={CardSize.Big} style={{ width: '1170px' }}>
        <CardHeader title="Make a new sale">
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => history.push(RoutePath.Sales)}
          >
            Back to sales
          </Button>
        </CardHeader>
        <CardContent>
          {iframeSrc ? (
            <StyledIframeResizer src={iframeSrc} scrolling={false} />
          ) : (
            <Loading fullPage={true} />
          )}
        </CardContent>
      </Card>
    </Page>
  )
}
export default ManualOrder
