import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Hyperlink } from '@one-tree/library'

const StyledDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 22px;
  .contact {
    text-align: center;
    a {
      display: block;
      margin: 5px;
    }
  }
`

function ContactGroup(): ReactElement {
  return (
    <StyledDiv>
      <div className="contact">
        <Hyperlink href="tel:01761472911">01761 472911</Hyperlink>
        <Hyperlink href="mailto:support@one-tree.net">
          support@one-tree.net
        </Hyperlink>
      </div>
    </StyledDiv>
  )
}
export default ContactGroup
