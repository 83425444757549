import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, ButtonStyle } from '@one-tree/library'
import CardHeader from '../../components/page/CardHeader'

export interface IInvoiceHeaderProps {
  id: number
  url: string | undefined
  backLink: string
}

function InvoiceHeader(props: IInvoiceHeaderProps): ReactElement {
  const { id, url, backLink } = props
  const history = useHistory()

  return (
    <CardHeader title={`Invoice ${id}`}>
      {url && (
        <Button
          buttonStyle={ButtonStyle.Primary}
          onClick={(): void => {
            window.open(url, '_blank')
          }}
        >
          Download PDF
        </Button>
      )}
      <Button
        buttonStyle={ButtonStyle.Secondary}
        onClick={(): void => {
          history.push(backLink)
        }}
      >
        Back to all
      </Button>
    </CardHeader>
  )
}
export default InvoiceHeader
