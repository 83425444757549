import { colors, GiftCardStatus } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
// eslint-disable-next-line import/no-cycle
import { displayStatus } from '../helpers/GiftCardHelper'

const Styles = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 0.7rem;
  font-weight: bold;
  color: ${colors.white};
  width: fit-content;
  &.status-box-paid {
    background-color: ${colors.paid};
  }
  &.status-box-booked {
    background-color: ${colors.booked};
  }
  &.status-box-redeemed {
    background-color: ${colors.redeemed};
  }
  &.status-box-delivered {
    background-color: ${colors.dispatched};
  }
  &.status-box-cancelled {
    background-color: ${colors.cancelled};
  }
  &.status-box-expired {
    background-color: ${colors.expired};
  }
  &.status-box-dropdown {
    margin: 0;
    font-size: 0.85rem;
  }
  &.status-box-inline {
    display: inline;
    font-size: 0.8rem;
  }
  &.status-box-table {
    font-size: 0.8rem;
    min-width: 60px;
    text-align: center;
    margin: 0 3px;
  }
`
export enum StatusBoxSize {
  Dropdown = 'dropdown',
  Inline = 'inline',
  Table = 'table',
}
interface IGiftCardStatusBoxProps {
  status: GiftCardStatus
  size: StatusBoxSize
}

function GiftCardStatusBox(props: IGiftCardStatusBoxProps): ReactElement {
  const { status, size } = props

  return (
    <Styles className={`status-box status-box-${status} status-box-${size}`}>
      {displayStatus(status)}
    </Styles>
  )
}
export default GiftCardStatusBox
