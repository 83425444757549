import {
  AsyncButton,
  Buttonlink,
  ButtonSize,
  ButtonStyle,
  GiftItemStatus,
  IGiftItemResponse,
  Switch,
  valueToCurrency,
} from '@one-tree/library'
import React, { ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../../../../context/OrganisationProvider'
import {
  archiveTicketPrice,
  patchTicketPriceStatus,
} from '../../../../../helpers/APIHelper'
import { patchGiftItem } from '../../../../../helpers/APIItemHelper'
import { IGiftItemPostBody } from '../../../../../types/GiftItemTypes'
import { SimpleTable } from '../SimpleTable'
import TicketAddPrices from './TicketAddPrices'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
interface ITicketPricesProps {
  ticket: IGiftItemResponse
  setGiftItem: (giftItem: IGiftItemResponse) => void
  hide: () => void
  changes: IGiftItemPostBody | null
  setChanges: (changes: IGiftItemPostBody | null) => void
}
export default function TicketPrices(props: ITicketPricesProps): ReactElement {
  const {
    ticket, setGiftItem, hide, changes, setChanges,
  } = props
  const { organisation } = useOrganisation()

  const handleDeletePrice = async (id: number): Promise<void> => {
    const res = await archiveTicketPrice({ id })
    if (res) setGiftItem(res)
  }

  const handleStatus = async (id: number, isOn: boolean): Promise<void> => {
    const status = isOn ? GiftItemStatus.Published : GiftItemStatus.Unpublished

    const res = await patchTicketPriceStatus({ id, status })
    if (res) setGiftItem(res)
  }

  const unpublishIfNoPrices = async (): Promise<void> => {
    const hasPricesButNoPublishedPrices = ticket.prices
      && ticket.prices.length > 0
      && ticket.prices.every((price) => price.status !== GiftItemStatus.Published)

    if (changes?.status === GiftItemStatus.Published) {
      setChanges({ ...changes, status: GiftItemStatus.Unpublished })
      return
    }

    // Unpublish Ticket if all prices unpublished
    if (
      organisation
      && ticket.id
      && hasPricesButNoPublishedPrices
      && ticket.status === GiftItemStatus.Published
    ) {
      const res = await patchGiftItem({
        format: organisation.format,
        itemId: ticket.id,
        patchOptions: {
          status: GiftItemStatus.Unpublished,
        },
      })
      if (res) setGiftItem(res)
    }
  }

  useEffect(() => {
    unpublishIfNoPrices()
  }, [ticket.prices])

  const activePrices = ticket.prices
    && [...ticket.prices].filter(
      ({ status }) => status !== GiftItemStatus.Archived,
    )

  const hasPrices = ticket.prices && ticket.prices.length > 0

  return (
    <Styles>
      <h3>Ticket prices</h3>
      <TicketAddPrices ticket={ticket} setGiftItem={setGiftItem} />
      {!hasPrices && (
        <Buttonlink onClick={hide} style={{ fontSize: '13px' }}>
          Hide multiple prices
        </Buttonlink>
      )}

      {activePrices?.length && organisation ? (
        <SimpleTable>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Value</th>
              <th>Status</th>
              <th>Delete</th>
            </tr>
            {activePrices.map(({
              id, name, value, status,
            }) => {
              if (status === GiftItemStatus.Archived) return null

              return (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{valueToCurrency(value, organisation.currencyCode)}</td>
                  <td aria-label="Published">
                    <Switch
                      value={status === GiftItemStatus.Published}
                      switchAction={(isOn): Promise<void> => handleStatus(id, isOn)}
                      label={
                        status === GiftItemStatus.Published
                          ? 'Enabled'
                          : 'Disabled'
                      }
                      flipLabel={true}
                    />
                  </td>
                  <td>
                    <AsyncButton
                      onClick={(): Promise<void> => handleDeletePrice(id)}
                      buttonStyle={ButtonStyle.Secondary}
                      buttonSize={ButtonSize.Table}
                      showConfirmation={true}
                      style={{ width: '46px' }}
                    >
                      Delete
                    </AsyncButton>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </SimpleTable>
      ) : null}
    </Styles>
  )
}
