import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import {
  Buttonlink,
  colors,
  DeliveryFormat,
  GiftCardStatus,
  hasRole,
  IGiftCardResponse,
  Role,
} from '@one-tree/library'
import { useOrganisation } from '../../context/OrganisationProvider'
import { transformDate, TransformDateFormat } from '../../helpers/DateHelper'
import { isResendingAllowed } from '../../helpers/GiftCardHelper'
import ExtendExpiryDate from './resultTable/editableRows/ExtendExpiryDate'
import History from './History'
import { OrganisationFormat } from '../../types/Types'
import EditRecipientEmail from './resultTable/editableRows/EditRecipientEmail'
import EditStatus from './resultTable/editableRows/EditStatus'
import TableHeader from './resultTable/TableHeader'
import { formatCurrency } from '../../helpers/CurrencyHelper'
import EditNameAndMessage from './resultTable/editableRows/EditNameAndMessage'
import ValidityRow from './resultTable/regularRows/ValidityRow'
import ResultTableRow from './resultTable/regularRows/ResultTableRow'
import RedeemedByRow from './resultTable/regularRows/RedeemedByRow'
import StatusRow from './resultTable/regularRows/StatusRow'
import { stripHTML, toSentence } from '../../helpers/DataTransformer'
import { RoutePath } from '../../types/Routes'
import EditPurchaserEmail from './resultTable/editableRows/EditPurchaserEmail'
import ResendOrderConfirmation from './ResendOrderConfirmation'
import EditDeliveryAddress from './resultTable/editableRows/editDeliveryAddress/EditDeliveryAddress'
import { itemWord, voucherWord } from '../../helpers/FormatHelper'
import ChangeDispatchDate from './resultTable/editableRows/ChangeDispatchDate'
import EditNotes from './resultTable/editableRows/EditNotes'

type Props = {
  giftCard: IGiftCardResponse
  setGiftCard(card: IGiftCardResponse): void
  justRedeemed: boolean
  fetchGiftCard: () => void
}
export default function UserResultTable(props: Props): ReactElement {
  const {
    giftCard, setGiftCard, justRedeemed, fetchGiftCard,
  } = props
  const { organisation, organisationUser } = useOrganisation()

  const {
    purchaserTitle,
    purchaserFirstName,
    purchaserLastName,
    deliverTo,
    deliveryFormat,
    status,
    deliveryDate,
    giftItemName,
    originalPrice,
    value,
    id,
    code,
    giftItemStart,
    startDate,
    redeemUser,
    redeemDate,
    giftItemDetails,
    giftItemTerms,
    giftItemRestrictions,
    orderId,
    purchaseTimestamp,
    purchaserPhone,
    dispatchedDate,
    manualUser,
    paymentReference,
    priceName,
  } = giftCard

  const { role } = organisationUser || {}
  const { currencyCode } = organisation || {}

  const isAdmin = role && hasRole(role, Role.OrganisationAdmin)

  const dispatchedDateFormatted = dispatchedDate
    && transformDate(dispatchedDate, TransformDateFormat.ShortWithoutTime)

  const deliveryDateFormatted = transformDate(
    deliveryDate,
    TransformDateFormat.ShortWithoutTime,
  )

  const getDispatchedDateText = (): string => {
    if (deliveryFormat === DeliveryFormat.Post && dispatchedDate) {
      return ` on ${dispatchedDateFormatted}`
    }
    if (deliveryFormat === DeliveryFormat.Email) {
      return ` on ${deliveryDateFormatted}`
    }
    return ''
  }

  const renderDispatchStatus = (): ReactElement => {
    if (deliveryFormat === DeliveryFormat.Print) {
      return <ResultTableRow title="Dispatch status" value="Printed manually" />
    }

    if (status === GiftCardStatus.Paid) {
      return (
        <>
          <ResultTableRow
            title="Dispatch status"
            value={`Awaiting dispatch to ${deliverTo} by ${deliveryFormat}`}
          />
          {isAdmin ? (
            <ChangeDispatchDate giftCard={giftCard} setGiftCard={setGiftCard} />
          ) : (
            <ResultTableRow
              title="Dispatch date"
              value={deliveryDateFormatted}
            />
          )}
        </>
      )
    }

    return (
      <>
        <ResultTableRow
          title="Dispatched by"
          value={`${deliveryFormat}${getDispatchedDateText()}`}
        />
        {organisation?.format === OrganisationFormat.GiftVouchers && (
          <ResultTableRow title="Dispatched to" value={deliverTo} />
        )}
      </>
    )
  }

  return (
    <tbody>
      <ValidityRow
        status={status}
        organisationFormat={organisation?.format}
        justRedeemed={justRedeemed}
      />
      <ResultTableRow
        title={`${itemWord(organisation)} name`}
        value={giftItemName}
      />
      {priceName && (
        <ResultTableRow
          title={`${itemWord(organisation)} price`}
          value={priceName}
        />
      )}
      {organisation?.format === OrganisationFormat.SpecialOffers && (
        <ResultTableRow
          title="Original price"
          value={
            currencyCode
            && originalPrice
            && formatCurrency(originalPrice, currencyCode)
          }
          style={{
            textDecoration: 'line-through',
            color: colors.darkerGray,
          }}
        />
      )}
      <ResultTableRow
        title="Value"
        value={currencyCode && formatCurrency(value, currencyCode)}
      />
      <ResultTableRow title="ID" value={id} />
      <ResultTableRow title="16 character code" value={code} />
      {organisation?.format === OrganisationFormat.Tickets ? (
        <ResultTableRow title="Event date" value={giftItemStart} />
      ) : (
        <>
          <ResultTableRow
            title="Valid from"
            value={transformDate(
              startDate,
              TransformDateFormat.LongWithoutTime,
            )}
          />
          <ExtendExpiryDate giftCard={giftCard} setGiftCard={setGiftCard} />
        </>
      )}
      <TableHeader title={`${voucherWord(organisation)} status`} />
      {role && hasRole(role, Role.OrganisationAdmin) ? (
        <EditStatus giftCard={giftCard} setGiftCard={setGiftCard} />
      ) : (
        <StatusRow status={status} />
      )}
      <RedeemedByRow
        status={status}
        redeemUser={redeemUser}
        redeemDate={redeemDate}
      />
      {renderDispatchStatus()}
      {isResendingAllowed(organisationUser, giftCard) && (
        <EditRecipientEmail giftCard={giftCard} setGiftCard={setGiftCard} />
      )}
      {deliveryFormat === DeliveryFormat.Post && (
        <EditDeliveryAddress giftCard={giftCard} setGiftCard={setGiftCard} />
      )}
      <TableHeader title={`${voucherWord(organisation)} details`} />
      {organisation?.format === OrganisationFormat.GiftVouchers && (
        <EditNameAndMessage giftCard={giftCard} setGiftCard={setGiftCard} />
      )}
      {organisation?.format === OrganisationFormat.GiftVouchers && (
        <ResultTableRow
          title="Description"
          value={stripHTML(giftItemDetails)}
        />
      )}
      <ResultTableRow
        title="Extended description"
        value={stripHTML(giftItemTerms)}
      />
      {organisation?.format === OrganisationFormat.SpecialOffers && (
        <ResultTableRow
          title="Important information"
          value={stripHTML(giftItemRestrictions)}
        />
      )}
      <TableHeader title="Order details" />
      <ResultTableRow
        title="Order ID"
        value={
          role && hasRole(role, Role.OrganisationAdmin) ? (
            <Link to={`${RoutePath.Invoices}/${orderId}`}>
              <Buttonlink>{orderId.toString()}</Buttonlink>
            </Link>
          ) : (
            orderId
          )
        }
      />
      {manualUser && (
        <>
          <ResultTableRow title="Issued by" value={manualUser} />
          <ResultTableRow title="Reference" value={paymentReference} />
        </>
      )}
      <ResultTableRow
        title="Purchased on"
        value={transformDate(
          purchaseTimestamp,
          TransformDateFormat.ShortWithTime,
        )}
      />
      <ResultTableRow
        title="Purchaser"
        value={toSentence(
          purchaserTitle,
          purchaserFirstName,
          purchaserLastName,
        )}
      />
      <ResultTableRow title="Purchaser phone" value={purchaserPhone} />
      <EditPurchaserEmail giftCard={giftCard} setGiftCard={setGiftCard} />
      <ResendOrderConfirmation
        giftCard={giftCard}
        fetchGiftCard={fetchGiftCard}
      />
      {organisation?.format === OrganisationFormat.Tickets && (
        <EditNotes giftCard={giftCard} setGiftCard={setGiftCard} />
      )}
      <History giftCard={giftCard} />
    </tbody>
  )
}
