import React, { ReactElement, useEffect, useState } from 'react'
import {
  alertError,
  alertSuccess,
  InputType,
  InputField,
} from '@one-tree/library'
import { patchGiftCard } from '../../../../helpers/APIHelper'
import { validateEmail } from '../../../../helpers/ValidationHelper'
import { IEditGiftCardProps } from '../../../../types/API'
import EditableTableRow from './EditableTableRow'

function EditRecipientEmail(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props

  const [email, setEmail] = useState(giftCard.deliveryEmail)
  const [error, setError] = useState(false)
  const [changeEmailReason, setChangeEmailReason] = useState('')
  const [emailIsEditing, setEmailIsEditing] = useState(false)
  const handleChangeEmail = async (): Promise<void> => {
    if (validateEmail(email)) {
      setError(true)
      alertError(validateEmail(email))
      return
    }
    setError(false)
    const result = await patchGiftCard({
      cardId: giftCard.id,
      patchOptions: {
        deliveryEmail: email,
        reason: changeEmailReason,
      },
    })
    if (result) {
      alertSuccess('Email updated')
      setEmail(result.deliveryEmail)
      setChangeEmailReason('')
      setEmailIsEditing(false)
      setGiftCard(result)
    }
  }

  useEffect(() => {
    setEmail(giftCard.deliveryEmail)
  }, [giftCard.deliveryEmail])

  return (
    <EditableTableRow
      title="Delivery email"
      isEditing={emailIsEditing}
      setIsEditing={setEmailIsEditing}
      editReason={changeEmailReason}
      setEditReason={setChangeEmailReason}
      saveAction={handleChangeEmail}
      cancelAction={(): void => {
        setChangeEmailReason('')
        setError(false)
        setEmail(giftCard.deliveryEmail)
      }}
    >
      <InputField
        placeholder="Add an email address..."
        type={InputType.Text}
        onChange={setEmail}
        value={email}
        isLong={true}
        isError={error}
      />
    </EditableTableRow>
  )
}
export default EditRecipientEmail
