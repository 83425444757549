import { colors } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import packageJson from '../../../package.json'

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 5px 1px;
  font-size: 0.8rem;
  color: ${colors.black};
  background-color: ${colors.white};
  border-top-left-radius: 4px;
`

function Footer(): ReactElement {
  return (
    <StyledFooter>
      {`Version: ${packageJson.version}`}
    </StyledFooter>
  )
}

export default Footer
