import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  alertError,
  alertSuccess,
  InputType,
  InputField,
  InputGroup,
} from '@one-tree/library'
import { patchGiftCard } from '../../../../../helpers/APIHelper'
import {
  getAddressFromCard,
  renderAddress,
} from '../../../../../helpers/GiftCardHelper'
import { IEditGiftCardProps } from '../../../../../types/API'
import EditIcon from '../EditIcon'
import EditDeliveryAddressRows from './EditDeliveryAddressRows'

const AddressCell = styled.td`
  display: flex;
  align-items: center;
  column-gap: 20px;
`
const EditCell = styled.td`
  padding-left: 3px !important;
`

function EditDeliveryAddress(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props

  const { id, deliverTo } = giftCard

  const [address, setAddress] = useState(
    getAddressFromCard(giftCard, deliverTo),
  )
  const [reasonError, setReasonError] = useState(false)
  const [changeReason, setChangeReason] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const handleChangeAddress = async (): Promise<void> => {
    const res = await patchGiftCard({
      cardId: id,
      patchOptions: {
        ...address,
        reason: changeReason,
      },
    })
    if (res) {
      alertSuccess('Address updated')
      setAddress(getAddressFromCard(res, deliverTo))
      setChangeReason('')
      setIsEditing(false)
      setGiftCard(res)
    }
  }

  const handleCancel = (): void => {
    setChangeReason('')
    setIsEditing(false)
    setAddress(getAddressFromCard(giftCard, deliverTo))
  }

  useEffect(() => {
    setAddress(getAddressFromCard(giftCard, deliverTo))
  }, [giftCard])

  return (
    <>
      <tr>
        <th>Delivery address</th>
        {isEditing ? (
          <EditCell>
            <EditDeliveryAddressRows
              deliverTo={deliverTo}
              address={address}
              setAddress={setAddress}
            />
          </EditCell>
        ) : (
          <AddressCell>
            <div>{renderAddress(address, deliverTo)}</div>
            <EditIcon onClick={(): void => setIsEditing(true)} />
          </AddressCell>
        )}
      </tr>
      {isEditing && (
        <tr>
          <th aria-label="Reason">&nbsp;</th>
          <EditCell>
            <InputGroup>
              <InputField
                placeholder="Reason for change"
                type={InputType.Text}
                value={changeReason}
                onChange={setChangeReason}
                isLong={true}
                isError={reasonError}
              />
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => handleCancel()}
              >
                Cancel
              </Button>
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                onClick={(): void => {
                  if (!changeReason.length) {
                    alertError('You must include a reason')
                    setReasonError(true)
                  } else {
                    setReasonError(false)
                    handleChangeAddress()
                  }
                }}
              >
                Save
              </AsyncButton>
            </InputGroup>
          </EditCell>
        </tr>
      )}
    </>
  )
}
export default EditDeliveryAddress
