import { IOrganisationResponse } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { capitalize } from '../../helpers/DataTransformer'
import {
  getFormatIcon,
  getOrganisationColor,
  organisationWord,
} from '../../helpers/FormatHelper'

const Styles = styled.div`
  svg {
    width: 32px;
    height: auto;
    margin-right: 7px;
    padding-left: 1px;
  }
  display: flex;
  align-items: center;
  margin: 2px 0;
`
const Name = styled.div`
  padding-right: 5px;
`
const Format = styled.div`
  font-size: 0.9rem;
  color: ${(props): string => props.theme};
`
export interface IOrganisationOptionProps {
  organisation: IOrganisationResponse
  onClick?: () => void
}

function OrganisationOption({
  organisation,
  onClick,
}: IOrganisationOptionProps): ReactElement {
  return (
    <Styles onClick={onClick}>
      <>
        {getFormatIcon(organisation)}
        <div>
          <Name>{organisation.name}</Name>
          <Format theme={getOrganisationColor(organisation.format)}>
            {`${capitalize(organisationWord(organisation))}s`}
          </Format>
        </div>
      </>
    </Styles>
  )
}
export default OrganisationOption
