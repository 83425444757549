import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react'
import { AxisOptions, Chart as ReactGraph } from 'react-charts'
import styled from 'styled-components'
import { lighten } from 'polished'
import {
  CurrencyCode,
  Loading,
  Switch,
  useDebounce,
  IGraphData,
  IHistoricData,
  ISeries,
  ReportDateRange,
  ReportMetric,
  ReportMetricType,
  getOverviewColor,
  ReportingWidget,
} from '@one-tree/library'
import { renderTotal } from '../ReportingHelper'
import { getReport } from '../../../helpers/APIReportHelper'
import { voucherWord } from '../../../helpers/FormatHelper'
import { useOrganisation } from '../../../context/OrganisationProvider'
import logo from '../../../assets/watermark.png'

const GraphWrapper = styled.div`
  min-height: 300px;
  margin: 20px;
  .tick {
    line {
      display: none;
    }
    text {
      /* needed to override react-charts inline styles  */
      font-size: 1rem !important;
    }
  }
  .tickLabel {
    white-space: pre;
  }
`
interface IGraphProps {
  currency: CurrencyCode
  loading: boolean
  data: IHistoricData[] | undefined
  dateRange: ReportDateRange
  metric: ReportMetric
  metricType: ReportMetricType
  fromMonth: number
  fromYear: number
}
export default function Graph(props: IGraphProps): ReactElement {
  const {
    currency,
    loading,
    data,
    metric,
    metricType,
    dateRange,
    fromMonth,
    fromYear,
  } = props

  const [compare, setCompare] = useState(false)
  const [prevData, setPrevData] = useState<IHistoricData[]>()

  const fetchData = async (): Promise<void> => {
    setPrevData(undefined)
    const res = await getReport({ fromMonth, fromYear: fromYear - 1 })
    if (res) setPrevData(res)
  }

  useEffect(() => {
    fetchData()
  }, [useDebounce(fromYear, 500), useDebounce(fromMonth, 500)])

  const xAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      getValue: (datum: IGraphData): string => datum.label,
    }),
    [],
  )
  const yAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      min: 0,
      elementType: 'bar',
      getValue: (datum: IGraphData): number => datum.total,
      formatters: {
        scale: (value: number): string => renderTotal(value, metricType, currency, 0),
      },
    }),
    [metricType],
  )

  const seriesData = {
    label: '',
    data: !data
      ? [{ label: 'loading', total: 0 }]
      : data.map((bin) => ({
        label: bin.label,
        total: bin[metric][metricType],
      })),
  }
  const seriesPrevData = prevData && {
    label: '',
    data: prevData.map((bin) => ({
      label: bin.label,
      total: bin[metric][metricType],
    })),
  }

  const showPrevData = compare && !loading

  const series: ISeries[] = useMemo(
    () => (seriesPrevData && showPrevData
      ? [seriesPrevData, seriesData]
      : [seriesData]),
    [data, prevData, compare, loading, metric, metricType],
  )

  const selectedColor = getOverviewColor(metric)
  const previousColor = lighten(0.25, selectedColor)

  const showPrevious = (
    <>
      , <span style={{ color: previousColor }}>{dateRange.previous}</span>
    </>
  )

  const renderTitle = (
    <div>
      Graph of {voucherWord(useOrganisation().organisation)} {metric} by{' '}
      {metricType}:{' '}
      {<span style={{ color: selectedColor }}>{dateRange.selected}</span>}{' '}
      {compare && showPrevious}
    </div>
  )

  const defaultColors = seriesPrevData && showPrevData
    ? [previousColor, selectedColor]
    : [selectedColor]

  const controls = prevData ? (
    <Switch
      label="Compare with previous"
      switchAction={setCompare}
      value={compare}
    />
  ) : (
    <Loading />
  )

  return (
    <ReportingWidget
      title={renderTitle}
      controls={controls}
      downloadPng="reporting-graph"
      logo={logo}
    >
      <GraphWrapper>
        {!data || loading ? (
          <Loading fullPage={true} />
        ) : (
          <ReactGraph
            options={{
              data: series,
              primaryAxis: xAxis,
              secondaryAxes: [yAxis],
              defaultColors,
              tooltip: false,
            }}
          />
        )}
      </GraphWrapper>
    </ReportingWidget>
  )
}
