import {
  GiftItemStatus,
  IGiftItemResponse,
  IOrganisationResponse,
  ITicketPrice,
} from '@one-tree/library'
import isEqual from 'react-fast-compare'
import { patchGiftItem } from '../../../../../helpers/APIItemHelper'

export function getPriceChanges(
  newPrices?: ITicketPrice[],
  oldPrices?: ITicketPrice[],
): ITicketPrice[] | false {
  if (!newPrices) return false

  if (oldPrices) {
    return newPrices.filter(
      (newPrice) => !oldPrices.some((oldPrice) => isEqual(newPrice, oldPrice)),
    )
  }
  return newPrices
}

export async function updatePriceQuestions(
  organisation: IOrganisationResponse,
  prices: ITicketPrice[] | false,
): Promise<boolean> {
  if (!prices) return false

  const patchEachPrice = prices.map((price) => patchGiftItem({
    format: organisation.format,
    itemId: price.id,
    patchOptions: { enabledQuestionIds: price.enabledQuestionIds },
  }))

  const pricesRes = await Promise.all([...patchEachPrice])

  return pricesRes.every((response) => Boolean(response))
}

export function publishFreeTicketWarning(ticket: IGiftItemResponse): boolean {
  if (ticket.status === GiftItemStatus.Unpublished) {
    if (ticket.prices && ticket.prices.length > 0) {
      const hasFreePrice = ticket.prices.some(({ value }) => value === '0')
      return hasFreePrice
    }

    return ticket.value === '0'
  }
  return false
}

export function priceUsingQuestion(
  questionId: number,
  prices?: ITicketPrice[],
): boolean {
  if (!prices) return false

  return prices?.some((price) => {
    const notArchived = price.status !== GiftItemStatus.Archived
    const hasQuestion = price.enabledQuestionIds?.includes(questionId)

    return notArchived && hasQuestion
  })
}
