import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  AsyncButton, ButtonStyle, ButtonSize, alertSuccess, IGiftCardResponse,
} from '@one-tree/library'
import { post } from '../../helpers/APIHelper'
import {
  GiftCardResourceOptions,
  Resource,
} from '../../types/API'

const StyledRow = styled.tr`
  td {
    padding-left: 4px;
  }
`
const StyledHeader = styled.th`
  position: relative;
`

function ResendOrderConfirmation({
  giftCard,
  fetchGiftCard,
}: {
  giftCard: IGiftCardResponse
  fetchGiftCard: () => void
}): ReactElement {
  const handleResend = async (cardId: string): Promise<void> => {
    const result = await post({
      resource: Resource.GiftCards,
      urlPath: [
        cardId,
        GiftCardResourceOptions.ResendOrderConfirmation,
      ],
    })
    if (result) {
      alertSuccess('Message sent')
      fetchGiftCard()
    }
  }

  return (
    <StyledRow>
      <StyledHeader />
      <td>
        <AsyncButton
          buttonStyle={ButtonStyle.Primary}
          buttonSize={ButtonSize.Mini}
          onClick={(): Promise<void> => handleResend(giftCard.id)}
          showConfirmation={true}
          confirmationStyle={ButtonStyle.Primary}
          confirmationMessage={`Are you sure you want to resend order confirmation to: ${giftCard.purchaserEmail}?`}
        >
          Resend order confirmation
        </AsyncButton>
      </td>
    </StyledRow>
  )
}
export default ResendOrderConfirmation
