import EmailValidator from 'email-validator'
import { IContactPatchBody } from '../../types/API'

export const billingNameTest = (billing?: IContactPatchBody): string | false => {
  if (!billing?.email) return 'Billing contact requires email'
  if (billing.email && !EmailValidator.validate(billing.email)) {
    return 'Billing contact email invalid'
  }
  return false
}
