import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Loading,
  Buttonlink,
  colors,
  IItemisedReportResponse,
  ReportItemisation,
  ReportMetric,
  ReportMetricType,
} from '@one-tree/library'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getItemisedReport } from '../../helpers/APIReportHelper'
import { capitalize } from '../../helpers/DataTransformer'
import { voucherWord } from '../../helpers/FormatHelper'
import { RoutePath } from '../../types/Routes'
import { formatCurrency } from '../reporting/ReportingHelper'

const Styles = styled.div`
  border: 1px solid ${colors.darkGray};
  border-radius: 4px;
  background-color: ${colors.lightestGray};
  padding: 15px 15px 12px;
  margin-bottom: 30px;
  width: fit-content;
  height: 95px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 40px;
  margin-bottom: 10px;
  .quick-stats-title {
    font-weight: bold;
    margin-bottom: 1px;
  }
`
const Table = styled.table`
  th,
  td {
    text-align: left;
    padding: 0;
  }
  th {
    font-weight: normal;
  }
  td {
    font-size: 1.3rem;
    font-weight: bold;
    color: ${colors.lightBlue};
    padding-right: 30px;
    min-width: 60px;
    :last-child {
      padding-right: 0;
    }
  }
`

const getTopItem = (data: IItemisedReportResponse | false): string | false => {
  if (!data) return false
  if (data.itemisedData.length === 0) return false
  data.itemisedData.sort((a, b) => b.total - a.total)
  return data.itemisedData[0].label
}

function QuickStats(): ReactElement {
  const history = useHistory()
  const { organisation } = useOrganisation()

  const [loading, setLoading] = useState(false)

  type data = IItemisedReportResponse | false
  const [valueData, setValueData] = useState<data>(false)
  const [volumeData, setVolumeData] = useState<data>(false)

  const fromDate = moment().subtract(1, 'month').add(1, 'day').unix()
  const toDate = moment().endOf('day').unix()

  const fetchData = async (): Promise<void> => {
    setLoading(true)

    const metric = ReportMetric.Sales
    const itemiseBy = ReportItemisation.Type

    setValueData(
      await getItemisedReport({
        fromDate,
        toDate,
        metric,
        metricType: ReportMetricType.Value,
        itemiseBy,
      }),
    )

    setVolumeData(
      await getItemisedReport({
        fromDate,
        toDate,
        metric,
        metricType: ReportMetricType.Volume,
        itemiseBy,
      }),
    )

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const bestseller = getTopItem(volumeData)
  const highestRevenue = getTopItem(valueData)

  const hasData = valueData && volumeData

  const renderStats = organisation && hasData ? (
    <Table>
      <tbody>
        <tr>
          <th>Value</th>
          <th>Volume</th>
          {bestseller && <th>Bestseller</th>}
          {highestRevenue && <th>Highest revenue</th>}
        </tr>
        <tr>
          <td>
            {formatCurrency(valueData.total, organisation.currencyCode, 2)}
          </td>
          <td>{volumeData.total.toLocaleString()}</td>
          {bestseller && <td>{bestseller}</td>}
          {highestRevenue && <td>{highestRevenue}</td>}
        </tr>
      </tbody>
    </Table>
  ) : (
    <div>No sales in past month</div>
  )

  const fromDateReadable = moment.unix(fromDate).format('D MMM YYYY')
  const toDateReadable = moment.unix(toDate).format('D MMM YYYY')

  return (
    <Styles>
      <Header>
        <div>
          <div className="quick-stats-title">
            {`${capitalize(voucherWord(organisation))} sales in past month`}
          </div>
          <div>{`${fromDateReadable} - ${toDateReadable}`}</div>
        </div>
        <Buttonlink onClick={(): void => history.push(RoutePath.Reporting)}>
          View reporting
        </Buttonlink>
      </Header>
      {!loading ? renderStats : <Loading fullPage={true} />}
    </Styles>
  )
}
export default QuickStats
