import { colors } from '@one-tree/library'
import React, { ReactElement, SVGProps } from 'react'
import styled from 'styled-components'
import { PropsWithChildren } from '../../types/Types'

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 2px 3px 7px ${colors.shadow};
  min-width: 220px;
  max-width: 500px;
  height: 420px;
  margin: 40px 16px;
  flex: 1;
  position: relative;
  background-color: ${colors.white};
  h1 {
    font-size: 1.5rem;
    margin: 0;
  }
  svg {
    min-width: 80px;
    min-height: 80px;
    max-width: 80px;
    max-height: 80px;
    margin-top: -40px;
    margin-bottom: 20px;
    pointer-events: none;
  }
`
export interface IFormatCard {
  icon: SVGProps<SVGElement>
  title: string
  placeholder?: string
  className?: string
}
function FormatCard(props: PropsWithChildren<IFormatCard>): ReactElement {
  const {
    icon, title, children, className,
  } = props

  return (
    <StyledCard className={className}>
      <>
        {icon}
        <h1>{title}</h1>
        {children}
      </>
    </StyledCard>
  )
}

export default FormatCard
