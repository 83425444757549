import styled from 'styled-components'
import React, { ReactElement, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  Button,
  colors,
  hasRole,
  IGiftCardResponse,
  Role,
  voucherWord,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import { get } from '../../helpers/APIHelper'
import Page from '../../hoc/Page'
import { Resource } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import { useOrganisation } from '../../context/OrganisationProvider'
import GiftCardActions from './GiftCardActions'
import ResultTable from './resultTable/ResultTable'
import UserResultTable from './UserResultTable'
import GiftCardRedeem from './GiftCardRedeem'
import { useVoucherPagination } from '../../hooks/useVoucherPagination'

const StyledCard = styled(Card)`
  color: ${colors.black};
  h2 {
    margin: 0;
  }
  & :first-letter {
    text-transform: uppercase;
  }
  & .gift-card-email td:first-letter {
    text-transform: lowercase;
  }
`

const ActionsContainer = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  button {
    margin-left: 10px;
  }
`

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 2px solid ${colors.gray};
`

interface IGiftCardResultParams {
  id?: string
}
export default function GiftCardResult(): ReactElement {
  const { id }: IGiftCardResultParams = useParams()
  const [giftCard, setGiftCard] = useState<IGiftCardResponse | false>(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  if (!id) history.push(RoutePath.Home)

  const { organisation, organisationUser } = useOrganisation()

  // if card has just been Redeemed, though the card is now invalid, it should read 'Redeemed'
  const [justRedeemed, setJustRedeemed] = useState<boolean>(false)

  const fetchGiftCard = async (): Promise<void> => {
    if (!id) return
    setLoading(true)

    const res = await get<IGiftCardResponse>({
      resource: Resource.GiftCards,
      urlPath: [id],
    })

    if (res) {
      setGiftCard(res)
    } else {
      history.push(RoutePath.Home)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchGiftCard()
  }, [id, justRedeemed])

  const { role } = organisationUser || {}
  const isUser = role && hasRole(role, Role.OrganisationUser)

  const pagination = useVoucherPagination(giftCard)
  const goTo = (targetId: string | null): void => {
    if (targetId) history.push(`${RoutePath.VoucherDetail}/${targetId}`)
  }

  return (
    <Page>
      {giftCard && (
        <StyledCard cardSize={CardSize.Medium}>
          <ActionsContainer>
            <GiftCardActions giftCard={giftCard} setGiftCard={setGiftCard} />
            <GiftCardRedeem
              giftCard={giftCard}
              setJustRedeemed={setJustRedeemed}
            />
          </ActionsContainer>
          <ResultTable giftCard={giftCard} justRedeemed={justRedeemed}>
            {isUser && (
              <UserResultTable
                giftCard={giftCard}
                setGiftCard={setGiftCard}
                justRedeemed={justRedeemed}
                fetchGiftCard={fetchGiftCard}
              />
            )}
          </ResultTable>
          {isUser && pagination.show && organisation && (
            <Pagination>
              <Button
                disabled={!pagination.prev || loading}
                onClick={(): void => goTo(pagination.prev)}
              >
                {`Previous ${voucherWord(organisation.format)}`}
              </Button>
              <Button
                disabled={!pagination.next || loading}
                onClick={(): void => goTo(pagination.next)}
              >
                {`Next ${voucherWord(organisation.format)}`}
              </Button>
            </Pagination>
          )}
        </StyledCard>
      )}
    </Page>
  )
}
