import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { ReportingWidget, AsyncButton } from '@one-tree/library'
import { getMailingList } from '../../../helpers/APIReportHelper'

const StyledReportingWidget = styled(ReportingWidget)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
function MailingList(): ReactElement {
  return (
    <StyledReportingWidget title="Mailing list">
      <p>
        Buyers are invited to opt-in to your mailing list during checkout.
        Download a CSV file of opted-in email addresses to add to your email
        marketing list.
      </p>
      <AsyncButton
        style={{ marginTop: 'auto' }}
        onClick={async (): Promise<void> => {
          const res = await getMailingList()
          if (res) window.open(res, '_blank')
        }}
      >
        Download data
      </AsyncButton>
    </StyledReportingWidget>
  )
}
export default MailingList
