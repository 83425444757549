import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Buttonlink,
  capitalize,
  Checkbox,
  ColorField,
  colors,
  Font,
  IOrganisationResponse,
  itemWord,
  removeHash,
  SelectField,
} from '@one-tree/library'
import { get } from '../../helpers/APIHelper'
import useResponsive from '../../helpers/isResponsive'
import { IOrganisationPatchBody, Resource } from '../../types/API'
import { Header, Section } from './WebShopHelper'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 245px 245px;
  gap: 20px 25px;
  ${(props): string => props.theme.isSmallDesktop && 'grid-template-columns: 190px 190px'};
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 20px;
`

interface IWebShopThemeProps {
  values: IOrganisationResponse
  changeOrganisation: (changes: IOrganisationPatchBody) => void
}

function WebShopTheme({
  values,
  changeOrganisation,
}: IWebShopThemeProps): ReactElement {
  const [fonts, setFonts] = useState<Font[] | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      setFonts((await get<Font[]>({ resource: Resource.Fonts })) || null)
    })()
  }, [])

  const highlightValid = CSS.supports('color', `#${values.colourHighlight}`)
  const bodyTextValid = CSS.supports('color', `#${values.colourBodyText}`)
  const textOnHighlightValid = CSS.supports(
    'color',
    `#${values.colourTextOnHighlight}`,
  )

  const missingEssential = !highlightValid || !bodyTextValid || !textOnHighlightValid

  const addDefaults = (): void => {
    changeOrganisation({
      colourHighlight: highlightValid
        ? values.colourHighlight
        : removeHash(colors.logo),
      colourBodyText: bodyTextValid
        ? values.colourBodyText
        : removeHash(colors.darkestGray),
      colourTextOnHighlight: textOnHighlightValid
        ? values.colourTextOnHighlight
        : removeHash(colors.white),
    })
  }

  const handleColorChange = (
    value: string,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: removeHash(value),
    })
  }

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: value,
    })
  }

  const fontOptions = fonts
    ? fonts.map((font) => ({
      label: capitalize(font),
      value: font,
    }))
    : []

  const selectedFont = {
    label: capitalize(values.font),
    value: values.font,
  }

  const word = capitalize(itemWord(values.format))
  const placeholder = values.colourHighlight ? `#${values.colourHighlight}` : ''

  return (
    <Section>
      <Header>
        <h3>Theme</h3>
        <p>Change the general theme of your online web shop.</p>
        {missingEssential && (
          <p
            style={{
              color: `${colors.red}`,
              fontWeight: 'bold',
              maxWidth: '400px',
            }}
          >
            You are missing essential colours. Please fill in any red fields
            below, or{' '}
            <Buttonlink onClick={addDefaults}>add defaults</Buttonlink>.
          </p>
        )}
      </Header>
      <Grid theme={useResponsive()}>
        <Column>
          <SelectField
            options={fontOptions}
            label="Select a font"
            onChange={(option): void => {
              if (option) handleChange(option.value, 'font')
            }}
            value={selectedFont}
          />
          <ColorField
            label="Body text"
            onChange={(value): void => handleColorChange(value, 'colourBodyText')}
            value={values.colourBodyText}
            isError={!bodyTextValid}
          />
          <ColorField
            label="Text on highlight"
            onChange={(value): void => handleColorChange(value, 'colourTextOnHighlight')}
            value={values.colourTextOnHighlight}
            isError={!textOnHighlightValid}
          />
          <div>
            <ColorField
              label="Page background"
              onChange={(value): void => handleColorChange(value, 'colourPageBackground')}
              value={values.colourPageBackground}
              disabled={values.transparentIframe}
            />
            <Checkbox
              label="Transparent background?"
              onChange={(value): void => {
                changeOrganisation({
                  transparentIframe: value,
                  colourPageBackground: value
                    ? ''
                    : values.colourPageBackground,
                })
              }}
              value={values.transparentIframe}
              style={{ marginTop: '7px' }}
            />
          </div>
        </Column>
        <Column>
          <ColorField
            label="Highlight"
            onChange={(value): void => handleColorChange(value, 'colourHighlight')}
            value={values.colourHighlight}
            isError={!highlightValid}
          />
          <ColorField
            label={`${word} name`}
            onChange={(value): void => handleColorChange(value, 'colourHeaderText')}
            value={values.colourHeaderText}
            placeholder={placeholder}
          />
          <ColorField
            label={`${word} price`}
            onChange={(value): void => handleColorChange(value, 'colourPriceText')}
            value={values.colourPriceText}
            placeholder={placeholder}
          />
        </Column>
      </Grid>
    </Section>
  )
}
export default WebShopTheme
