import moment, { Moment } from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  NumberField,
  CurrencyField,
  Datepicker,
  InputGroup,
  ISelectOption,
  SelectField,
  Switch,
  CurrencyCode,
  Item,
  useNotFirstRender,
  ISpecialOffer,
  GiftItemStatus,
  RichField,
  capitalize,
} from '@one-tree/library'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { publishSwitchHandler } from '../../../helpers/GiftItemHelper'
import { IGiftItemPostBody } from '../../../types/GiftItemTypes'
import ItemBanner from './designElements/ItemBanner'
import ItemExtendedDescription from './designElements/ItemExtendedDescription'
import ItemImage from './designElements/ItemImage'
import ItemName from './designElements/ItemName'
import {
  categoryTest,
  expiryTest,
  getGiftItemStatus,
  isGiftItemValid,
  originalPriceTest,
  restrictionsTest,
  startDateTest,
  unpublishDateTest,
} from '../../../helpers/GiftItemValidityHelper'
import ItemPrice from './designElements/ItemPrice'
import { get } from '../../../helpers/APIHelper'
import { ICategoryResponse, Resource } from '../../../types/API'
import {
  getCategoriesAsOptions,
  getGiftVoucherCategories,
} from '../../../helpers/CategoryHelper'
import { getOrganisationColors } from '../../../helpers/OrganisationHelper'

export interface ISpecialOfferDesignProps {
  specialOffer: ISpecialOffer
  changeSpecialOffer: (changes: IGiftItemPostBody) => void
  isEditing: boolean
}

function SpecialOfferDesign({
  specialOffer,
  changeSpecialOffer,
  isEditing,
}: ISpecialOfferDesignProps): ReactElement {
  const { organisation } = useOrganisation()
  const [categories, setCategories] = useState<ICategoryResponse[]>([])
  const notFirstRender = useNotFirstRender()

  const [imagePreview, setImagePreview] = useState<{ [url: string]: string }>({
    imageUrl: specialOffer.imageUrl,
  })
  const [headerPreview, setHeaderPreview] = useState({
    headerImageUrl: specialOffer.headerImageUrl,
  })

  const giftItemWithPreview = {
    ...specialOffer,
    ...imagePreview,
    ...headerPreview,
  }

  // if user publishes, but then invalidates, republish if user makes valid again
  const [forcePublish, setForcePublish] = useState(
    specialOffer.status === GiftItemStatus.Published,
  )

  const isValid = isGiftItemValid(specialOffer, organisation)

  useEffect(() => {
    const shouldUnpublish = !isValid && specialOffer.status === GiftItemStatus.Published
    const shouldRepublish = isValid && forcePublish
    if (shouldUnpublish) {
      changeSpecialOffer({
        status: GiftItemStatus.Unpublished,
      })
    }
    if (notFirstRender && shouldRepublish) {
      changeSpecialOffer({
        status: GiftItemStatus.Published,
      })
    }
  }, [isValid])

  useEffect(() => {
    if (organisation?.categoriesEnabled) {
      (async (): Promise<void> => {
        const res = await get<ICategoryResponse[]>({
          resource: Resource.Categories,
        })
        if (res) setCategories(res)
      })()
    }
  }, [])

  const handleDateChange = (
    date: Moment | null,
    key: keyof IGiftItemPostBody,
  ): void => {
    changeSpecialOffer({
      [key]: date ? moment(date).unix() : null,
    })
  }

  const handleOriginalPriceChange = (value: string): void => {
    changeSpecialOffer({
      originalPrice: value ? parseFloat(value) : undefined,
    })
  }

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IGiftItemPostBody,
  ): void => {
    changeSpecialOffer({
      [key]: value,
    })
  }

  const setGiftVoucherCategories = (options: ISelectOption<number>[]): void => {
    changeSpecialOffer({
      categoryIds: options.map((option: ISelectOption<number>) => option.value),
    })
  }

  const categoryOptions = getCategoriesAsOptions(categories)

  return (
    <>
      <section className="gift-settings gift-settings-styles">
        <h3>Offer settings</h3>
        {specialOffer.quantitySold || specialOffer.quantitySold === 0 ? (
          <p>
            {`${specialOffer.quantitySold} sold as of ${moment().format(
              'h:mma, DD/MM/YYYY',
            )}`}
          </p>
        ) : null}
        <InputGroup>
          {organisation?.categoriesEnabled && (
            <SelectField
              placeholder={
                categoryOptions.length
                  ? 'Select categories...'
                  : 'No categories found'
              }
              options={categoryOptions}
              onChange={(options): void => {
                if (options) setGiftVoucherCategories(options)
              }}
              isMulti={true}
              isLong={true}
              value={getGiftVoucherCategories(specialOffer, categories)}
              isError={
                isEditing
                && Boolean(categoryTest({ giftItem: specialOffer, organisation }))
              }
            />
          )}
          <Switch
            label={capitalize(
              getGiftItemStatus(giftItemWithPreview, organisation),
            )}
            disabled={!isValid}
            flipLabel={true}
            value={specialOffer?.status === GiftItemStatus.Published}
            switchAction={(isOn): void => {
              publishSwitchHandler(isOn, changeSpecialOffer, setForcePublish)
            }}
          />
        </InputGroup>
        <InputGroup>
          <NumberField
            label="Maximum per order"
            placeholder="Default value is 100"
            onChange={(number): void => handleChange(number, 'maxPerOrder')}
            value={specialOffer.maxPerOrder}
          />
          <NumberField
            label="Quantity"
            placeholder="Default is unlimited"
            onChange={(number): void => handleChange(number, 'quantity')}
            value={specialOffer.quantity}
          />
          <Switch
            label="Show quantity remaining?"
            switchAction={(action): void => handleChange(action, 'showRemaining')}
            value={specialOffer.showRemaining}
            flipLabel={true}
            labelOffset={true}
          />
        </InputGroup>
        <InputGroup>
          <Datepicker
            label="Publish date (optional)"
            onChange={(date): void => {
              handleDateChange(date, 'publishDate')
            }}
            value={
              specialOffer.publishDate
                ? moment.unix(specialOffer.publishDate)
                : null
            }
            isClearable={true}
            futureOnly={true}
          />
          <Datepicker
            label="Unpublish date (optional)"
            onChange={(date): void => {
              handleDateChange(date, 'unpublishDate')
            }}
            value={
              specialOffer.unpublishDate
                ? moment.unix(specialOffer.unpublishDate)
                : null
            }
            isClearable={true}
            futureOnly={true}
            isError={
              isEditing
              && Boolean(
                unpublishDateTest({ giftItem: specialOffer, organisation }),
              )
            }
          />
        </InputGroup>
        {organisation && (
          <InputGroup>
            <CurrencyField
              label="Original price (optional)"
              currency={organisation.currencyCode}
              initValue={
                specialOffer.originalPrice
                  ? specialOffer.originalPrice.toString()
                  : undefined
              }
              onChange={(value): void => {
                handleOriginalPriceChange(value)
              }}
              isError={
                isEditing
                && Boolean(
                  originalPriceTest({ giftItem: specialOffer, organisation }),
                )
              }
              subtitle={
                (isEditing
                  && originalPriceTest({
                    giftItem: specialOffer,
                    organisation,
                  }))
                || 'Price including VAT'
              }
            />
            <ItemPrice
              label="Sale price"
              giftItem={specialOffer}
              changeItem={changeSpecialOffer}
              organisation={organisation}
              isEditing={isEditing}
            />
          </InputGroup>
        )}
      </section>
      <section className="gift-design">
        <h3>Offer design</h3>
        <ItemName
          label="Offer name"
          value={specialOffer.name}
          giftItem={specialOffer}
          changeItem={changeSpecialOffer}
          organisation={organisation}
          isEditing={isEditing}
        />
        <ItemImage
          label="Offer image"
          previewUrl={imagePreview.imageUrl}
          giftItem={specialOffer}
          changeItem={changeSpecialOffer}
          changePreview={setImagePreview}
          organisation={organisation}
          isEditing={isEditing}
        />
        <ItemBanner
          label="Voucher header"
          previewUrl={headerPreview.headerImageUrl}
          changeItem={changeSpecialOffer}
          changePreview={setHeaderPreview}
        />
      </section>
      <section className="preview-design">
        <h3>Web preview</h3>
        <Item
          giftItem={giftItemWithPreview}
          currency={organisation?.currencyCode || CurrencyCode.GBP}
          organisationColors={getOrganisationColors(organisation)}
        />
      </section>
      <section className="gift-description">
        <h3>Offer description</h3>
        <InputGroup subtitle="Enter the start date and the end date for when the voucher may be used.">
          <Datepicker
            label="Start date"
            onChange={(date): void => {
              handleDateChange(date, 'startDate')
            }}
            value={moment.unix(specialOffer.startDate)}
            futureOnly={true}
            isError={
              isEditing
              && Boolean(startDateTest({ giftItem: specialOffer, organisation }))
            }
          />
          <Datepicker
            label="Expiry date"
            onChange={(date): void => {
              handleDateChange(date, 'expiryDate')
            }}
            value={moment.unix(specialOffer.expiryDate)}
            futureOnly={true}
            isError={
              isEditing
              && Boolean(expiryTest({ giftItem: specialOffer, organisation }))
            }
          />
        </InputGroup>
        <ItemExtendedDescription
          value={specialOffer.terms}
          giftItem={specialOffer}
          changeItem={changeSpecialOffer}
          label="Description"
          isEditing={isEditing}
          organisation={organisation}
          maxChars={1000}
          placeholder="Add a description of the services included in the offer"
        />
        <RichField
          label="Important information"
          placeholder="Add redemption details or exclusion information"
          maxChars={300}
          value={specialOffer.restrictions}
          onChange={(content): void => handleChange(content, 'restrictions')}
          isError={
            isEditing
            && Boolean(restrictionsTest({ giftItem: specialOffer, organisation }))
          }
          subtitle={
            (isEditing
              && restrictionsTest({ giftItem: specialOffer, organisation }))
            || undefined
          }
        />
      </section>
      <section className="preview-description">
        <h3>Web preview (details)</h3>
        <Item
          giftItem={giftItemWithPreview}
          currency={organisation?.currencyCode || CurrencyCode.GBP}
          setDetails={true}
          organisationColors={getOrganisationColors(organisation)}
        />
      </section>
    </>
  )
}
export default SpecialOfferDesign
