import React, { ReactElement, useState } from 'react'
import moment from 'moment'
import download from 'downloadjs'
import {
  AsyncButton,
  FormattedDateTime,
  IColumn,
  IFilters,
  IOrganisationResponse,
  Order,
  RequestWithFilters,
  Table,
} from '@one-tree/library'
import Page from '../../hoc/Page'
import { exportOrderInvoices, orderInvoices } from '../../helpers/APIHelper'
import { RoutePath } from '../../types/Routes'
import { formatCurrency } from '../../helpers/CurrencyHelper'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import { useOrganisation } from '../../context/OrganisationProvider'
import { IInvoiceResponse } from '../../types/API'
import CardHeader from '../../components/page/CardHeader'
import { capitalize } from '../../helpers/DataTransformer'
import { getCSVExportTitle } from '../reporting/ReportingHelper'

const columns = (
  organisation: IOrganisationResponse | null,
): IColumn<IInvoiceResponse>[] => [
  {
    accessor: 'id',
    header: 'Order ID',
  },
  {
    accessor: 'purchaserFirstName',
    header: 'First name',
    parent: 'Purchaser',
  },
  {
    accessor: 'purchaserLastName',
    header: 'Last name',
    parent: 'Purchaser',
  },
  {
    accessor: 'date',
    header: 'Date',
    display: ({ date }) => <FormattedDateTime timestamp={date} />,
  },
  {
    accessor: 'type',
    header: 'Order type',
    display: ({ type }) => capitalize(type),
  },
  {
    accessor: 'subtotal',
    header: 'Subtotal',
    display: ({ subtotal }) => (subtotal && organisation
      ? formatCurrency(subtotal, organisation.currencyCode)
      : '-'),
  },
  {
    accessor: 'delivery',
    header: 'Delivery',
    display: ({ delivery }) => (delivery && organisation
      ? formatCurrency(delivery, organisation.currencyCode)
      : '-'),
  },
  {
    accessor: 'total',
    header: 'Total',
    display: ({ total }) => (total && organisation
      ? formatCurrency(total, organisation.currencyCode)
      : '-'),
  },
  {
    accessor: 'paymentReference',
    header: 'Payment reference',
  },
]

export default function OrderInvoices(): ReactElement {
  const { organisation } = useOrganisation()

  type TableState = RequestWithFilters<
    IInvoiceResponse,
    IFilters<IInvoiceResponse>[]
  >
  const [tableState, setTableState] = useState<TableState>()

  const fetchCSV = async (): Promise<void> => {
    const { fromDate, toDate, search } = tableState || {}

    const res = await exportOrderInvoices({ fromDate, toDate, search })
    if (res) download(res, getCSVExportTitle(fromDate, toDate, 'Order invoices'))
  }

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="Order invoices">
          <AsyncButton onClick={fetchCSV}>Download CSV</AsyncButton>
        </CardHeader>
        <CardContent>
          <Table
            fetch={orderInvoices}
            columns={columns(organisation)}
            options={{
              orderBy: 'date',
              orderDirection: Order.Desc,
            }}
            search={true}
            dates={{
              startDate: moment().subtract(1, 'year').add(1, 'day').unix(),
              endDate: moment().unix(),
            }}
            rowLink={{ route: RoutePath.Invoices, slug: 'id' }}
            getState={setTableState}
          />
        </CardContent>
      </Card>
    </Page>
  )
}
