import {
  CurrencyCode,
  IBreakdownParams,
  IOverviewParams,
  ReportItemisation,
  ReportMetric,
  ReportMetricType,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useOrganisation } from '../../context/OrganisationProvider'
import Page from '../../hoc/Page'
import Breakdown from './breakdown/Breakdown'
import Downloads from './downloads/Downloads'
import Overview from './overview/Overview'
import { isQueryValid, setQuery } from './ReportingHelper'

function Reporting(): ReactElement {
  const { organisation } = useOrganisation()
  const currency = organisation?.currencyCode || CurrencyCode.GBP

  const history = useHistory()
  const location = useLocation()
  const queryParams = Object.fromEntries(new URLSearchParams(location.search))
  const queryValid = isQueryValid(queryParams)

  // have checked for errors with `isQueryValid`
  /* eslint-disable no-type-assertion/no-type-assertion */

  const overviewInit = {
    overviewMetric:
      queryValid && queryParams.overviewMetric
        ? (queryParams.overviewMetric as ReportMetric)
        : ReportMetric.Sales,
    overviewMetricType:
      queryValid && queryParams.overviewMetricType
        ? (queryParams.overviewMetricType as ReportMetricType)
        : ReportMetricType.Value,
    overviewFromMonth:
      queryValid && queryParams.overviewFromMonth
        ? Number(queryParams.overviewFromMonth)
        : moment().month() + 1,
    overviewFromYear:
      queryValid && queryParams.overviewFromYear
        ? Number(queryParams.overviewFromYear)
        : moment().year() - 1,
  }

  const breakdownInit = {
    breakdownMetric:
      queryValid && queryParams.breakdownMetric
        ? (queryParams.breakdownMetric as ReportMetric)
        : ReportMetric.Sales,
    // default itemiseBy to `name`, as for TESO the selector is not shown and should always be name
    breakdownItemiseBy:
      queryValid && queryParams.breakdownItemiseBy
        ? (queryParams.breakdownItemiseBy as ReportItemisation)
        : ReportItemisation.Name,
    breakdownMetricType:
      queryValid && queryParams.breakdownMetricType
        ? (queryParams.breakdownMetricType as ReportMetricType)
        : ReportMetricType.Value,
    breakdownFromDate:
      queryValid && queryParams.breakdownFromDate
        ? Number(queryParams.breakdownFromDate)
        : moment().subtract(1, 'year').add(1, 'day').valueOf(),
    breakdownToDate:
      queryValid && queryParams.breakdownToDate
        ? Number(queryParams.breakdownToDate)
        : moment().endOf('day').valueOf(),
  }

  const [updateParams, setUpdateParams] = useState<Partial<IOverviewParams & IBreakdownParams>>()
  useEffect(() => {
    if (updateParams) {
      history.replace({
        pathname: location.pathname,
        search: setQuery(location.search, updateParams),
      })
    }
  }, [updateParams])

  return (
    <Page>
      <Overview
        currency={currency}
        init={overviewInit}
        updateParams={(updates): void => {
          setUpdateParams((prevState) => ({ ...prevState, ...updates }))
        }}
      />
      <Breakdown
        currency={currency}
        init={breakdownInit}
        updateParams={(updates): void => {
          setUpdateParams((prevState) => ({ ...prevState, ...updates }))
        }}
      />
      <Downloads />
    </Page>
  )
}
export default Reporting
