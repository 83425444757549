import React, { ReactElement } from 'react'
import {
  AsyncButton,
  ButtonStyle,
  alertSuccess,
  Role,
  hasRole,
  IGiftCardResponse,
} from '@one-tree/library'
import { Resource, GiftCardResourceOptions } from '../../types/API'
import { post, getOrgAccessToken } from '../../helpers/APIHelper'
import {
  isGiftCardValid,
  isResendingAllowed,
} from '../../helpers/GiftCardHelper'
import { useOrganisation } from '../../context/OrganisationProvider'
import { StateAction } from '../../types/Aliases'

interface IGiftCardActions {
  giftCard: IGiftCardResponse
  setGiftCard: StateAction<IGiftCardResponse | false>
}

function GiftCardActions(props: IGiftCardActions): ReactElement | null {
  const { giftCard, setGiftCard } = props
  const { organisationUser } = useOrganisation()
  const { role } = organisationUser || {}

  const handleResend = async (cardId: string): Promise<void> => {
    const res = await post<IGiftCardResponse>({
      resource: Resource.GiftCards,
      urlPath: [cardId, GiftCardResourceOptions.Resend],
    })
    if (res) {
      alertSuccess('Message sent')
      setGiftCard(res)
    }
  }

  const handlePdf = async (): Promise<void> => {
    const pdfToken = await getOrgAccessToken()
    if (pdfToken) {
      // setTimeout is needed for safari and firefox to not block the new tab
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
      setTimeout(() => {
        window.open(`${giftCard.pdfUrl}?token=${pdfToken}`, '_blank')
      })
    }
  }

  // only show download button if the gift card is the standard One Tree type, as other imported
  // types won't work
  const isStandardGift = giftCard.code.replaceAll(/[^A-Za-z0-9]/g, '').length === 16

  return isGiftCardValid(giftCard.status) ? (
    <>
      {role && hasRole(role, Role.OrganisationUser) && isStandardGift && (
        <AsyncButton buttonStyle={ButtonStyle.Primary} onClick={handlePdf}>
          Download PDF
        </AsyncButton>
      )}
      {isResendingAllowed(organisationUser, giftCard) && (
        <AsyncButton
          buttonStyle={ButtonStyle.Primary}
          onClick={(): Promise<void> => handleResend(giftCard.id)}
          showConfirmation={true}
          confirmationStyle={ButtonStyle.Primary}
          confirmationMessage={`Are you sure you want to resend this voucher to: ${giftCard.deliveryEmail}?`}
        >
          Resend email
        </AsyncButton>
      )}
    </>
  ) : null
}
export default GiftCardActions
