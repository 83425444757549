import React, { FormEvent, ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonStyle,
  Buttonlink,
  InputField,
  InputType,
} from '@one-tree/library'
import { RoutePath } from '../../types/Routes'
import { requestPassword } from '../../helpers/APIHelper'
import { validateEmail } from '../../helpers/ValidationHelper'
import { ILoginMessages, IMessagingProps } from '../../types/Types'
import { IPasswordRequestResponse as IRequestPasswordResponse } from '../../types/API'

function RequestPassword(props: IMessagingProps<ILoginMessages>): ReactElement {
  const { sendMessages, receiveMessages } = props
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState<boolean>(false)

  const handleRequestPassword = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()

    sendMessages()
    if (validateEmail(email)) {
      sendMessages({ email: validateEmail(email) })
      return
    }

    setLoading(true)
    const result: IRequestPasswordResponse | false = await requestPassword({
      email,
    })

    if (result) {
      sendMessages({ success: 'Check your emails' })
    } else {
      sendMessages({ error: 'User not found' })
    }
    setLoading(false)
  }

  return (
    <>
      <form className="form" noValidate={true} onSubmit={handleRequestPassword}>
        <InputField
          type={InputType.Email}
          onChange={setEmail}
          value={email}
          isError={Boolean(receiveMessages.email)}
          placeholder="Email address"
        />
        <Button buttonStyle={ButtonStyle.Primary} loading={loading}>
          Send reset email
        </Button>
      </form>
      <Link
        to={RoutePath.Login}
        onClick={(): void => {
          sendMessages()
          setEmail('')
        }}
      >
        <Buttonlink>Back to login</Buttonlink>
      </Link>
    </>
  )
}

export default RequestPassword
