import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  FormattedDateTime,
  IGiftCardResponse,
  Loading,
} from '@one-tree/library'
import { toSentenceCase } from '../../helpers/DataTransformer'
import { transformDate, TransformDateFormat } from '../../helpers/DateHelper'
import {
  GiftCardResourceOptions,
  Resource,
  IGiftCardHistory,
} from '../../types/API'
import { get } from '../../helpers/APIHelper'
import TableHeader from './resultTable/TableHeader'
import { voucherWord } from '../../helpers/FormatHelper'
import { useOrganisation } from '../../context/OrganisationProvider'

const HistoryInfo = styled.div`
  display: inline-flex;
  ul {
    list-style: none;
    padding-left: 15px;
    margin: 0;
  }
`
export interface IHistoryProps {
  giftCard: IGiftCardResponse
}

function History(props: IHistoryProps): ReactElement {
  const { giftCard } = props
  const [cardHistory, setCardHistory] = useState<IGiftCardHistory[]>([])
  const [loading, setLoading] = useState(false)
  const { organisation } = useOrganisation()

  const getHistory = async (): Promise<void> => {
    setLoading(true)
    const result = await get<IGiftCardHistory[]>({
      resource: Resource.GiftCards,
      urlPath: [giftCard.id, GiftCardResourceOptions.History],
    })
    if (result) setCardHistory(result)
    setLoading(false)
  }
  useEffect(() => {
    getHistory()
  }, [giftCard])

  const history = cardHistory.map((item: IGiftCardHistory) => (
    <tr key={item.id}>
      <th aria-label="Date">
        <FormattedDateTime timestamp={item.date} />
      </th>
      <td>
        <HistoryInfo>
          {`${item.emailAddress || '-'}`}
          <ul>
            {Object.entries(item.logData).map((logItem) => {
              let value = logItem[1].newValue
              if (
                typeof logItem[1].newValue === 'number'
                && moment(logItem[1].newValue).isValid()
              ) {
                value = transformDate(
                  logItem[1].newValue,
                  TransformDateFormat.NumbersAndSlashes,
                )
              }

              if (value.toLowerCase() === 'delivered') value = 'dispatched'

              const keyWithHTML = <strong>{toSentenceCase(logItem[0])}</strong>
              const valueWithHTML = <span>{value}</span>
              return (
                <li key={item.id + logItem[0]}>
                  {keyWithHTML}
                  {': '}
                  {valueWithHTML}
                </li>
              )
            })}
          </ul>
        </HistoryInfo>
      </td>
    </tr>
  ))

  return loading ? (
    <TableHeader title={<Loading />} />
  ) : (
    <>
      {cardHistory.length ? (
        <TableHeader title={`${voucherWord(organisation)} history`} />
      ) : (
        <TableHeader title={`No ${voucherWord(organisation)} history`} />
      )}
      {history}
    </>
  )
}
export default History
