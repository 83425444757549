import {
  CurrencyCode,
  IBreakdownParams,
  IItemisedData,
  IItemisedReportResponse,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import Card, { CardSize } from '../../../components/page/Card'
import CardContent from '../../../components/page/CardContent'
import CardHeader from '../../../components/page/CardHeader'
import {
  getAllTimeSales,
  getItemisedReport,
} from '../../../helpers/APIReportHelper'
import useResponsive from '../../../helpers/isResponsive'
import { getGraphData } from './BreakdownHelper'
import Controls from './Controls'
import Graph from './Graph'
import Itemisation from './Itemisation'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
  margin-top: 30px;
  ${(props): string => props.theme.isSmallDesktop
    && 'grid-auto-flow: row; grid-template-columns: none;'}
`
interface IBreakdown {
  currency: CurrencyCode
  init: IBreakdownParams
  updateParams: (updates: IBreakdownParams) => void
}
function Breakdown({ currency, init, updateParams }: IBreakdown): ReactElement {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IItemisedReportResponse>()
  const [graphData, setGraphData] = useState<IItemisedData[]>()

  const [metric, setMetric] = useState(init.breakdownMetric)
  const [itemiseBy, setItemiseBy] = useState(init.breakdownItemiseBy)
  const [metricType, setMetricType] = useState(init.breakdownMetricType)

  const [fromDate, setFromDate] = useState(moment(init.breakdownFromDate))
  const [toDate, setToDate] = useState(moment(init.breakdownToDate))

  useEffect(() => {
    updateParams({
      breakdownMetric: metric,
      breakdownItemiseBy: itemiseBy,
      breakdownMetricType: metricType,
      breakdownFromDate: fromDate.valueOf(),
      breakdownToDate: toDate.valueOf(),
    })
  }, [metric, itemiseBy, metricType, fromDate, toDate])

  const fetchData = async (): Promise<void> => {
    setLoading(true)

    const res = await getItemisedReport({
      fromDate: fromDate.unix(),
      toDate: toDate.unix(),
      metric,
      metricType,
      itemiseBy,
    })
    if (res) setData(res)

    const graphRes = await getAllTimeSales()
    if (graphRes) setGraphData(getGraphData(graphRes, 5))

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [itemiseBy, metric, metricType, fromDate, toDate])

  return (
    <Card cardSize={CardSize.Full}>
      <CardHeader title="Sales breakdown" />
      <CardContent>
        <Controls
          itemiseBy={itemiseBy}
          setItemiseBy={setItemiseBy}
          metric={metric}
          setMetric={setMetric}
          metricType={metricType}
          setMetricType={setMetricType}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
        <Grid theme={useResponsive()}>
          <Itemisation
            currency={currency}
            loading={loading}
            data={data}
            itemiseBy={itemiseBy}
            metric={metric}
            metricType={metricType}
          />
          <Graph currency={currency} loading={loading} data={graphData} />
        </Grid>
      </CardContent>
    </Card>
  )
}
export default Breakdown
