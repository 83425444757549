import { CurrencyCode } from '@one-tree/library'
import { parseString } from './DataTransformer'

const currencyMap = new Map<CurrencyCode, string>([
  [CurrencyCode.GBP, '&#163;'],
  [CurrencyCode.EUR, '&#8364;'],
])
export const getCurrencySymbol = (code: CurrencyCode): string => {
  const symbol = currencyMap.get(code)
  if (symbol) {
    return parseString(symbol)
  }
  return ''
}

export const formatCurrency = (
  amount: number,
  currency: CurrencyCode,
  withoutDecimals?: boolean,
): string => {
  if (amount !== 0 && !amount) return ''

  const formattedAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  return `${getCurrencySymbol(currency)}${withoutDecimals ? amount.toFixed(0) : formattedAmount
  }`
}

export const roundDecimalValue = (
  input: string,
  trailingZerosOptional: boolean = true,
): string => {
  const noDecimals = input.indexOf('.') === -1

  // if number has no decimals and trailing zeros are
  // optional, then we can just return the number
  if (noDecimals && trailingZerosOptional) {
    return parseFloat(input).toString()
  }
  // else force 2 decimal places
  return parseFloat(input).toFixed(2)
}
