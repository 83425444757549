import React, { ReactElement } from 'react'
import {
  capitalize,
  Checkbox,
  colors,
  InputGroup,
  IOrganisationResponse,
  TextArea,
} from '@one-tree/library'
import { voucherWord } from '../../helpers/FormatHelper'
import { IOrganisationPatchBody } from '../../types/API'
import { useOrganisation } from '../../context/OrganisationProvider'
import { OrganisationFormat } from '../../types/Types'
import { Section } from './WebShopHelper'

interface IWebShopDetailsProps {
  values: IOrganisationResponse
  changeOrganisation: (changes: IOrganisationPatchBody) => void
  deliveryError: boolean
}

function WebShopDetails({
  values,
  changeOrganisation,
  deliveryError,
}: IWebShopDetailsProps): ReactElement {
  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: value,
    })
  }

  const { organisation } = useOrganisation()

  return (
    <>
      {organisation?.format === OrganisationFormat.GiftVouchers && (
        <Section>
          <h3>{`${capitalize(voucherWord(organisation))} delivery methods`}</h3>
          <p>
            Select which delivery options are available to the customer when
            checking out
          </p>
          <InputGroup>
            <Checkbox
              label="Email"
              onChange={(value): void => handleChange(value, 'emailDelivery')}
              value={values.emailDelivery}
              isError={deliveryError}
            />
            <Checkbox
              label="Post"
              onChange={(value): void => handleChange(value, 'postDelivery')}
              value={values.postDelivery}
              isError={deliveryError}
            />
          </InputGroup>
          {deliveryError && (
            <strong style={{ color: colors.red }}>
              You must select a delivery method
            </strong>
          )}
        </Section>
      )}
      <Section>
        <h3>Purchaser terms and conditions</h3>
        <p>
          {`These are the terms the purchaser agrees to online when buying ${voucherWord(
            values,
          )}s. Edit as required for your business. If left blank, default terms will be used.`}
        </p>
        <TextArea
          className="card-section"
          maxChars={900}
          rows={8}
          onChange={(value): void => handleChange(value, 'purchaserTerms')}
          value={values.purchaserTerms || ''}
          placeholder="Optionally add terms and conditions for the purchaser..."
        />
      </Section>
    </>
  )
}
export default WebShopDetails
