import React, { ReactElement } from 'react'
import {
  Route, Switch, Redirect, useLocation,
} from 'react-router-dom'
import Page from '../../hoc/Page'
import CardTabs from '../../components/page/CardTabs'
import CardTab from '../../components/page/CardTab'
import { RoutePath } from '../../types/Routes'
import ManageGiftItems from './ManageGiftItems'
import { useOrganisation } from '../../context/OrganisationProvider'
import ManageCategories from './ManageCategories'
import Card, { CardSize } from '../../components/page/Card'
import { capitalize } from '../../helpers/DataTransformer'
import { itemWord } from '../../helpers/FormatHelper'

// This page is a container for other pages. We are doing it this way so that the breadcrumbs and
// active link in the menu work as expected.

// By doing it this way, when you change to either tab, you remain on the same "manage" page.

function Manage(): ReactElement {
  const { organisation } = useOrganisation()

  // This sets the default page
  if (useLocation().pathname === RoutePath.Manage) {
    return <Redirect to={RoutePath.ManageItems} />
  }

  return (
    <Page>
      <Card cardSize={CardSize.Big}>
        {organisation?.categoriesEnabled ? (
          <CardTabs>
            <CardTab title={`${capitalize(itemWord(organisation))}s`} to={RoutePath.ManageItems} />
            <CardTab title="Categories" to={RoutePath.ManageCategories} />
          </CardTabs>
        ) : null}
        <Switch>
          <Route
            exact={true}
            path={RoutePath.ManageItems}
            component={ManageGiftItems}
          />
          <Route
            exact={true}
            path={RoutePath.ManageCategories}
            component={ManageCategories}
          />
        </Switch>
      </Card>
    </Page>
  )
}
export default Manage
