import React from 'react'
import { History } from 'history'
import { Moment } from 'moment'
import {
  ISelectOption,
  ITicketQuestion,
  QuestionType,
  User,
} from '@one-tree/library'

export interface IHistoryProp {
  history: History
}

export enum OrganisationFormat {
  GiftVouchers = 'voucher',
  SpecialOffers = 'special_offers',
  Tickets = 'ticket',
}

export type OrganisationUser = User | null

export type PropsWithChildren<P> = P & { children?: React.ReactNode }

export interface ILoginMessages {
  success?: string
  error?: string
  email?: string
  password?: string
  passwordRepeat?: string
}

export interface ITokenPair {
  token: string
  refreshToken?: string
}

export interface IPasswordRequest {
  message: string
  hash: string
}

export interface IIDParam {
  id?: string
}

export interface IMessagingProps<T> {
  sendMessages: (object?: T) => void
  receiveMessages: T
}

export interface ISearchParams {
  purchaserName: string
  recipientName: string
  orderId: string
}

export enum GraphDisplay {
  TwelveMonths = 'twelveMonths',
  CalendarYear = 'year',
  Quarterly = 'quarterly',
}
export interface IMomentPresets {
  startOfYear: Moment
  startOfQuarter: Moment
  startOfMonth: Moment
}

export type PortalTicketQuestion = {
  rules?: string
  options?: ISelectOption[]
} & ITicketQuestion

export type SubmitTicketQuestion = {
  title: string
  type: QuestionType
  options?: string[]
  required?: boolean
}

export type TicketPrice = {
  id: number
  name: string
  value: string
}
