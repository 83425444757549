import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  colors,
  InputField,
  InputType,
  SelectField,
  stringsToOptions,
  stringToOption,
  TextArea,
  getResizedImage,
  ORGANISATION_LOGO_DIMENSIONS,
  VOUCHER_BANNER_DIMENSIONS,
  IOrganisationResponse,
  UploadImageField,
  ImageType,
  ColorField,
  removeHash,
  InputGroup,
  capitalize,
  Font,
} from '@one-tree/library'
import { get, uploadImage } from '../../helpers/APIHelper'
import {
  itemWord,
  shortVoucherWord,
  voucherWord,
} from '../../helpers/FormatHelper'
import {
  IImageUploadResponse,
  IOrganisationPatchBody,
  Resource,
} from '../../types/API'
import { OrganisationFormat } from '../../types/Types'

const CardFieldGrid = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 27px) calc(50% - 27px);
  column-gap: 54px;
`
const GridFullWidth = styled.div`
  grid-column: 1 / 3;
  :last-child {
    margin-top: 30px;
  }
`
const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    margin: 40px 0 10px;
  }
  h4 {
    margin: 20px 0 10px;
  }
  p {
    margin: 0;
  }
`
const GridFieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

interface IVoucherTemplateDetailsProps {
  values: IOrganisationResponse
  organisation: IOrganisationResponse
  changeOrganisation: (changes: IOrganisationPatchBody) => void
}

function TemplateDetails({
  values,
  organisation,
  changeOrganisation,
}: IVoucherTemplateDetailsProps): ReactElement {
  const [counties, setCounties] = useState<string[] | null>(null)
  const [fonts, setFonts] = useState<Font[] | null>(null)

  useEffect(() => {
    (async (): Promise<void> => {
      setCounties(
        (await get<string[]>({ resource: Resource.Counties })) || null,
      )
    })()
  }, [])

  useEffect(() => {
    (async (): Promise<void> => {
      setFonts((await get<Font[]>({ resource: Resource.Fonts })) || null)
    })()
  }, [])

  const fontOptions = fonts
    ? fonts.map((font) => ({
      label: capitalize(font),
      value: font,
    }))
    : []

  const selectedFont = {
    label: capitalize(values.voucherFont),
    value: values.voucherFont,
  }

  const [logoUrl, setLogoUrl] = useState(values.imageUrl)
  const handleLogoChange = (response: IImageUploadResponse): void => {
    changeOrganisation({
      imageFileId: response.id,
    })
    setLogoUrl(response.url)
  }

  const [headerUrl, setHeaderUrl] = useState(values.headerImageUrl)
  const handleHeaderChange = (response: IImageUploadResponse): void => {
    changeOrganisation({
      headerImageFileId: response.id,
    })
    setHeaderUrl(response.url)
  }

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: value,
    })
  }

  const handleColorChange = (
    value: string,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: removeHash(value),
    })
  }

  const voucher = voucherWord(values)
  const shortVoucher = shortVoucherWord(values)
  const item = itemWord(values)

  const isGiftVoucher = values.format === OrganisationFormat.GiftVouchers

  const renderGiftVoucherStyling = (
    <>
      <h3 style={{ marginBottom: '0' }}>Voucher design</h3>
      <h4>Body copy</h4>
      <GridFieldGroup>
        <InputGroup>
          <ColorField
            label="Text"
            onChange={(value): void => handleColorChange(value, 'voucherBodyText')}
            value={values.voucherBodyText}
            isError={!values.voucherBodyText}
          />
          <SelectField
            options={fontOptions}
            label="Font"
            onChange={(option): void => {
              if (option) handleChange(option.value, 'voucherFont')
            }}
            value={selectedFont}
          />
        </InputGroup>
      </GridFieldGroup>
      <h3>Colour blocks</h3>
      <p>{`Optionally add blocks of colour to the top and/or bottom sections of your ${shortVoucher}s. Choose a contrasting text colour.`}</p>
      <h4>Top block</h4>
      <GridFieldGroup>
        <InputGroup style={{ alignItems: 'flex-end' }}>
          <ColorField
            label="Background"
            onChange={(value): void => handleColorChange(value, 'voucherTopBackground')}
            value={values.voucherTopBackground}
            isError={!values.voucherTopBackground}
          />
          <ColorField
            label="Text"
            onChange={(value): void => handleColorChange(value, 'voucherTopText')}
            value={values.voucherTopText}
            isError={!values.voucherTopText}
          />
        </InputGroup>
      </GridFieldGroup>
      <h4>Bottom block</h4>
      <GridFieldGroup>
        <InputGroup style={{ alignItems: 'flex-end' }}>
          <ColorField
            label="Background"
            onChange={(value): void => handleColorChange(value, 'voucherBottomBackground')}
            value={values.voucherBottomBackground}
            isError={!values.voucherBottomBackground}
          />
          <ColorField
            label="Text"
            onChange={(value): void => handleColorChange(value, 'voucherBottomText')}
            value={values.voucherBottomText}
            isError={!values.voucherBottomText}
          />
        </InputGroup>
      </GridFieldGroup>
    </>
  )

  return (
    <CardFieldGrid>
      <GridColumn>
        <h3 style={{ marginTop: '20px' }}>Details</h3>
        <GridFieldGroup>
          <InputField
            type={InputType.Text}
            label="Display name"
            maxChars={40}
            onChange={(value): void => handleChange(value, 'displayName')}
            value={values.displayName}
          />
        </GridFieldGroup>
        <h3>Address</h3>
        <GridFieldGroup style={{ gap: '15px' }}>
          <InputField
            type={InputType.Text}
            label="Line 1"
            onChange={(value): void => handleChange(value, 'addressLineOne')}
            value={values.addressLineOne}
          />
          <InputField
            type={InputType.Text}
            label="Line 2"
            onChange={(value): void => handleChange(value, 'addressLineTwo')}
            value={values.addressLineTwo}
          />
          <InputField
            type={InputType.Text}
            label="Town"
            onChange={(value): void => handleChange(value, 'addressTown')}
            value={values.addressTown}
          />
          <SelectField
            label="County"
            options={stringsToOptions(counties || [])}
            onChange={(option): void => {
              if (option) {
                handleChange(option.value, 'addressCounty')
              }
            }}
            value={stringToOption(values.addressCounty)}
          />
          <InputField
            type={InputType.Text}
            label="Postcode"
            onChange={(value): void => handleChange(value, 'addressPostcode')}
            value={values.addressPostcode}
          />
          <InputField
            type={InputType.Phone}
            label="Telephone"
            onChange={(value): void => handleChange(value, 'telephone')}
            value={values.telephone}
          />
          <InputField
            type={InputType.Email}
            label="Email address"
            onChange={(value): void => handleChange(value, 'emailAddress')}
            value={values.emailAddress}
          />
          <InputField
            type={InputType.Text}
            label="URL"
            onChange={(value): void => handleChange(value, 'url')}
            value={values.url}
          />
        </GridFieldGroup>
        <h3 style={{ marginBottom: '0' }}>Redemption details</h3>
        <GridFieldGroup>
          {organisation.format === OrganisationFormat.GiftVouchers && (
            <TextArea
              className="card-section"
              placeholder={`Add/edit copy to describe how gift recipients can redeem their ${voucher}.`}
              maxChars={100}
              rows={3}
              onChange={(value): void => handleChange(value, 'redemptionDetails')}
              value={values.redemptionDetails}
            />
          )}
        </GridFieldGroup>
      </GridColumn>
      <GridColumn>
        <h3 style={{ marginTop: '20px' }}>Images</h3>
        <GridFieldGroup>
          <UploadImageField
            label="Header"
            imageType={ImageType.VoucherBanner}
            onSuccess={handleHeaderChange}
            previewUrl={getResizedImage(headerUrl, VOUCHER_BANNER_DIMENSIONS)}
            cropPixelAspect={VOUCHER_BANNER_DIMENSIONS}
            subtitle={`Add a header to appear at the top of all your ${voucher}s. This can be customised for individual ${item}s in Manage ${item}s. For best results upload as a jpg measuring 1300 px by 326 px.`}
            isError={!values.headerImageUrl}
            upload={uploadImage}
          />
          {!values.headerImageUrl && (
            <strong style={{ color: colors.red }}>
              You must upload a header image
            </strong>
          )}
          <UploadImageField
            label="Logo"
            imageType={ImageType.OrganisationLogo}
            onSuccess={handleLogoChange}
            previewUrl={getResizedImage(logoUrl, ORGANISATION_LOGO_DIMENSIONS)}
            minimumAspect={{ x: 200, y: 200 }}
            canCrop={true}
            subtitle={`Please upload a jpg of your logo for display on the ${voucher}s, with a minimum dimension of 200 px on the smallest side.`}
            isError={!values.imageUrl}
            upload={uploadImage}
          />
          {!values.imageUrl && (
            <strong style={{ color: colors.red }}>
              You must upload a logo
            </strong>
          )}
        </GridFieldGroup>
        {isGiftVoucher && renderGiftVoucherStyling}
      </GridColumn>
      <GridFullWidth>
        <h3>Terms and conditions</h3>
        <p style={{ marginBottom: '0' }}>
          {`These terms and conditions are printed at the bottom of every ${shortVoucher} and refer to the use of the ${shortVoucher}. The buyer also agrees to them before checking out. Edit as required for your business. If left blank, default terms will be used.`}
        </p>
        <GridFieldGroup>
          <TextArea
            className="card-section"
            maxChars={900}
            rows={6}
            onChange={(value): void => handleChange(value, 'termsAndConditions')}
            value={values.termsAndConditions || ''}
            placeholder={`Optionally add ${shortVoucher} terms and conditions...`}
          />
        </GridFieldGroup>
      </GridFullWidth>
    </CardFieldGrid>
  )
}
export default TemplateDetails
