import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { GridContextProvider, GridDropZone, swap } from 'react-grid-dnd'
import {
  Modal, AsyncButton, Button, ButtonStyle,
  alertError, alertSuccess, colors,
} from '@one-tree/library'
import { ICategoryResponse } from '../../../types/API'
import CategoryGridItem from './CategoryGridItem'
import { patchCategory } from '../../../helpers/APIHelper'
import { getOrder, getParent } from '../../../helpers/CategoryHelper'
import { StateAction } from '../../../types/Aliases'
import { bulkRedeemTickets, getGiftItems } from '../../../helpers/APIItemHelper'
import { filterGiftItems } from '../../../helpers/GiftItemHelper'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { stripEmptyFromArray } from '../../../helpers/DataTransformer'

const StyledGridDropZone = styled(GridDropZone)`
  border: 1px solid ${colors.lightGray};
  background-color: ${colors.lightestGray};
  border-radius: 4px;
  margin-top: 15px;
  padding: 10px;
  min-height: 100px;
`
const ModalContents = styled.div`
  p {
    margin: auto;
    padding: 10px 6px 0;
  }
  min-width: 240px;
  max-width: 560px;
  text-align: center;
  font-weight: bold;
`
const ButtonWrapper = styled.div`
  padding-top: 10px;
  button {
    margin: 10px;
    padding: 5px;
  }
`

interface ICategoryGridProps {
  categories: ICategoryResponse[]
  setCategories: StateAction<ICategoryResponse[]>
  fetchCategories: () => void
  loading: boolean
  setLoading: StateAction<boolean>
}

function CategoryGrid({
  categories,
  setCategories,
  fetchCategories,
  loading,
  setLoading,
}: ICategoryGridProps): ReactElement {
  const rowHeight = 50
  const totalHeight = Math.ceil(categories.length) * rowHeight
  const [isHovering, setIsHovering] = useState(false)
  const [isCurrentIndented, setIsCurrentIndented] = useState<boolean>(false)
  const { organisation } = useOrganisation()

  const saveOrder = async (
    categoryId: number,
    order: number,
    parent: number,
  ): Promise<void> => {
    const res: false | ICategoryResponse = await patchCategory({
      categoryId,
      patchOptions: {
        order,
        parent,
      },
    })
    if (!res) alertError('Error updating category')
    fetchCategories()
    setIsCurrentIndented(false)
  }

  const onChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number,
  ): void => {
    setLoading(true)

    const newOrder = swap(categories, sourceIndex, targetIndex)
    const allCategoriesAbove = newOrder.slice(0, targetIndex).reverse()

    const order = getOrder(
      sourceIndex,
      targetIndex,
      isCurrentIndented,
      allCategoriesAbove,
      categories,
    )
    const parent = getParent(
      targetIndex,
      isCurrentIndented,
      allCategoriesAbove,
      categories,
    )

    // set our changes locally so the UI updates
    setCategories(newOrder)

    // send our changes to API
    if (categories[sourceIndex]) {
      saveOrder(categories[sourceIndex].id, order, parent)
    } else {
      alertError('Error saving new order')
      fetchCategories()
    }
  }

  const hideChildrenWhenDragging = (categoryId: number): void => {
    if (categoryId) {
      setCategories(
        categories.filter((category) => category.parent !== categoryId),
      )
    }
  }

  const [categoryToRedeem, setCategoryToRedeem] = useState<number | false>(
    false,
  )
  const handleRedeemAllTickets = async (categoryId: number): Promise<void> => {
    const items = organisation && (await getGiftItems({ format: organisation.format }))
    if (items) {
      const filteredItems = filterGiftItems(
        items,
        {
          category: categoryId,
          status: false,
          type: false,
          unfinished: false,
        },
        organisation,
      )

      if (filteredItems.length) {
        const itemsToRedeem = filteredItems.map((item) => item.id)
        const redeemed = await bulkRedeemTickets(
          { giftItemIds: stripEmptyFromArray(itemsToRedeem) },
        )
        if (redeemed) {
          alertSuccess(
            `${redeemed.length} ticket${redeemed.length > 1 ? 's' : ''
            } redeemed`,
          )
        }
      } else {
        alertError('No tickets to redeem')
      }
    }
    setCategoryToRedeem(false)
  }
  // Making a new modal confirmation rather than using the `Button` one, as the button is within a
  // Grid Item - so the modal was rendered within that Item rather than covering the whole page.
  const renderModal = categoryToRedeem && (
    <Modal
      modalOpen={Boolean(categoryToRedeem)}
      onModalClose={(): void => setCategoryToRedeem(false)}
      modalAction={(): Promise<void> => handleRedeemAllTickets(categoryToRedeem)}
    >
      <ModalContents>
        <p>
          Are you sure you want to redeem all tickets for the events in this
          category? This cannot be undone.
        </p>
        <ButtonWrapper>
          <AsyncButton
            buttonStyle={ButtonStyle.Danger}
            onClick={(): Promise<void> => handleRedeemAllTickets(categoryToRedeem)}
          >
            Yes
          </AsyncButton>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => setCategoryToRedeem(false)}
          >
            No
          </Button>
        </ButtonWrapper>
      </ModalContents>
    </Modal>
  )

  return (
    <GridContextProvider onChange={onChange}>
      <StyledGridDropZone
        id="items"
        boxesPerRow={1}
        rowHeight={rowHeight}
        style={{ height: `${totalHeight}px` }}
        disableDrag={loading || !isHovering}
      >
        {categories
          && categories.map((category) => (
            <CategoryGridItem
              key={category.id}
              category={category}
              setIsHovering={setIsHovering}
              disabled={loading}
              getIsIndented={setIsCurrentIndented}
              getCurrentDrag={hideChildrenWhenDragging}
              fetchCategories={fetchCategories}
              setCategoryToRedeem={(id): void => setCategoryToRedeem(id)}
            />
          ))}
      </StyledGridDropZone>
      {renderModal}
    </GridContextProvider>
  )
}
export default CategoryGrid
