import React, { ReactElement } from 'react'
import { colors, IGiftCardResponse } from '@one-tree/library'
import { stripHTML } from '../../helpers/DataTransformer'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  isDateBeforeToday,
  transformDate,
  TransformDateFormat,
} from '../../helpers/DateHelper'
import History from './History'
import { formatCurrency } from '../../helpers/CurrencyHelper'
import ValidityRow from './resultTable/regularRows/ValidityRow'
import ResultTableRow from './resultTable/regularRows/ResultTableRow'
import RedeemedByRow from './resultTable/regularRows/RedeemedByRow'
import StatusRow from './resultTable/regularRows/StatusRow'
import { OrganisationFormat } from '../../types/Types'
import { voucherWord, itemWord } from '../../helpers/FormatHelper'

interface IResultTableProps {
  giftCard: IGiftCardResponse
  tableExpanded: boolean
  justRedeemed: boolean
}
function RedeemerResultTable(props: IResultTableProps): ReactElement {
  const { giftCard, tableExpanded, justRedeemed } = props
  const { organisation } = useOrganisation()

  return (
    <>
      <tbody>
        <ValidityRow
          status={giftCard.status}
          organisationFormat={organisation?.format}
          justRedeemed={justRedeemed}
        />
        <RedeemedByRow
          status={giftCard.status}
          redeemUser={giftCard.redeemUser}
          redeemDate={giftCard.redeemDate}
        />
        <StatusRow status={giftCard.status} />
        <ResultTableRow
          title={`${itemWord(organisation)} name`}
          value={giftCard.giftItemName}
        />
        {organisation?.format === OrganisationFormat.SpecialOffers && (
          <ResultTableRow
            title="Original price"
            value={
              organisation
              && giftCard.originalPrice
              && formatCurrency(giftCard.originalPrice, organisation.currencyCode)
            }
            style={{
              textDecoration: 'line-through',
              color: colors.darkerGray,
            }}
          />
        )}
        <ResultTableRow
          title="Value"
          value={
            organisation
            && formatCurrency(giftCard.value, organisation.currencyCode)
          }
        />
        <ResultTableRow
          title={`${voucherWord(organisation)} id`}
          value={giftCard.id}
        />
        <ResultTableRow title="16 character code" value={giftCard.code} />
        {organisation?.format === OrganisationFormat.Tickets ? (
          <ResultTableRow title="Event date" value={giftCard.giftItemStart} />
        ) : (
          <tr>
            <th>Expiry date</th>
            <td
              className={
                isDateBeforeToday(giftCard.expiryDate)
                  ? 'card-status-date-expired'
                  : ''
              }
            >
              {transformDate(
                giftCard.expiryDate,
                TransformDateFormat.LongWithoutTime,
              )}
            </td>
          </tr>
        )}
      </tbody>
      {tableExpanded && (
        <tbody className="table-hidden">
          <ResultTableRow
            title="Valid from"
            value={transformDate(
              giftCard.startDate,
              TransformDateFormat.LongWithoutTime,
            )}
          />
          <ResultTableRow
            title="Purchaser"
            value={`${giftCard.purchaserFirstName} ${giftCard.purchaserLastName}`}
          />
          {organisation?.format === OrganisationFormat.GiftVouchers && (
            <>
              <ResultTableRow
                title="Recipient"
                value={`${giftCard.deliveryFirstName} ${giftCard.deliveryLastName}`}
              />
              <ResultTableRow
                title="Description"
                value={stripHTML(giftCard.giftItemDetails)}
              />
            </>
          )}
          <ResultTableRow
            title="Extended description"
            value={stripHTML(giftCard.giftItemTerms)}
          />
          {organisation?.format === OrganisationFormat.SpecialOffers && (
            <ResultTableRow
              title="Important information"
              value={stripHTML(giftCard.giftItemRestrictions)}
            />
          )}
          <History giftCard={giftCard} />
        </tbody>
      )}
    </>
  )
}
export default RedeemerResultTable
