import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'
import moment from 'moment'
import {
  CurrencyCode,
  IBreakdownParams,
  IOverviewParams,
  ReportItemisation,
  ReportMetric,
  ReportMetricType,
} from '@one-tree/library'
import { getCurrencySymbol } from '../../helpers/CurrencyHelper'
import { stripEmptyFromObject } from '../../helpers/DataTransformer'

export const downloadAsPng = (selector: string): void => {
  const container = document.getElementById(selector)
  if (container) {
    htmlToImage.toPng(container, { pixelRatio: 2 }).then((dataUrl) => {
      download(dataUrl, `${selector}.png`)
    })
  }
}

// TODO move this to currency helper and replace the existing formatCurrency
export const formatCurrency = (
  amount: number | undefined,
  currency: CurrencyCode,
  decimalPlaces: number = 2,
): string => {
  if (amount !== 0 && !amount) return ''

  const formattedAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  })

  return `${getCurrencySymbol(currency)}${formattedAmount}`
}

export const renderTotal = (
  value: number | undefined,
  metricType: ReportMetricType,
  currency: CurrencyCode,
  decimalPlaces: number = 2,
): string => {
  switch (metricType) {
  case ReportMetricType.Value:
    return formatCurrency(value, currency, decimalPlaces)
  case ReportMetricType.Volume:
  default:
    return value ? value.toLocaleString() : '0'
  }
}

export const getCSVExportTitle = (
  fromDate?: number,
  toDate?: number,
  title?: string,
): string => {
  const from = fromDate
    ? ` from ${moment.unix(fromDate).format('YYYYMMDD')}`
    : ''
  const to = toDate ? ` to ${moment.unix(toDate).format('YYYYMMDD')}` : ''

  return `${title || 'Data'}${from}${to}.csv`
}

export const setQuery = (
  currentSearch: string,
  newParams: Partial<IOverviewParams & IBreakdownParams>,
): string => {
  const existingQueryParams = Object.fromEntries(
    new URLSearchParams(currentSearch),
  )
  const cleanedExisting = stripEmptyFromObject(existingQueryParams)
  const cleanedNew = stripEmptyFromObject(newParams)

  // stripEmptyFromObject returns same object without empty, safe to recast
  // eslint-disable-next-line no-type-assertion/no-type-assertion
  const merged = { ...cleanedExisting, ...cleanedNew } as {
    [key: string]: string
  }

  const paramArray: string[] = []
  Object.keys(merged).forEach((key) => {
    paramArray.push(`${key}=${merged[key]}`)
  })

  return `?${paramArray.join('&')}`
}

export const isQueryValid = (
  queryParams: Partial<IOverviewParams & IBreakdownParams>,
): boolean => {
  const {
    overviewMetric,
    overviewMetricType,
    overviewFromMonth,
    overviewFromYear,
    breakdownMetric,
    breakdownItemiseBy,
    breakdownMetricType,
    breakdownFromDate,
    breakdownToDate,
  } = queryParams

  if (!Number(overviewFromMonth) || !Number(overviewFromYear)) return false

  if (
    overviewMetric !== ReportMetric.Sales
    && overviewMetric !== ReportMetric.Redemptions
    && overviewMetric !== ReportMetric.Expired
    && overviewMetric !== ReportMetric.Cancellations
  ) {
    return false
  }

  if (
    overviewMetricType !== ReportMetricType.Value
    && overviewMetricType !== ReportMetricType.Volume
  ) {
    return false
  }

  if (!Number(breakdownFromDate) || !Number(breakdownToDate)) return false

  if (
    breakdownMetric !== ReportMetric.Sales
    && breakdownMetric !== ReportMetric.Redemptions
    && breakdownMetric !== ReportMetric.Expired
    && breakdownMetric !== ReportMetric.Cancellations
  ) {
    return false
  }

  if (
    breakdownItemiseBy !== ReportItemisation.DispatchMethod
    && breakdownItemiseBy !== ReportItemisation.Name
    && breakdownItemiseBy !== ReportItemisation.Type
  ) {
    return false
  }

  if (
    breakdownMetricType !== ReportMetricType.Value
    && breakdownMetricType !== ReportMetricType.Volume
  ) {
    return false
  }

  return true
}
