import { theme, ZIndex } from '@one-tree/library'
import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  padding: 25px 25px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1.3rem;
    margin: 0;
    height: ${theme.fieldHeight};
    display: flex;
    align-items: center;
  }
  /* so the controls are above the ScrollZone */
  z-index: ${ZIndex.AboveContent};
`
const Buttons = styled.div`
  button {
    margin-left: 10px;
  }
  z-index: inherit;
`
export interface ICardHeaderProps {
  children?: ReactNode
  title?: string | ReactElement
}
function CardHeader(props: ICardHeaderProps): ReactElement {
  const { title, children } = props
  return (
    <Styles>
      <h2>{title}</h2>
      <Buttons>{children}</Buttons>
    </Styles>
  )
}
export default CardHeader
