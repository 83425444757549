import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  alertError,
  InputType,
  InputField,
  InputGroup,
} from '@one-tree/library'
import { StateAction } from '../../../../types/Aliases'
import EditIcon from './EditIcon'

const StyledCell = styled.td`
  padding-left: 3px !important;
`

interface EditableTableRowProps {
  title: string
  children: ReactElement
  isEditing: boolean
  setIsEditing: StateAction<boolean>
  editReason: string
  setEditReason: StateAction<string>
  saveAction: (reason: string) => void
  cancelAction: () => void
  showConfirmation?: boolean
  confirmationMessage?: string
}

function EditableTableRow(props: EditableTableRowProps): ReactElement {
  const {
    title,
    children,
    isEditing,
    setIsEditing,
    editReason,
    setEditReason,
    saveAction,
    cancelAction,
    showConfirmation,
    confirmationMessage,
  } = props
  const [error, setError] = useState(false)

  return (
    <>
      <tr>
        <th style={{ verticalAlign: 'middle' }}>{title}</th>
        <StyledCell>
          <InputGroup>
            {React.cloneElement(children, { disabled: !isEditing })}
            {isEditing ? (
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => {
                  cancelAction()
                  setError(false)
                  setIsEditing(false)
                }}
              >
                Cancel
              </Button>
            ) : (
              <EditIcon onClick={(): void => setIsEditing(true)} />
            )}
          </InputGroup>
        </StyledCell>
      </tr>
      {isEditing && (
        <tr>
          <th aria-label="Reason">&nbsp;</th>
          <StyledCell>
            <InputGroup>
              <InputField
                placeholder="Reason for change"
                type={InputType.Text}
                value={editReason}
                onChange={setEditReason}
                isLong={true}
                isError={error}
              />
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                onClick={(): void | null => {
                  if (!editReason.length) {
                    alertError('You must include a reason')
                    setError(true)
                    return null
                  }
                  setError(false)
                  return saveAction(editReason)
                }}
                showConfirmation={showConfirmation}
                confirmationMessage={confirmationMessage}
              >
                Save
              </AsyncButton>
            </InputGroup>
          </StyledCell>
        </tr>
      )}
    </>
  )
}
export default EditableTableRow
