import { IGiftCardResponse } from '@one-tree/library'
import { useState, useEffect } from 'react'

interface VoucherPagination {
  show: boolean
  index: number | null
  next: string | null
  prev: string | null
}
export const useVoucherPagination = (
  giftCard: IGiftCardResponse | false,
): VoucherPagination => {
  const init = {
    show: false,
    index: null,
    next: null,
    prev: null,
  }
  const [pagination, setPagination] = useState<VoucherPagination>(init)

  useEffect(() => {
    const { id } = giftCard || {}
    const stringList = sessionStorage.getItem('voucherIdList')
    const voucherIdList = stringList ? JSON.parse(stringList) : null

    if (id && voucherIdList && voucherIdList.length > 0) {
      const currentIndex = voucherIdList.indexOf(id)

      // Only show pagination if the current voucher is in the stored list
      if (currentIndex > -1) {
        setPagination({
          show: true,
          index: currentIndex,
          next: voucherIdList[currentIndex + 1] || false,
          prev: voucherIdList[currentIndex - 1] || false,
        })
        return
      }
    }

    setPagination(init)
  }, [giftCard])

  return pagination
}
