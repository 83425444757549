import {
  capitalize,
  ColorField,
  enumToOptions,
  FontSize,
  InputGroup,
  IOrganisationResponse,
  removeHash,
  SelectField,
  TextArea,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { IOrganisationPatchBody } from '../../types/API'
import { Header, Section } from './WebShopHelper'

interface IProps {
  values: IOrganisationResponse
  changeOrganisation: (changes: IOrganisationPatchBody) => void
}
export default function WebShopBar(props: IProps): ReactElement {
  const { values, changeOrganisation } = props

  const selectedFontSize = {
    label: capitalize(values.textOnBackgroundSize),
    value: values.textOnBackgroundSize,
  }

  const handleColorChange = (
    value: string,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: removeHash(value),
    })
  }

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: value,
    })
  }

  return (
    <Section>
      <Header>
        <h3>Announcement bar (optional)</h3>
        <p>Add a banner above your webshop for seasonal promotions.</p>
      </Header>
      <TextArea
        className="card-section"
        label="Text"
        placeholder="Optionally add a small amount of text above your web shop..."
        maxChars={300}
        rows={4}
        onChange={(value): void => handleChange(value, 'introductoryText')}
        value={values.introductoryText}
      />
      <InputGroup style={{ marginTop: '7px' }}>
        <SelectField
          placeholder="Select a size..."
          options={enumToOptions(FontSize)}
          label="Text size"
          onChange={(option): void => {
            if (option) handleChange(option.value, 'textOnBackgroundSize')
          }}
          value={values.textOnBackgroundSize ? selectedFontSize : undefined}
        />
        <ColorField
          label="Text colour"
          onChange={(value): void => handleColorChange(value, 'colourTextOnBackground')}
          value={values.colourTextOnBackground}
        />
        <ColorField
          label="Background colour"
          onChange={(value): void => handleColorChange(value, 'colourHeaderBackground')}
          value={values.colourHeaderBackground}
        />
      </InputGroup>
    </Section>
  )
}
