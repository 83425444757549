import { InputField, IOrganisationResponse } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IOrganisationPatchBody } from '../../types/API'

const Styles = styled.div`
  margin-top: 40px;
  width: 600px;
`
const Fields = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-auto-flow: column;
`
interface IProps {
  values: IOrganisationResponse
  changeOrganisation: (changes: IOrganisationPatchBody) => void
}
export default function WebShopAnalytics(props: IProps): ReactElement {
  const { values, changeOrganisation } = props

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IOrganisationPatchBody,
  ): void => {
    changeOrganisation({
      [key]: value,
    })
  }

  return (
    <Styles>
      <h3>Analytics</h3>
      <p>
        Add your Google Analytics ID and/or Meta Pixel ID to track interactions
        with your web shop and monitor advertising performance.
      </p>
      <Fields>
        <InputField
          label="Google Analytics ID"
          onChange={(value): void => handleChange(value, 'gaTrackingId')}
          value={values.gaTrackingId || ''}
          subtitle="This is the ID that starts 'G-' found in the Admin section of Google Analytics"
        />
        <InputField
          label="Meta Pixel ID"
          onChange={(value): void => handleChange(value, 'fbPixelId')}
          value={values.fbPixelId || ''}
          subtitle="This is the 15-digit number found in the Meta Events Manager"
        />
      </Fields>
    </Styles>
  )
}
