import { colors, ZIndex } from '@one-tree/library'
import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  background: ${colors.lightGray};
  box-sizing: border-box;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: inline-flex;
  a {
    /* so they are above the ScrollZone */
    z-index: ${ZIndex.AboveContent};
  }
`

interface ICardTabsProps {
  children: ReactNode
}
function CardTabs(props: ICardTabsProps): ReactElement {
  const { children } = props
  return <Styles>{children}</Styles>
}
export default CardTabs
