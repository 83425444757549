import {
  ErrorCode,
  Font,
  FontSize,
  IGiftCardResponse,
  Role,
} from '@one-tree/library'

export enum Resource {
  AllTimeSales = '/reports/all-time-sales',
  Authenticate = '/authenticate',
  Categories = '/categories',
  ChangePassword = '/authenticate/change-password',
  Contacts = '/contacts',
  Counties = '/metadata/counties',
  Fonts = '/metadata/fonts',
  GiftCardCode = '/gift-cards/code',
  GiftCards = '/gift-cards',
  GiftCardsExport = '/gift-cards/export',
  GiftVoucherItems = '/voucher-items',
  GiftVoucherTypes = '/metadata/voucher-types',
  ItemisedReports = '/reports/itemised',
  LicenseInvoices = '/invoices/license',
  MailingList = '/reports/marketing',
  OrderInvoices = '/invoices/order',
  OrderInvoicesExport = '/invoices/order/export',
  Organisations = '/organisations',
  Questions = '/questions',
  RefreshToken = '/authenticate/refresh-token',
  Reports = '/reports',
  RequestPassword = '/authenticate/request-password',
  SpecialOfferItems = '/offer-items',
  Splash = '/metadata/splash',
  TicketItems = '/ticket-items',
  TokenLogin = '/authenticate/token-login',
  Upload = '/upload',
  Users = '/users',
  VoucherTemplate = '/organisations/voucher-components',
  WebShop = '/organisations/web-shop',
}

export interface IPasswordRequestResponse {
  message: string
  hash: string
}

export enum GiftCardResourceOptions {
  History = 'history',
  Book = 'book',
  Redeem = 'redeem',
  Resend = 'resend',
  ResendOrderConfirmation = 'resend-confirmation',
}

export interface IGiftCardHistory {
  date: number
  emailAddress: string
  id: number
  logData: {
    [key: string]: {
      oldValue: string
      newValue: string
    }
  }
  logType: string
}

export interface IGiftCardPatchBody {
  reason?: string
  status?: string
  expiryDate?: string
  deliveryEmail?: string
  purchaserEmail?: string
  message?: string
  firstName?: string
  lastName?: string
  deliveryTitle?: string
  deliveryFirstName?: string
  deliveryLastName?: string
  deliveryAddressLineOne?: string
  deliveryAddressLineTwo?: string
  deliveryAddressTown?: string
  deliveryAddressCounty?: string
  deliveryAddressPostcode?: string
  purchaserTitle?: string
  purchaserFirstName?: string
  purchaserLastName?: string
  deliveryDate?: number
  notes?: string
}

export interface IGiftCardCodeProps {
  code: string
}

export interface ISalesTableProps {
  search?: string
  giftCardId?: string
  orderId?: string
  purchaserName?: string
  recipientName?: string
  status?: string
  fromDate?: string
  toDate?: string
}

export interface IInvoicesTableProps {
  fromDate?: string
  toDate?: string
  search?: string
}

export enum OrderDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface IPaginatedListProps {
  limit?: number
  skip?: number
  orderBy?: string
  orderDirection?: OrderDirection
}

export interface IPaginatedList<T> {
  recordsTotal: number
  recordsFiltered: number
  limit: number
  skip: number
  startDate?: number
  endDate?: number
  data: T[]
}

export interface IUserResponse {
  userId: number
  role: Role
  emailAddress: string
  title: string
  firstName: string
  lastName: string
  status: string
  lastLoginTimestamp: number
  inOrganisation: boolean
  organisations?: []
}

export interface IUserPostBody {
  userId?: number
  firstName?: string
  lastName?: string
  emailAddress?: string
  role?: Role
}

export interface IInvoiceResponse {
  id: number
  date: number
  dueDate?: number
  paidDate?: number
  type?: string
  paymentReference?: string
  paymentMethod?: string
  status: string
  pdfUrl?: string
  purchaserFirstName?: string
  purchaserLastName?: string
  purchaserAddressLineOne?: string
  purchaserAddressLineTwo?: string
  purchaserAddressTown?: string
  purchaserAddressCounty?: string
  purchaserAddressPostcode?: string
  items?: IInvoiceItem[]
  subtotal: number
  delivery?: number
  tax?: number
  total: number
  manualUser?: string
}

export interface IInvoiceItem {
  id?: number
  description: string
  value: number
  type?: string
  date?: number
  url?: string
}

export interface IDecodedToken {
  aud: string
  email: string
  exp: number
  firstName: string
  iat: number
  id: string
  iss: string
  lastName: string
  nbf: number
  role: Role
  sub: string
}

export interface IImageUploadResponse {
  id: number
  url: string
}

export interface IEditGiftCardProps {
  giftCard: IGiftCardResponse
  setGiftCard(card: IGiftCardResponse): void
}
export enum CategoryStatus {
  Unpublished = 'unpublished',
  Published = 'published',
}
export interface ICategoryResponse {
  id: number
  parent: number
  title: string
  order: number
  imageUrl: string
  imageFileId: number
  embedUrl: string
  isParent: boolean
  status: CategoryStatus
}

export interface ICategoryPostBody {
  title?: string
  parent?: number
  imageFileId?: number
  status?: CategoryStatus
  order?: number
}

export enum CategoryIndentation {
  Indented = 'indented',
  Outdented = 'outdented',
  Level = 'level',
}

export interface IError {
  response: IErrorResponse
}
export interface IErrorResponse {
  data: string
  status: ErrorCode
}

export interface IOrganisationPatchBody {
  colourPageBackground?: string
  colourTextOnBackground?: string
  colourHeaderText?: string
  colourPriceText?: string
  colourBodyText?: string
  colourHighlight?: string
  colourTextOnHighlight?: string
  colourHeaderBackground?: string
  textOnBackgroundSize?: FontSize
  transparentIframe?: boolean
  emailDelivery?: boolean
  postDelivery?: boolean
  font?: Font
  introductoryText?: string
  termsAndConditions?: string
  embedUrl?: string
  displayName?: string
  addressLineOne?: string
  addressLineTwo?: string
  addressTown?: string
  addressCounty?: string
  addressPostcode?: string
  emailAddress?: string
  redemptionDetails?: string
  telephone?: string
  url?: string
  imageFileId?: number
  headerImageFileId?: number
  purchaserTerms?: string
  gaTrackingId?: string
  fbPixelId?: string
  voucherBodyText?: string
  voucherBottomBackground?: string
  voucherBottomText?: string
  voucherTopBackground?: string
  voucherTopText?: string
  voucherFont?: Font
}

export enum Title {
  Dr = 'Dr',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Other = 'Other',
}
export interface IContact {
  contactId: number
  type: 'billing' | 'sales'
  title: Title
  firstName: string
  lastName: string
  jobTitle: string
  phone: string
  email: string
  notifications: boolean
}
export interface IContactResponse {
  billing: IContact
  sales: IContact
}
export interface IContactPatchBody {
  title?: Title
  firstName?: string
  lastName?: string
  jobTitle?: string
  phone?: string
  email?: string
  notifications?: boolean
}

export interface ISplash {
  splashLink: string
  splashBackgroundUrl: string
  splashForegroundUrl: string
}
