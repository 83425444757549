import { colors } from '@one-tree/library'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

const StyledRow = styled.tr`
  text-transform: uppercase;
  font-weight: bold;
`
const LeftCell = styled.td`
  border-right: 2px solid ${colors.gray};
`

interface TableHeaderProps {
  title: string | ReactElement
}

function TableHeader(props: TableHeaderProps): ReactElement {
  const { title } = props

  return (
    <StyledRow>
      <LeftCell />
      <td style={{ paddingTop: '40px' }}>{title}</td>
    </StyledRow>
  )
}
export default TableHeader
