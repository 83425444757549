import {
  AsyncButton,
  ButtonSize,
  ButtonStyle,
  capitalize,
  colors,
  IGiftItemResponse,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useOrganisation } from '../../../../../context/OrganisationProvider'
import { get, deleteQuestion } from '../../../../../helpers/APIHelper'
import { Resource } from '../../../../../types/API'
import { IGiftItemPostBody } from '../../../../../types/GiftItemTypes'
import { PortalTicketQuestion } from '../../../../../types/Types'
import { SimpleTable } from '../SimpleTable'
import { priceUsingQuestion } from '../TicketPrices/pricesHelper'
import EnableQuestion from './EnableQuestion'
import TicketAddQuestions from './TicketAddQuestions'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
const Option = styled.div`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px;
  }
`
const Number = styled.span`
  color: ${colors.darkerGray};
`
interface ITicketQuestionsProps {
  ticket: IGiftItemResponse
  changeTicket: (changes: IGiftItemPostBody) => void
}
export default function TicketQuestions(
  props: ITicketQuestionsProps,
): ReactElement | null {
  const { ticket, changeTicket } = props
  const { organisation } = useOrganisation()
  const [allQuestions, setAllQuestions] = useState<PortalTicketQuestion[]>()
  const [disableDelete, setDisableDelete] = useState<number[]>([])

  const getQuestions = async (): Promise<void> => {
    if (organisation) {
      const res = await get<PortalTicketQuestion[]>({
        resource: Resource.Questions,
      })
      if (res) setAllQuestions(res)
    }
  }

  useEffect(() => {
    getQuestions()
  }, [])

  const handleDeleteQuestion = async (questionId: number): Promise<void> => {
    if (organisation) {
      const res = await deleteQuestion({ orgId: organisation.id, questionId })
      if (res) await getQuestions()
    }
  }

  return (
    <Styles>
      <h3>Ticket questions</h3>
      <TicketAddQuestions setAllQuestions={setAllQuestions} />
      {allQuestions && allQuestions.length ? (
        <SimpleTable>
          <tbody>
            <tr>
              <th>Enable</th>
              <th>Title</th>
              <th>Type</th>
              <th>Options</th>
              <th>Delete</th>
            </tr>
            {allQuestions.map((question) => {
              const questions = ticket.enabledQuestionIds ?? []
              const { options } = question

              const renderOptions = options
                && options.length > 0
                && options.map((option, index) => (
                  <Option key={option.value}>
                    <Number>{`${index + 1}. `}</Number>
                    <span>{option.label}</span>
                  </Option>
                ))

              const isRequired = question.rules && question.rules.includes('required')
                ? 'Required'
                : 'Optional'

              const parentUsingQuestion = ticket.enabledQuestionIds
                && ticket.enabledQuestionIds.length > 0

              const usingQuestionBeforeSaving = disableDelete.includes(
                question.id,
              )

              // TODO: Difficult to determine if a Question is used elsewhere.
              // `question.used` currently not working, seeing if we can fix...
              // Will still need `usingQuestionBeforeSaving` regardless
              const questionInUse = parentUsingQuestion
                || priceUsingQuestion(question.id, ticket.prices)
                || usingQuestionBeforeSaving
                || question.used

              return (
                <tr key={question.id}>
                  <td aria-label="Enable question">
                    <EnableQuestion
                      question={question}
                      questions={questions}
                      ticket={ticket}
                      changeTicket={changeTicket}
                      setDisableDelete={setDisableDelete}
                    />
                  </td>
                  <td style={{ maxWidth: '300px' }}>{question.title}</td>
                  <td style={{ textWrap: 'nowrap' }}>
                    {`${isRequired} ${capitalize(question.type)}`}
                  </td>
                  <td>{renderOptions}</td>
                  <td>
                    <AsyncButton
                      onClick={(): Promise<void> => handleDeleteQuestion(question.id)}
                      buttonStyle={ButtonStyle.Secondary}
                      buttonSize={ButtonSize.Table}
                      showConfirmation={true}
                      disabled={questionInUse}
                      style={{ width: '46px' }}
                    >
                      {questionInUse ? 'In use' : 'Delete'}
                    </AsyncButton>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </SimpleTable>
      ) : null}
    </Styles>
  )
}
