import {
  Button,
  colors,
  CurrencyCode,
  IHistoricData,
  Loading,
  ReportDateRange,
  ReportMetricType,
  getTableCSV,
  ReportingWidget,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { capitalize } from '../../../helpers/DataTransformer'
import { voucherWord } from '../../../helpers/FormatHelper'
import useResponsive from '../../../helpers/isResponsive'
import { OrganisationFormat } from '../../../types/Types'
import { renderTotal } from '../ReportingHelper'

const StyledReportingWidget = styled(ReportingWidget)`
  .table-grid {
    display: grid;
    grid-auto-flow: column;
  }
  .grid-column {
    text-align: center;
    div {
      border: 1px solid transparent;
      transition: 0.2s;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px;
      :hover {
        border-color: ${colors.darkGray};
        background-color: ${colors.lightestGray};
      }
    }
    .grid-header {
      font-weight: bold;
      justify-content: flex-end;
    }
    .grid-month {
      font-weight: bold;
    }
    .grid-header,
    .grid-corner,
    .grid-month {
      pointer-events: none;
    }
    .grid-item {
      ${(props): string => props.theme.isSmallDesktop && 'font-size: 0.9rem;'}
    }
    .grid-sales {
      color: ${colors.paid};
    }
    .grid-redemptions {
      color: ${colors.redeemed};
    }
    .grid-expired {
      color: ${colors.expired};
    }
    .grid-cancellations {
      color: ${colors.cancelled};
    }
  }
`

interface ITable {
  currency: CurrencyCode
  loading: boolean
  data: IHistoricData[] | undefined
  metricType: ReportMetricType
  dateRange: ReportDateRange
}
export default function Table(props: ITable): ReactElement {
  const {
    currency, loading, data, metricType, dateRange,
  } = props
  const { organisation } = useOrganisation()
  const ifNotTickets = organisation?.format !== OrganisationFormat.Tickets

  const renderGridData = data
    && data.map((month) => {
      const {
        label, sales, redemptions, expired, cancellations,
      } = month

      const getTableTotal = (value: number): string => renderTotal(value, metricType, currency, 0)

      return (
        <div className="grid-column" key={month.label}>
          <div className="grid-month">{label}</div>
          <div className="grid-item grid-sales">
            {getTableTotal(sales[metricType])}
          </div>
          <div className="grid-item grid-redemptions">
            {getTableTotal(redemptions[metricType])}
          </div>
          {ifNotTickets && (
            <div className="grid-item grid-expired">
              {getTableTotal(expired[metricType])}
            </div>
          )}
          <div className="grid-item grid-cancellations">
            {getTableTotal(cancellations[metricType])}
          </div>
        </div>
      )
    })

  const title = `${capitalize(
    voucherWord(organisation),
  )}s - status by ${metricType}: ${dateRange.selected}`

  const controls = data && (
    <CSVLink
      filename={`${title}.csv`}
      data={getTableCSV(title, data, metricType)}
      target="_blank"
    >
      <Button>Download data</Button>
    </CSVLink>
  )

  return (
    <StyledReportingWidget
      title={title}
      controls={controls}
      theme={useResponsive()}
    >
      {!data || loading ? (
        <Loading fullPage={true} />
      ) : (
        <div className="table-grid">
          <div className="grid-column">
            <div className="grid-corner">&nbsp;</div>
            <div className="grid-header grid-sales">Sales</div>
            <div className="grid-header grid-redemptions">Redemptions</div>
            {ifNotTickets && (
              <div className="grid-header grid-expired">Expired</div>
            )}
            <div className="grid-header grid-cancellations">Cancellations</div>
          </div>
          {renderGridData}
        </div>
      )}
    </StyledReportingWidget>
  )
}
