import './fonts.css'
import React, { ReactElement } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Notifications, NotificationPosition, Role } from '@one-tree/library'
import { AuthProvider } from './context/AuthProvider'
import Login from './pages/login/Login'
import Logout from './pages/Logout'
import AuthRoute from './components/routing/AuthRoute'
import { RoutePath } from './types/Routes'
import SelectOrganisation from './pages/selectOrganisation/SelectOrganisation'
import Home from './pages/home/Home'
import Sales from './pages/sales/Sales'
import { OrganisationProvider } from './context/OrganisationProvider'
import OrganisationRoute from './components/routing/OrganisationRoute'
import Footer from './components/page/Footer'
import Users from './pages/users/Users'
import LicenseInvoices from './pages/invoices/LicenseInvoices'
import OrderInvoices from './pages/invoices/OrderInvoices'
import OrderInvoice from './pages/invoices/OrderInvoice'
import GiftCardResult from './pages/giftCard/GiftCardResult'
import SearchResults from './pages/SearchResults'
import ManualOrder from './pages/sales/ManualOrder'
import Reporting from './pages/reporting/Reporting'
import EditGiftItem from './pages/manage/giftItem/EditGiftItem'
import Manage from './pages/manage/Manage'
import EditCategory from './pages/manage/category/EditCategory'
import WebShop from './pages/webShop/WebShop'
import Template from './pages/template/Template'
import LicenseInvoice from './pages/invoices/LicenseInvoice'
import RedeemerResult from './pages/giftCard/RedeemerResult'
import Contacts from './pages/contacts/Contacts'
import EditUser from './pages/users/EditUser'

function App(): ReactElement {
  return (
    <AuthProvider>
      <OrganisationProvider>
        <>
          <Router>
            <Switch>
              <Route path={RoutePath.Login} component={Login} />
              <Route path={RoutePath.Logout} component={Logout} />
              <AuthRoute
                path={RoutePath.SelectOrganisation}
                component={SelectOrganisation}
              />
              <OrganisationRoute
                path={RoutePath.ManualOrder}
                requiredRole={Role.OrganisationUser}
                component={ManualOrder}
              />
              <OrganisationRoute
                path={RoutePath.Sales}
                requiredRole={Role.OrganisationUser}
                component={Sales}
              />
              <OrganisationRoute
                path={RoutePath.CreateItem}
                requiredRole={Role.OrganisationAdmin}
                component={EditGiftItem}
              />
              <OrganisationRoute
                path={`${RoutePath.ManageItems}/:id`}
                requiredRole={Role.OrganisationAdmin}
                component={EditGiftItem}
              />
              <OrganisationRoute
                path={RoutePath.CreateCategory}
                requiredRole={Role.OrganisationAdmin}
                component={EditCategory}
              />
              <OrganisationRoute
                path={`${RoutePath.ManageCategories}/:id`}
                requiredRole={Role.OrganisationAdmin}
                component={EditCategory}
              />
              <OrganisationRoute
                path={RoutePath.Manage}
                requiredRole={Role.OrganisationAdmin}
                component={Manage}
              />
              <OrganisationRoute
                path={`${RoutePath.EditUser}/:id`}
                requiredRole={Role.OrganisationAdmin}
                component={EditUser}
              />
              <OrganisationRoute
                path={RoutePath.EditUser}
                requiredRole={Role.OrganisationAdmin}
                component={EditUser}
              />
              <OrganisationRoute
                path={RoutePath.Users}
                requiredRole={Role.OrganisationAdmin}
                component={Users}
              />
              <OrganisationRoute
                path={RoutePath.Contacts}
                requiredRole={Role.OrganisationAdmin}
                component={Contacts}
              />
              <OrganisationRoute
                path={`${RoutePath.Billing}/:id`}
                requiredRole={Role.OrganisationAdmin}
                component={LicenseInvoice}
              />
              <OrganisationRoute
                path={RoutePath.Billing}
                requiredRole={Role.OrganisationAdmin}
                component={LicenseInvoices}
              />
              <OrganisationRoute
                path={`${RoutePath.Invoices}/:id`}
                requiredRole={Role.OrganisationAdmin}
                component={OrderInvoice}
              />
              <OrganisationRoute
                path={RoutePath.Invoices}
                requiredRole={Role.OrganisationAdmin}
                component={OrderInvoices}
              />
              <OrganisationRoute
                path={RoutePath.Reporting}
                requiredRole={Role.OrganisationAdmin}
                component={Reporting}
              />
              <OrganisationRoute
                path={`${RoutePath.VoucherDetail}/:id`}
                requiredRole={Role.OrganisationUser}
                component={GiftCardResult}
              />
              <OrganisationRoute
                path={RoutePath.VoucherRedeem}
                requiredRole={Role.OrganisationRedeemer}
                component={RedeemerResult}
              />
              <OrganisationRoute
                path={RoutePath.SearchResults}
                requiredRole={Role.OrganisationUser}
                component={SearchResults}
              />
              <OrganisationRoute
                path={RoutePath.WebShop}
                requiredRole={Role.OrganisationAdmin}
                component={WebShop}
              />
              <OrganisationRoute
                path={RoutePath.Template}
                requiredRole={Role.OrganisationAdmin}
                component={Template}
              />
              <OrganisationRoute
                path={RoutePath.Home}
                requiredRole={Role.OrganisationRedeemer}
                component={Home}
              />
            </Switch>
          </Router>
          <Footer />
          <Notifications
            notificationPosition={NotificationPosition.BottomRight}
          />
        </>
      </OrganisationProvider>
    </AuthProvider>
  )
}

export default App
