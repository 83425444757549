import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { DeliverTo, InputField, InputType } from '@one-tree/library'
import { IGiftCardPatchBody } from '../../../../../types/API'
import { StateAction } from '../../../../../types/Aliases'

const Styles = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;
`

export interface IEditDeliveryAddressRowsProps {
  deliverTo: DeliverTo
  address: IGiftCardPatchBody
  setAddress: StateAction<IGiftCardPatchBody>
}

function EditDeliveryAddressRows(
  props: IEditDeliveryAddressRowsProps,
): ReactElement {
  const { deliverTo, address, setAddress } = props

  const handleChange = (key: keyof IGiftCardPatchBody, value: string): void => {
    setAddress((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const toRecipient = deliverTo === DeliverTo.Recipient

  return (
    <Styles>
      <InputField
        placeholder="First name"
        type={InputType.Text}
        onChange={(value): void => {
          handleChange(
            toRecipient ? 'deliveryFirstName' : 'purchaserFirstName',
            value,
          )
        }}
        value={
          toRecipient ? address.deliveryFirstName : address.purchaserFirstName
        }
      />
      <InputField
        placeholder="Last name"
        type={InputType.Text}
        onChange={(value): void => {
          handleChange(
            toRecipient ? 'deliveryLastName' : 'purchaserLastName',
            value,
          )
        }}
        value={
          toRecipient ? address.deliveryLastName : address.purchaserLastName
        }
      />
      <InputField
        placeholder="Address line one"
        type={InputType.Text}
        onChange={(value): void => {
          handleChange('deliveryAddressLineOne', value)
        }}
        value={address.deliveryAddressLineOne}
        isLong={true}
      />
      <InputField
        placeholder="Address line two"
        type={InputType.Text}
        onChange={(value): void => {
          handleChange('deliveryAddressLineTwo', value)
        }}
        value={address.deliveryAddressLineTwo}
        isLong={true}
      />
      <InputField
        placeholder="Town"
        type={InputType.Text}
        onChange={(value): void => {
          handleChange('deliveryAddressTown', value)
        }}
        value={address.deliveryAddressTown}
        isLong={true}
      />
      <InputField
        placeholder="Postcode"
        type={InputType.Text}
        onChange={(value): void => {
          handleChange('deliveryAddressPostcode', value)
        }}
        value={address.deliveryAddressPostcode}
        isLong={true}
      />
    </Styles>
  )
}
export default EditDeliveryAddressRows
