import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  alertSuccess,
  colors,
  shortVoucherWord,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getOrgAccessToken, patchOrganisation } from '../../helpers/APIHelper'
import { getGiftItems } from '../../helpers/APIItemHelper'
import { voucherWord } from '../../helpers/FormatHelper'
import Page from '../../hoc/Page'
import { IOrganisationPatchBody } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import TemplateDetails from './TemplateDetails'

function Template(): ReactElement {
  const [changes, setChanges] = useState<IOrganisationPatchBody | null>(null)
  const history = useHistory()
  const { organisation, selectOrganisation } = useOrganisation()

  const handleSaveVoucherTemplate = async (): Promise<void> => {
    if (organisation && changes) {
      // when patching, only send what has changed
      const res = await patchOrganisation({
        organisationId: organisation.id,
        patchOptions: {
          ...changes,
        },
      })
      if (res && (await selectOrganisation(res.id))) {
        setChanges(null)
        alertSuccess('Voucher components saved')
      }
    }
  }

  const handleChangeOrganisation = (updates: IOrganisationPatchBody): void => {
    setChanges({
      ...changes,
      ...updates,
    })
  }

  const missingEssential = organisation && !organisation.headerImageUrl

  const handlePreview = async (): Promise<void> => {
    if (organisation) {
      const items = await getGiftItems({ format: organisation.format })
      const token = await getOrgAccessToken()
      if (items) {
        // we are looking for an example gift item so just grab the first one
        const item = items[0].id
        if (item && token) {
          window.open(
            `${process.env.REACT_APP_BACKEND_URL}/gift_items/preview/${item}?token=${token}`,
            '_blank',
          )
        }
      }
    }
  }

  return (
    <Page warnBeforeExit={Boolean(changes)}>
      <Card cardSize={CardSize.Medium}>
        <CardHeader title={`Customise your ${voucherWord(organisation)}s`}>
          <AsyncButton
            buttonStyle={ButtonStyle.Primary}
            onClick={handlePreview}
            disabled={Boolean(changes)}
          >
            Preview sample {shortVoucherWord(organisation?.format)}
          </AsyncButton>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => history.push(RoutePath.Home)}
          >
            Cancel
          </Button>
          <AsyncButton
            buttonStyle={ButtonStyle.Action}
            disabled={!changes}
            onClick={handleSaveVoucherTemplate}
          >
            Save
          </AsyncButton>
        </CardHeader>
        <CardContent>
          <>
            <p>
              {`The details entered below are displayed on every ${voucherWord(
                organisation,
              )}. Please check for accuracy.`}
            </p>
            {missingEssential && (
              <p style={{ color: `${colors.red}`, fontWeight: 'bold' }}>
                You are missing essential details. Please complete any red
                sections below.
              </p>
            )}
          </>
          {organisation && (
            <TemplateDetails
              values={{
                ...organisation,
                ...changes,
              }}
              organisation={organisation}
              changeOrganisation={handleChangeOrganisation}
            />
          )}
        </CardContent>
      </Card>
    </Page>
  )
}
export default Template
