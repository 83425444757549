import {
  alertError,
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  capitalize,
  colors,
  GiftItemStatus,
  GiftItemType,
  IGiftItemResponse,
  IGiftVoucher,
  ISpecialOffer,
  ITicket,
  Loading,
  onlyChanges,
  stripHTML,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Embed from '../../../components/Embed'
import Card, { CardSize } from '../../../components/page/Card'
import CardContent from '../../../components/page/CardContent'
import CardHeader from '../../../components/page/CardHeader'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { getOrgAccessToken } from '../../../helpers/APIHelper'
import {
  createGiftItem,
  deleteGiftItem,
  getGiftItem,
  patchGiftItem,
} from '../../../helpers/APIItemHelper'
import {
  Code,
  itemWord,
  shortVoucherWord,
  voucherWord,
} from '../../../helpers/FormatHelper'
import { isGiftItemValid } from '../../../helpers/GiftItemValidityHelper'
import Page from '../../../hoc/Page'
import { IGiftItemPostBody } from '../../../types/GiftItemTypes'
import { RoutePath } from '../../../types/Routes'
import { OrganisationFormat } from '../../../types/Types'
import GiftVoucherDesign from './GiftVoucherDesign'
import SpecialOfferDesign from './SpecialOfferDesign'
import TicketDesign from './TicketDesign'
import {
  getPriceChanges,
  updatePriceQuestions,
} from './designElements/TicketPrices/pricesHelper'

const GiftGrid = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 60px 100px;
  grid-template-areas:
    'gift-settings gift-settings'
    'gift-design preview-design'
    'gift-description preview-description';
  section {
    display: flex;
    flex-direction: column;
    gap: 30px 0;
    h3 {
      margin: 0;
    }
  }
  .gift-settings {
    grid-area: gift-settings;
  }
  /* Breaking these styles out of the above class as Ticket Questions looks better without */
  .gift-settings-styles {
    display: flex;
    flex-direction: column;
    gap: 30px 0;
    h3 {
      margin: 0;
    }
    border: 1px solid ${colors.lightGray};
    border-radius: 4px;
    background-color: ${colors.lightestGray};
    padding: 20px;
    p {
      margin: 0;
    }
  }
  .gift-design {
    grid-area: gift-design;
    min-width: 320px;
  }
  .preview-design {
    grid-area: preview-design;
  }
  .gift-description {
    grid-area: gift-description;
  }
  .preview-description {
    grid-area: preview-description;
  }
`

function EditGiftItem(): ReactElement {
  const { id }: { id: string } = useParams()
  const history = useHistory()
  const [giftItem, setGiftItem] = useState<IGiftItemResponse | null>(null)
  const [changes, setChanges] = useState<IGiftItemPostBody | null>(null)
  const [loading, setLoading] = useState(false)
  const [showEmbed, setShowEmbed] = useState(false)
  const [isEditing, setIsEditing] = useState(Boolean(id))
  const [processing, setProcessing] = useState(false)

  const { organisation } = useOrganisation()
  const format = organisation?.format
  const wording = itemWord(organisation)
  const wordingCaps = capitalize(wording)
  const longVoucher = voucherWord(organisation)
  const shortVoucher = shortVoucherWord(organisation)
  const isTESO = format !== OrganisationFormat.GiftVouchers

  const monetary = !isTESO
    ? ', unless the Monetary option is selected. In this case the Summary field does not appear on the voucher'
    : ''

  const text = `The words entered in these fields appear on your website and on the ${longVoucher}${monetary}. Please describe what is included. General terms and conditions of use appear at the bottom of all ${longVoucher}s and can be edited in the ${shortVoucher} template.`

  useEffect(() => {
    (async (): Promise<void> => {
      setLoading(true)
      if (id && organisation) {
        const response = await getGiftItem({ format: organisation.format, id })
        if (response) {
          setGiftItem(response)
        } else {
          alertError(`${wordingCaps} not found`)
          history.push(RoutePath.Home)
        }
      }
      setLoading(false)
    })()
  }, [id])

  useEffect(() => {
    if (!id && !giftItem) {
      setGiftItem((prevState) => ({
        ...prevState,
        status: GiftItemStatus.Unpublished,
      }))
    }
  }, [])

  useEffect(() => {
    // if user changes to a Monetary type, description should auto-fill with copy
    const existingDescription = stripHTML(
      (changes?.description || '') + (giftItem?.description || ''),
    )
    if (changes?.type === GiftItemType.Monetary && !existingDescription) {
      setChanges((prevState) => ({
        ...prevState,
        description:
          'Give a special treat. Choose an amount, personalise and buy your gift voucher securely today.',
      }))
    }
  }, [changes?.type])

  const handleArchiveItem = async (): Promise<void> => {
    setProcessing(true)
    if (giftItem && giftItem.id && organisation) {
      const response = await deleteGiftItem({
        format: organisation.format,
        id: giftItem.id,
      })
      if (response) {
        alertSuccess(`${wordingCaps} archived`)
        history.push(RoutePath.ManageItems)
      }
    }
    setProcessing(false)
  }

  const handleRestoreItem = async (): Promise<void> => {
    setProcessing(true)
    if (giftItem && giftItem.id && organisation) {
      const response = await patchGiftItem({
        format: organisation.format,
        itemId: giftItem.id,
        patchOptions: {
          status: GiftItemStatus.Unpublished,
        },
      })
      if (response) {
        alertSuccess(`${wordingCaps} restored`)
        history.push(RoutePath.ManageItems)
      }
    }
    setProcessing(false)
  }

  const handleCloneItem = async (): Promise<void> => {
    if (giftItem && organisation) {
      const giftItemClone: Partial<IGiftItemResponse> = { ...giftItem }
      // delete fields that cannot be given to a clone (i.e. clone will have own `id`, `order`, etc)
      delete giftItemClone.id
      delete giftItemClone.imageUrl
      delete giftItemClone.headerImageUrl
      delete giftItemClone.quantitySold
      delete giftItemClone.status
      delete giftItemClone.order
      delete giftItemClone.embedUrl
      const response = await createGiftItem({
        format: organisation.format,
        giftItem: giftItemClone,
      })
      setLoading(true)
      if (response) {
        // Timeout gives user visual feedback that the Item has changed
        setTimeout(() => {
          alertSuccess(`${wordingCaps} cloned`)
          history.push(`${RoutePath.ManageItems}/${response.id}`)
          setLoading(false)
        }, 1000)
      } else {
        alertError(`There was an error cloning the ${wording}`)
        setLoading(false)
      }
    }
  }

  const handlePreview = async (giftId: number | undefined): Promise<void> => {
    const token = await getOrgAccessToken()
    if (giftId && token) {
      window.open(
        `${process.env.REACT_APP_BACKEND_URL}/gift_items/preview/${giftId}?token=${token}`,
        '_blank',
      )
    }
  }

  const handleSaveItem = async (): Promise<void> => {
    setProcessing(true)

    if (giftItem && organisation) {
      const hasNoHeader = !giftItem.headerImageUrl
      const hasNoNewHeader = !changes?.headerImageFileId
      const addDefaultHeader = hasNoHeader
        && hasNoNewHeader && {
        headerImageFileId: organisation.headerImageFileId,
      }
      const toSave = {
        ...changes,
        ...addDefaultHeader,
      }

      // Extract Ticket Prices from the changes so they can be handled separately
      const { prices: newPrices } = toSave
      delete toSave.prices

      const pricesSuccess = await updatePriceQuestions(
        organisation,
        getPriceChanges(newPrices, giftItem?.prices),
      )

      let itemSuccess: IGiftItemResponse | false = false
      // If we still have changes after removing Ticket Prices
      const stillHasChanges = Object.keys(toSave).length !== 0

      if (stillHasChanges) {
        if (id && giftItem.id) {
          // when patching, only send what has changed
          itemSuccess = await patchGiftItem({
            format: organisation.format,
            itemId: giftItem.id,
            patchOptions: toSave,
          })
        } else {
          itemSuccess = await createGiftItem({
            format: organisation.format,
            giftItem: toSave,
          })
        }
      }

      if (itemSuccess || pricesSuccess) {
        setChanges(null)
        alertSuccess(`${wordingCaps} saved`)
        setLoading(true)

        // open the preview on save if the gift item is valid
        if (itemSuccess && isGiftItemValid(itemSuccess, organisation)) {
          handlePreview(itemSuccess.id)
        }

        history.push(RoutePath.ManageItems)
      }
      setLoading(false)
    }
    setProcessing(false)
  }

  const handleChangeGiftItem = (updates: IGiftItemPostBody): void => {
    setChanges({
      ...changes,
      ...updates,
    })
  }

  const actualChanges = onlyChanges(giftItem || undefined, changes || undefined)

  const isArchived = giftItem?.status === GiftItemStatus.Archived

  return (
    <Page warnBeforeExit={!processing && Boolean(actualChanges)}>
      <Card cardSize={CardSize.Medium}>
        <CardHeader
          title={
            id
              ? `Edit${isArchived ? ' archived' : ''} ${wording}`
              : `Create new ${wording}`
          }
        >
          {!loading && (
            <>
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => history.push(RoutePath.ManageItems)}
              >
                Cancel
              </Button>
              {giftItem?.id && (
                <>
                  {!isArchived && (
                    <AsyncButton
                      buttonStyle={ButtonStyle.Secondary}
                      onClick={(): void => setShowEmbed(true)}
                    >
                      Embed
                    </AsyncButton>
                  )}
                  {isArchived ? (
                    <AsyncButton
                      buttonStyle={ButtonStyle.Action}
                      onClick={handleRestoreItem}
                    >
                      Restore
                    </AsyncButton>
                  ) : (
                    <AsyncButton
                      buttonStyle={ButtonStyle.Danger}
                      onClick={handleArchiveItem}
                      showConfirmation={true}
                      confirmationMessage={`This ${wording} will be hidden, but can be restored later. Any unsaved changes will be lost. Proceed?`}
                    >
                      Archive
                    </AsyncButton>
                  )}
                  <AsyncButton
                    buttonStyle={ButtonStyle.Primary}
                    onClick={(): Promise<void> => handleCloneItem()}
                    showConfirmation={true}
                    confirmationMessage={`Are you sure you want to clone this ${wording}?`}
                    disabled={Boolean(actualChanges)}
                  >
                    Clone
                  </AsyncButton>
                  <AsyncButton
                    buttonStyle={ButtonStyle.Primary}
                    onClick={(): Promise<void> => handlePreview(giftItem.id)}
                    disabled={Boolean(actualChanges)}
                  >
                    Preview
                  </AsyncButton>
                </>
              )}
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                onClick={handleSaveItem}
                disabled={!actualChanges}
                showConfirmation={
                  !isGiftItemValid({ ...giftItem, ...changes }, organisation)
                }
                confirmationMessage={`Are you sure you want to save an unfinished ${wording}?`}
                onConfirmationCancel={(): void => setIsEditing(true)}
              >
                Save
              </AsyncButton>
            </>
          )}
        </CardHeader>
        <CardContent>
          {loading || !giftItem ? (
            <Loading fullPage={true} />
          ) : (
            <>
              <p>{text}</p>
              <GiftGrid>
                {/* For the below type assertions. If we are e.g. GiftVouchers
              format, we know giftItem is IGiftVoucher  */}
                {/* eslint-disable no-type-assertion/no-type-assertion */}
                {format === OrganisationFormat.GiftVouchers && (
                  <GiftVoucherDesign
                    giftVoucher={
                      {
                        ...giftItem,
                        ...changes,
                      } as IGiftVoucher
                    }
                    changeGiftVoucher={handleChangeGiftItem}
                    isEditing={isEditing}
                  />
                )}
                {format === OrganisationFormat.SpecialOffers && (
                  <SpecialOfferDesign
                    specialOffer={
                      {
                        ...giftItem,
                        ...changes,
                      } as ISpecialOffer
                    }
                    changeSpecialOffer={handleChangeGiftItem}
                    isEditing={isEditing}
                  />
                )}
                {format === OrganisationFormat.Tickets && (
                  <TicketDesign
                    ticket={
                      {
                        ...giftItem,
                        ...changes,
                      } as ITicket
                    }
                    changeTicket={handleChangeGiftItem}
                    isEditing={isEditing}
                    setGiftItem={setGiftItem}
                    changes={changes}
                    setChanges={setChanges}
                  />
                )}
              </GiftGrid>
            </>
          )}
        </CardContent>
      </Card>
      <Embed
        modalOpen={showEmbed}
        setModalOpen={setShowEmbed}
        embedUrl={giftItem?.embedUrl}
        linkText={(
          <>
            <br />
            <h3>Create a link to this {wording}</h3>
            <p>Add the following suffix to your {longVoucher} shop URL:</p>
            <Code>?item={giftItem?.id}</Code>
            <p>E.g. https://yourcompanyname.co.uk/gift-vouchers?item=XXX</p>
          </>
        )}
      />
    </Page>
  )
}
export default EditGiftItem
