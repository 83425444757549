import React, { ReactElement, ClipboardEvent } from 'react'
import { colors, FieldStyles } from '@one-tree/library'
import styled from 'styled-components'
import { MdClear } from 'react-icons/md'
import {
  cleanInput,
  goToInput,
  handleNextInput,
  handlePrevInput,
  stringToCode,
} from './codeHelper'

const Inputs = styled.div`
  display: inline-flex;
  column-gap: 10px;
  position: relative;
  margin-right: 25px;
  svg {
    width: 20px;
    height: auto;
    position: absolute;
    inset: 0 -22px 0 auto;
    margin: auto;
    color: red;
    transition: color 0.2s;
    cursor: pointer;
    color: ${colors.darkGray};
    :hover {
      color: ${colors.darkerGray};
    }
  }
  .styled-input {
    text-align: center;
  }
`

interface IInputFieldProps {
  id: string
  index: number
  value: string
  handleChange: (index: number, value: string) => void
  handlePaste: (event: ClipboardEvent<HTMLInputElement>) => void
}
export function CodeInputField(props: IInputFieldProps): ReactElement {
  const {
    id, index, value, handleChange, handlePaste,
  } = props
  return (
    <input
      id={id}
      value={value}
      onChange={(event): void => {
        const newValue = cleanInput(event.target.value)
        if (newValue.length <= 4) {
          handleChange(index, newValue)
        }
        if (newValue.length === 4) {
          handleNextInput(id)
        }
      }}
      className="styled-input"
      onPaste={handlePaste}
      onKeyDown={(event): void => {
        if (event.key === 'Backspace' && !value) handlePrevInput(id)
      }}
    />
  )
}

interface ICodeInputProps {
  value: string
  onChange: (code: string) => void
  label?: string
}
export default function CodeInput(props: ICodeInputProps): ReactElement {
  const { value, onChange, label } = props

  const handleChange = (index: number, newValue: string): void => {
    const codeArray = stringToCode(value)
    if (!codeArray) return

    codeArray[index] = newValue

    onChange(codeArray.join('-'))
  }

  const handlePaste = (
    event: ClipboardEvent<HTMLInputElement>,
    index: number,
  ): void => {
    event.preventDefault()

    const clip = event.clipboardData
    if (!clip) return

    const toPaste = cleanInput(clip.getData('Text'))
    const formatted = toPaste.match(/.{1,4}/g)
    if (!formatted) return

    const codeArray = stringToCode(value)

    const isFullCode = toPaste.length === 16

    // replace the existing code with our pasted values, from the box we pasted in
    // also if pasting a full code, always paste from start
    codeArray.splice(isFullCode ? 0 : index, formatted.length, ...formatted)
    const truncated = codeArray.slice(0, 4)

    onChange(truncated.join('-'))

    // put cursor in furthest populated input
    goToInput(truncated.filter((n) => n).length - 1)
  }

  const renderFields = stringToCode(value).map((codeSection, index) => {
    const key = `${index}`
    return (
      <CodeInputField
        key={key}
        id={`code-field-${index}`}
        index={index}
        value={codeSection}
        handleChange={handleChange}
        handlePaste={(event): void => handlePaste(event, index)}
      />
    )
  })

  return (
    <FieldStyles label={label}>
      <Inputs>
        {renderFields}
        {value.length > 0 && <MdClear onClick={(): void => onChange('')} />}
      </Inputs>
    </FieldStyles>
  )
}
