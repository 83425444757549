// eslint-disable-next-line import/prefer-default-export
export enum RoutePath {
  Billing = '/billing',
  ChangePassword = '/login/change-password',
  Dashboard = '/dashboard',
  Feedback = '/feedback',
  Help = '/help',
  Home = '/',
  Invoices = '/invoices',
  Login = '/login',
  Logout = '/logout',
  Manage = '/manage',
  ManageItems = '/manage/items',
  CreateItem = '/manage/items/create',
  PreviewItem = '/manage/items/preview',
  ManageCategories = '/manage/categories',
  CreateCategory = '/manage/categories/create',
  EditUser = '/manage-users/edit',
  Reporting = '/reporting',
  RequestPassword = '/login/reset-password',
  Resources = '/resources',
  Sales = '/sales',
  ManualOrder = '/sales/manual-order',
  SelectOrganisation = '/select-organisation',
  Settings = '/settings',
  Contacts = '/manage-contacts',
  WebShop = '/web-shop',
  Template = '/template',
  Users = '/manage-users',
  SearchResults = '/search-results',
  VoucherDetail = '/voucher-detail',
  VoucherDownload = '/voucher-download',
  VoucherRedeem = '/voucher-redeem',
}
