import { GiftCardStatus } from '@one-tree/library'
import React, { ReactElement } from 'react'
import {
  getGiftCardInvalidReason,
  isGiftCardValid,
} from '../../../../helpers/GiftCardHelper'
import { OrganisationFormat } from '../../../../types/Types'

interface IValidityRowProps {
  status: GiftCardStatus
  organisationFormat: OrganisationFormat | undefined
  justRedeemed: boolean
}

function ValidityRow(props: IValidityRowProps): ReactElement | null {
  const { status, organisationFormat, justRedeemed } = props

  return !isGiftCardValid(status) && !justRedeemed ? (
    <tr>
      <th>Invalid reason</th>
      <td>{getGiftCardInvalidReason(status, organisationFormat)}</td>
    </tr>
  ) : null
}
export default ValidityRow
