import styled from 'styled-components'

export const Header = styled.div`
  h3 {
    margin: 0 0 8px;
  }
  p {
    margin: 0 0 18px;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`
