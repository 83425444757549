import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  CardError,
  Loading,
  AsyncButton,
  Button,
  ButtonStyle,
  Buttonlink,
  alertSuccess,
  alertError,
} from '@one-tree/library'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { getContacts, patchContact } from '../../helpers/APIHelper'
import { voucherWord } from '../../helpers/FormatHelper'
import Page from '../../hoc/Page'
import { IContactPatchBody, IContactResponse } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import Contact from './Contact'
import { billingNameTest } from './contactValidation'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
`

export default function Contacts(): ReactElement {
  const [contacts, setContacts] = useState<IContactResponse | undefined>()
  const { organisation, selectOrganisation } = useOrganisation()
  const [billingChanges, setBillingChanges] = useState<IContactPatchBody | null>(null)
  const [salesChanges, setSalesChanges] = useState<IContactPatchBody | null>(
    null,
  )
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const fetchContacts = async (): Promise<void> => {
    const res = await getContacts()
    if (res) setContacts(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchContacts()
  }, [])

  const handleSave = async (): Promise<void> => {
    if (!contacts) {
      alertError('Save error: no contacts found')
      return
    }

    if (billingChanges) {
      const {
        billing: { contactId },
      } = contacts
      const res = await patchContact({
        contactId,
        patchOptions: billingChanges,
      })
      if (res) alertSuccess('Billing contact saved')
    }

    if (salesChanges) {
      const {
        sales: { contactId },
      } = contacts
      const res = await patchContact({ contactId, patchOptions: salesChanges })
      if (res) alertSuccess('Sales contact saved')
    }

    await fetchContacts()
    setBillingChanges(null)
    setSalesChanges(null)

    // If org is not activated, re-fetch this org to update outstandingFields
    if (
      organisation?.outstandingFields
      && organisation.outstandingFields.length > 0
    ) {
      await selectOrganisation(organisation.id)
    }
  }

  const changes = Boolean(billingChanges) || Boolean(salesChanges)
  const invalid = !loading && billingNameTest({ ...contacts?.billing, ...billingChanges })

  return (
    <Page warnBeforeExit={changes}>
      <Card cardSize={CardSize.Medium}>
        <CardHeader title="Manage contacts">
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => history.push(RoutePath.Home)}
          >
            Cancel
          </Button>
          <AsyncButton
            buttonStyle={ButtonStyle.Action}
            onClick={handleSave}
            disabled={!changes || Boolean(invalid)}
          >
            Save
          </AsyncButton>
        </CardHeader>
        <CardError>{invalid}</CardError>
        <CardContent>
          <p>
            Please fill in contacts below. The billing contact receives One Tree
            licence fee invoices. The sales contact receives an email each time
            a {`${voucherWord(organisation)}`} is sold.
          </p>
          {!loading ? (
            <Grid>
              <Contact
                heading="Billing contact"
                copyLink={(
                  <Buttonlink
                    onClick={(): void => {
                      const copy = {
                        ...contacts?.billing,
                        ...billingChanges,
                        contactId: undefined,
                        type: undefined,
                      }
                      setSalesChanges(copy)
                    }}
                  >
                    copy to Sales contact
                  </Buttonlink>
                )}
                contact={{ ...contacts?.billing, ...billingChanges }}
                changeContact={(update): void => {
                  setBillingChanges((prevState) => ({
                    ...prevState,
                    ...update,
                  }))
                }}
                showNotifications={false}
              />
              <Contact
                heading="Sales contact"
                copyLink={(
                  <Buttonlink
                    onClick={(): void => {
                      const copy = {
                        ...contacts?.sales,
                        ...salesChanges,
                        contactId: undefined,
                        type: undefined,
                      }
                      setBillingChanges(copy)
                    }}
                  >
                    copy to Billing contact
                  </Buttonlink>
                )}
                contact={{ ...contacts?.sales, ...salesChanges }}
                changeContact={(update): void => {
                  setSalesChanges((prevState) => ({
                    ...prevState,
                    ...update,
                  }))
                }}
                showNotifications={true}
              />
            </Grid>
          ) : (
            <Loading fullPage={true} />
          )}
        </CardContent>
      </Card>
    </Page>
  )
}
