import React, { SVGProps } from 'react'
import { colors, IOrganisationResponse } from '@one-tree/library'
import styled from 'styled-components'
import { ReactComponent as SpecialOffers } from '../assets/icon-special_offers.svg'
import { ReactComponent as GiftVouchers } from '../assets/icon-voucher.svg'
import { ReactComponent as Tickets } from '../assets/icon-ticket.svg'
import { OrganisationFormat } from '../types/Types'

const iconMap = new Map<OrganisationFormat, React.SVGProps<SVGElement>>([
  [
    OrganisationFormat.GiftVouchers,
    <GiftVouchers style={{ fill: colors.giftVouchers }} />,
  ],
  [
    OrganisationFormat.SpecialOffers,
    <SpecialOffers style={{ fill: colors.specialOffers }} />,
  ],
  [OrganisationFormat.Tickets, <Tickets style={{ fill: colors.tickets }} />],
])
export const getFormatIcon = (
  org: IOrganisationResponse,
): SVGProps<SVGElement> | undefined => iconMap.get(org.format)

const itemWordingMap = new Map<OrganisationFormat, string>([
  [OrganisationFormat.GiftVouchers, 'gift'],
  [OrganisationFormat.SpecialOffers, 'offer'],
  [OrganisationFormat.Tickets, 'ticket'],
])
export const itemWord = (
  organisation: IOrganisationResponse | null,
): string => {
  if (organisation) {
    const wording = itemWordingMap.get(organisation.format)
    if (wording) return wording
  }
  return 'item'
}
const voucherWordingMap = new Map<OrganisationFormat, string>([
  [OrganisationFormat.GiftVouchers, 'gift voucher'],
  [OrganisationFormat.SpecialOffers, 'special offer voucher'],
  [OrganisationFormat.Tickets, 'ticket'],
])
export const voucherWord = (
  organisation: IOrganisationResponse | null,
): string => {
  if (organisation) {
    const wording = voucherWordingMap.get(organisation.format)
    if (wording) return wording
  }
  return 'voucher'
}
const organisationWordingMap = new Map<OrganisationFormat, string>([
  [OrganisationFormat.GiftVouchers, 'gift'],
  [OrganisationFormat.SpecialOffers, 'special offer'],
  [OrganisationFormat.Tickets, 'ticket'],
])
export const organisationWord = (
  organisation: IOrganisationResponse | null,
): string => {
  if (organisation) {
    const wording = organisationWordingMap.get(organisation.format)
    if (wording) return wording
  }
  return 'organisation'
}
const shortVoucherWordingMap = new Map<OrganisationFormat, string>([
  [OrganisationFormat.GiftVouchers, 'voucher'],
  [OrganisationFormat.SpecialOffers, 'voucher'],
  [OrganisationFormat.Tickets, 'ticket'],
])
export const shortVoucherWord = (
  organisation: IOrganisationResponse | null,
): string => {
  if (organisation) {
    const wording = shortVoucherWordingMap.get(organisation.format)
    if (wording) return wording
  }
  return 'voucher'
}
export const getOrgsByFormat = (
  orgs: IOrganisationResponse[],
  format: OrganisationFormat,
): IOrganisationResponse[] | undefined => {
  const orgFilterMap = new Map<OrganisationFormat, IOrganisationResponse[]>([
    [
      OrganisationFormat.GiftVouchers,
      orgs.filter((org) => org.format === OrganisationFormat.GiftVouchers),
    ],
    [
      OrganisationFormat.SpecialOffers,
      orgs.filter((org) => org.format === OrganisationFormat.SpecialOffers),
    ],
    [
      OrganisationFormat.Tickets,
      orgs.filter((org) => org.format === OrganisationFormat.Tickets),
    ],
  ])
  return orgFilterMap.get(format)
}

export const getOrganisationColor = (format: OrganisationFormat): string => {
  switch (format) {
  case OrganisationFormat.Tickets:
    return colors.tickets
  case OrganisationFormat.SpecialOffers:
    return colors.specialOffers
  default:
    return colors.giftVouchers
  }
}

export const Code = styled.code`
  background-color: ${colors.gray};
  border: 1px solid ${colors.darkGray};
  display: block;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
`
