import React, { FunctionComponent, ReactElement } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { RoutePath } from '../../types/Routes'

function AuthRoute({
  component,
  path,
}: {
  component: FunctionComponent
  path: string
}): ReactElement {
  const { token } = useAuth()

  return token ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect
      to={{ pathname: RoutePath.Login, state: { referer: document.referrer } }}
    />
  )
}

export default AuthRoute
