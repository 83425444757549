import { colors, theme } from '@one-tree/library'
import axios from 'axios'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import App from './App'

// Import fonts
import '@one-tree/library/dist/index.css'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 14px;
    background-color: ${colors.lightGray};
  }
  body {
    margin: 0;
    overflow: overlay;
    ${theme.scrollbar};
  }
`

axios.defaults.baseURL = process.env.REACT_APP_API_HOST
axios.defaults.headers.post['Content-Type'] = 'application/json'

ReactDOM.render(
  <StrictMode>
    <GlobalStyle />
    <App />
  </StrictMode>,
  document.getElementById('root'),
)
