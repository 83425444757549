import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Switch, InputField, InputType } from '@one-tree/library'
import { IContact } from '../../types/API'

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    margin: 0 7px 0 0;
  }
  margin: 10px 0;
`
interface IContactProps {
  heading: string
  contact: Partial<IContact>
  changeContact: (changes: Partial<IContact>) => void
  copyLink: ReactElement
  showNotifications: boolean
}
export default function Contact(props: IContactProps): ReactElement {
  const {
    heading, contact, copyLink, changeContact, showNotifications,
  } = props
  const {
    title, firstName, lastName, jobTitle, phone, email, notifications,
  } = contact

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof IContact,
  ): void => {
    changeContact({
      [key]: value,
    })
  }

  return (
    <Styles>
      <HeaderRow>
        <h3>{heading}</h3>
        {copyLink}
      </HeaderRow>
      <InputField
        label="Title"
        value={title}
        onChange={(value): void => handleChange(value, 'title')}
      />
      <InputField
        label="First name"
        value={firstName}
        onChange={(value): void => handleChange(value, 'firstName')}
      />
      <InputField
        label="Last name"
        value={lastName}
        onChange={(value): void => handleChange(value, 'lastName')}
      />
      <InputField
        label="Job title"
        value={jobTitle}
        onChange={(value): void => handleChange(value, 'jobTitle')}
      />
      <InputField
        label="Phone number"
        type={InputType.Phone}
        value={phone}
        onChange={(value): void => handleChange(value, 'phone')}
      />
      <InputField
        label="Email"
        type={InputType.Email}
        value={email}
        onChange={(value): void => handleChange(value, 'email')}
      />
      {showNotifications && (
        <Switch
          label="receives sales notifications?"
          flipLabel={true}
          value={Boolean(email) && (notifications || false)}
          switchAction={(isOn): void => handleChange(isOn, 'notifications')}
          disabled={!email}
        />
      )}
    </Styles>
  )
}
