import {
  colors,
  CurrencyCode,
  getOverviewDateRange,
  getOverviewTotal,
  IHistoricData,
  IOverviewParams,
  Loading,
  ReportDateRange,
  useDebounce,
  ReportingWidget,
} from '@one-tree/library'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import Card, { CardSize } from '../../../components/page/Card'
import CardContent from '../../../components/page/CardContent'
import CardHeader from '../../../components/page/CardHeader'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { getReport } from '../../../helpers/APIReportHelper'
import { voucherWord } from '../../../helpers/FormatHelper'
import { renderTotal } from '../ReportingHelper'
import Controls from './Controls'
import Graph from './Graph'
import Table from './Table'

const Grid = styled.div`
  display: grid;
  row-gap: 30px;
  margin-top: 30px;
`
const Title = styled.span`
  font-weight: bold;
  margin-right: 7px;
`
const Value = styled.span`
  font-weight: bold;
  color: ${colors.lightBlue};
`
const StyledReportingWidget = styled(ReportingWidget)`
  display: flex;
  font-size: 1.2rem;
`
interface IOverview {
  currency: CurrencyCode
  init: IOverviewParams
  updateParams: (updates: IOverviewParams) => void
}
function Overview({ currency, init, updateParams }: IOverview): ReactElement {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IHistoricData[]>()

  const [metric, setMetric] = useState(init.overviewMetric)
  const [metricType, setMetricType] = useState(init.overviewMetricType)
  const [fromMonth, setFromMonth] = useState(init.overviewFromMonth)
  const [fromYear, setFromYear] = useState(init.overviewFromYear)

  useEffect(() => {
    updateParams({
      overviewMetric: metric,
      overviewMetricType: metricType,
      overviewFromMonth: fromMonth,
      overviewFromYear: fromYear,
    })
  }, [metric, metricType, fromMonth, fromYear])

  const [timeNow, setTimeNow] = useState<string>()

  const dateRange: ReportDateRange = {
    selected: getOverviewDateRange(fromMonth, fromYear),
    previous: getOverviewDateRange(fromMonth, fromYear - 1),
  }

  const fetchData = async (): Promise<void> => {
    setLoading(true)

    const res = await getReport({ fromMonth, fromYear })
    if (res) {
      setData(res)
      setTimeNow(moment().format('HH:mm on DD/MM'))
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [useDebounce(fromYear, 500), useDebounce(fromMonth, 500)])

  const { organisation } = useOrganisation()

  const instructions = `Use the filters to see an overview of the status of your ${voucherWord(
    organisation,
  )} revenue. Data is a snapshot${timeNow ? ` taken at ${timeNow}.` : '.'}`

  return (
    <Card cardSize={CardSize.Full}>
      <CardHeader title="Reporting" />
      <CardContent>
        <p>{instructions}</p>
        <Controls
          metric={metric}
          setMetric={setMetric}
          metricType={metricType}
          setMetricType={setMetricType}
          fromMonth={fromMonth}
          setFromMonth={setFromMonth}
          fromYear={fromYear}
          setFromYear={setFromYear}
        />
        <Grid>
          <StyledReportingWidget>
            <Title>{`Total ${metric} ${metricType} for ${dateRange.selected}: `}</Title>
            <Value>
              {!data || loading ? (
                <Loading />
              ) : (
                renderTotal(
                  getOverviewTotal(data, metric, metricType),
                  metricType,
                  currency,
                )
              )}
            </Value>
          </StyledReportingWidget>
          <Graph
            currency={currency}
            loading={loading}
            data={data}
            dateRange={dateRange}
            metric={metric}
            metricType={metricType}
            fromMonth={fromMonth}
            fromYear={fromYear}
          />
          <Table
            currency={currency}
            loading={loading}
            data={data}
            metricType={metricType}
            dateRange={dateRange}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}
export default Overview
