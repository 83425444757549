import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  alertError,
  alertSuccess,
  InputField,
  InputType,
  InputGroup,
  TextArea,
  IGiftCardResponse,
} from '@one-tree/library'
import { patchGiftCard } from '../../../../helpers/APIHelper'
import { IEditGiftCardProps } from '../../../../types/API'
import EditIcon from './EditIcon'

const StyledTextArea = styled(TextArea)`
  /* extra specificity ensures this is honored */
  .styled-input.text-area-enabled {
    height: 120px;
    width: 303px;
  }
  .text-area-disabled {
    height: 120px !important;
    width: 303px !important;
  }
`
const StyledCell = styled.td`
  padding-left: 3px !important;
`

function EditNameAndMessage(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props

  const initName = (card: IGiftCardResponse): string => {
    const { deliveryFirstName, deliveryLastName } = card
    const firstName = deliveryFirstName ? `${deliveryFirstName} ` : ''
    const lastName = deliveryLastName ? `${deliveryLastName} ` : ''
    return `${firstName}${lastName}`
  }
  const [name, setName] = useState(initName(giftCard))
  const [message, setMessage] = useState(giftCard.message)
  const [reasonError, setReasonError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [editReason, setEditReason] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const handleChangeDetails = async (): Promise<void> => {
    if (!editReason) {
      setReasonError(true)
      alertError('You must include a reason')
      return
    }
    if (!name) {
      setNameError(true)
      alertError('You must include a name')
      return
    }
    setReasonError(false)
    setNameError(false)

    const nameArray = name.split(' ').filter((word) => word !== '')
    const firstName = nameArray[0]
    nameArray.shift()
    const lastName = nameArray.join(' ')

    const result = await patchGiftCard({
      cardId: giftCard.id,
      patchOptions: {
        firstName,
        lastName,
        message,
        reason: editReason,
      },
    })
    if (result) {
      alertSuccess('Details updated')
      setName(initName(result))
      setEditReason('')
      setIsEditing(false)
      setGiftCard(result)
    }
  }

  useEffect(() => {
    setName(initName(giftCard))
  }, [giftCard])

  return (
    <>
      <tr>
        <th style={{ verticalAlign: 'middle' }}>Recipient name</th>
        <StyledCell>
          <InputGroup>
            <InputField
              placeholder="Recipient name"
              type={InputType.Text}
              onChange={setName}
              value={name}
              isLong={true}
              isError={nameError}
              disabled={!isEditing}
            />
            {isEditing ? (
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => {
                  setName(initName(giftCard))
                  setMessage(giftCard.message)
                  setEditReason('')
                  setNameError(false)
                  setReasonError(false)
                  setIsEditing(false)
                }}
              >
                Cancel
              </Button>
            ) : (
              <EditIcon onClick={(): void => setIsEditing(true)} />
            )}
          </InputGroup>
        </StyledCell>
      </tr>
      <tr>
        <th style={{ paddingTop: '14px' }}>Message</th>
        <StyledCell>
          <StyledTextArea
            placeholder="Message"
            onChange={setMessage}
            value={message}
            disabled={!isEditing}
          />
        </StyledCell>
      </tr>
      {isEditing && (
        <tr>
          <th aria-label="Reason">&nbsp;</th>
          <StyledCell>
            <InputGroup>
              <InputField
                placeholder="Reason for change"
                type={InputType.Text}
                value={editReason}
                onChange={setEditReason}
                isLong={true}
                isError={reasonError}
              />
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                onClick={handleChangeDetails}
              >
                Save
              </AsyncButton>
            </InputGroup>
          </StyledCell>
        </tr>
      )}
    </>
  )
}
export default EditNameAndMessage
