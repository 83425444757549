import EmailValidator from 'email-validator'
import { IUserResponse } from '../../types/API'

export const userEmailTest = (
  user?: Partial<IUserResponse>,
): string | false => {
  const { emailAddress } = user || {}
  if (!emailAddress) return 'User email required'
  if (emailAddress && !EmailValidator.validate(emailAddress)) {
    return 'user email invalid'
  }
  return false
}

export const userFirstNameTest = (
  user?: Partial<IUserResponse>,
): string | false => {
  if (!user?.firstName) return 'User first name required'
  return false
}

export const userLastNameTest = (
  user?: Partial<IUserResponse>,
): string | false => {
  if (!user?.lastName) return 'User last name required'
  return false
}

export const userRoleTest = (user?: Partial<IUserResponse>): string | false => {
  if (!user?.role) return 'User role required'
  return false
}

export const getUserErrors = (user?: Partial<IUserResponse>): string[] => {
  const errorArray: string[] = []

  const add = (
    errorTest: (user?: Partial<IUserResponse>) => string | false,
  ): void => {
    if (!user) errorArray.push('Can\'t find user')

    const error = errorTest(user)
    if (error) errorArray.push(error)
  }

  add(userEmailTest)
  add(userFirstNameTest)
  add(userLastNameTest)
  add(userRoleTest)

  return errorArray
}

export const isUserValid = (user?: Partial<IUserResponse>): boolean => {
  if (getUserErrors(user).length) return false
  return true
}
