import React, { ReactElement, useEffect } from 'react'
import {
  getResizedImage,
  ImageType,
  ITEM_HEADER_IMAGE_DIMENSIONS,
  UploadImageField,
} from '@one-tree/library'
import { useOrganisation } from '../../../../context/OrganisationProvider'
import { IGiftItemPostBody } from '../../../../types/GiftItemTypes'
import { uploadImage } from '../../../../helpers/APIHelper'

export interface IItemBannerProps {
  label: string
  previewUrl: string | undefined
  changeItem: (changes: IGiftItemPostBody) => void
  changePreview: (newUrl: { headerImageUrl: string }) => void
}

function ItemBanner(props: IItemBannerProps): ReactElement {
  const {
    label, previewUrl, changeItem, changePreview,
  } = props
  const { organisation } = useOrganisation()

  useEffect(() => {
    // if no image is already set as the banner, use the organisation banner by default
    if (!previewUrl && organisation) {
      changePreview({ headerImageUrl: organisation.headerImageUrl })
    }
  }, [])

  return (
    <UploadImageField
      label={label}
      subtitle="If no image is provided, your default organisation header is used. This can be changed in the 'Voucher template' section."
      imageType={ImageType.GiftItemHeader}
      onSuccess={(response): void => {
        changeItem({
          headerImageFileId: response.id,
        })
        changePreview({ headerImageUrl: response.url })
      }}
      previewUrl={
        previewUrl
          ? getResizedImage(previewUrl, ITEM_HEADER_IMAGE_DIMENSIONS)
          : ''
      }
      cropPixelAspect={ITEM_HEADER_IMAGE_DIMENSIONS}
      upload={uploadImage}
    />
  )
}
export default ItemBanner
