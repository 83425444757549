import React, { ReactElement } from 'react'
import styled from 'styled-components'
import Card, { CardSize } from '../../../components/page/Card'
import CardContent from '../../../components/page/CardContent'
import CardHeader from '../../../components/page/CardHeader'
import DataDownload from './DataDownload'
import MailingList from './MailingList'

const StyledCard = styled(Card)`
  /* to make room for the date drop downs */
  margin-bottom: 200px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  gap: 30px;
`
function Downloads(): ReactElement {
  return (
    <StyledCard cardSize={CardSize.Full}>
      <CardHeader title="Downloads" />
      <CardContent>
        <Grid>
          <MailingList />
          <DataDownload />
        </Grid>
      </CardContent>
    </StyledCard>
  )
}
export default Downloads
