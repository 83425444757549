import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { FiEdit } from 'react-icons/fi'
import { colors } from '@one-tree/library'

const StyledEditIcon = styled(FiEdit)`
  width: 16px;
  height: 16px;
  margin-top: 4px;
  cursor: pointer;
  color: ${colors.blue};
  transition: color 0.2s;
  :hover {
    color: ${colors.lightBlue};
  }
`

function EditIcon({ onClick }: { onClick: () => void }): ReactElement {
  return <StyledEditIcon onClick={onClick} />
}
export default EditIcon
