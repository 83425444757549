import { GiftCardStatus } from '@one-tree/library'
import React, { ReactElement } from 'react'
import GiftCardStatusBox, {
  StatusBoxSize,
} from '../../../../components/GiftCardStatusBox'

function StatusRow({ status }: { status: GiftCardStatus }): ReactElement {
  return (
    <tr>
      <th>Status</th>
      <td aria-label="Status">
        <GiftCardStatusBox status={status} size={StatusBoxSize.Inline} />
      </td>
    </tr>
  )
}
export default StatusRow
