import React, { ReactElement, useState } from 'react'
import {
  IColumn, IOrganisationResponse, Order, Table, Button, ButtonStyle, Modal, trueOr0, CurrencyCode,
} from '@one-tree/library'
import styled from 'styled-components'
import Page from '../../hoc/Page'
import { transformDate, TransformDateFormat } from '../../helpers/DateHelper'
import { licenseInvoices } from '../../helpers/APIHelper'
import { RoutePath } from '../../types/Routes'
import { formatCurrency } from '../../helpers/CurrencyHelper'
import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import { useOrganisation } from '../../context/OrganisationProvider'
import { IInvoiceResponse } from '../../types/API'
import CardHeader from '../../components/page/CardHeader'
import { capitalize } from '../../helpers/DataTransformer'

const ModalContent = styled.div`
  h3 {
    margin: 0 0 10px;
  }
  table {
    margin-bottom: 10px;
    td {
      padding: 0 8px 2px 0;
    }
  }
  padding-bottom: 20px;
  min-width: 240px;
`

const columns = (organisation: IOrganisationResponse | null): IColumn<IInvoiceResponse>[] => [
  {
    accessor: 'id',
    header: 'Order ID',
  },
  {
    accessor: 'date',
    header: 'Date issued',
    display: ({ date }) => (transformDate(date, TransformDateFormat.NumbersAndSlashes)),
  },
  {
    accessor: 'dueDate',
    header: 'Due date',
    display: ({ dueDate }) => (dueDate
      ? (transformDate(dueDate, TransformDateFormat.NumbersAndSlashes)) : '-'),
  },
  {
    accessor: 'paidDate',
    header: 'Paid date',
    display: ({ paidDate }) => (paidDate
      ? transformDate(paidDate, TransformDateFormat.LongWithoutTime) : '-'),
  },
  {
    accessor: 'total',
    header: 'Total',
    display: ({ total }) => (total && organisation
      ? formatCurrency(total, organisation.currencyCode)
      : '-'),
  },
  {
    accessor: 'status',
    header: 'Status',
    display: ({ status }) => (capitalize(status)),
  },
]

export default function LicenseInvoices(): ReactElement {
  const { organisation } = useOrganisation()
  const [modalOpen, setModalOpen] = useState(false)
  const closeModal = (): void => setModalOpen(false)

  const {
    minimumCommission, commissionFee, licenceFee, currencyCode,
  } = organisation || {}

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="Billing">
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => setModalOpen(true)}
          >View agreement
          </Button>
        </CardHeader>
        <CardContent>
          <Table
            fetch={licenseInvoices}
            columns={columns(organisation)}
            options={{
              orderBy: 'date',
              orderDirection: Order.Desc,
            }}
            search={true}
            restrictions={/^[0-9]*$/}
            rowLink={{ route: RoutePath.Billing, slug: 'id' }}
          />
        </CardContent>
      </Card>
      <Modal modalOpen={modalOpen} onModalClose={closeModal}>
        <ModalContent>
          <h3>Customer agreement</h3>
          <table>
            <tbody>
              {trueOr0(commissionFee) && (
                <tr>
                  <td>Commission rate:</td>
                  <td>{commissionFee}%</td>
                </tr>
              )}
              {(minimumCommission || minimumCommission === 0) && (
                <tr>
                  <td>Minimum commission:</td>
                  <td>{formatCurrency(minimumCommission, currencyCode || CurrencyCode.GBP)}</td>
                </tr>
              )}
              {(licenceFee || licenceFee === 0) && (
                <tr>
                  <td>Monthly licence fee:</td>
                  <td>{formatCurrency(licenceFee, currencyCode || CurrencyCode.GBP)}</td>
                </tr>
              )}
            </tbody>
          </table>
          <em>30-day rolling contract</em>
        </ModalContent>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={closeModal}
        >
          Close
        </Button>
      </Modal>
    </Page>
  )
}
