import { colors, Loading, ZIndex } from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import defaultBackground from '../../assets/login-background.jpg'
import defaultForeground from '../../assets/login-foreground.png'
import { getSplash } from '../../helpers/APIHelper'
import { ISplash } from '../../types/API'

const Styles = styled.div`
  background: url(${({ theme: { background } }): string => background})
    no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 550px;
  }
`
const LoadingCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightGray};
  pointer-events: none;
  transition: opacity 0.5s;
  ${({ theme: { loading } }): string => (!loading ? 'opacity: 0' : '')};
  z-index: ${ZIndex.Cover};
`

function Splash(): ReactElement | null {
  const [splash, setSplash] = useState<ISplash>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    (async (): Promise<void> => {
      const res = await getSplash()

      setSplash({
        splashForegroundUrl:
          (res && res?.splashForegroundUrl) || defaultForeground,
        splashBackgroundUrl:
          (res && res?.splashBackgroundUrl) || defaultBackground,
        splashLink: (res && res?.splashLink) || 'mailto:support@one-tree.net',
      })

      // timeout helps keep the transition smooth
      setTimeout(() => {
        setLoading(false)
      }, 500)
    })()
  }, [])

  return (
    <>
      {splash && (
        <Styles theme={{ background: splash.splashBackgroundUrl }}>
          <a href={splash.splashLink} target="_blank" rel="noreferrer">
            <img src={splash.splashForegroundUrl} alt="foreground" />
          </a>
        </Styles>
      )}
      <LoadingCover theme={{ loading }}>
        <Loading />
      </LoadingCover>
    </>
  )
}
export default Splash
