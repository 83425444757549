import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { colors, theme } from '@one-tree/library'
import FormatCard, { IFormatCard } from './FormatCard'
import { OrganisationFormat } from '../../types/Types'

const StyledCard = styled(FormatCard)`
  font-size: ${theme.fontSize};
  opacity: 0.7;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.85;
  }
  .content {
    height: 100%;
    padding-bottom: 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    ul {
      list-style: none;
      padding: 0;
      li {
        margin: 12px 0;
      }
    }
  }
  a.contact {
    position: absolute;
    left: 0;
    right: 0;
    width: 130px;
    margin: auto;
    bottom: -16px;
    padding: 5px;
    border-radius: 5px;
    background-color: ${colors.white};
    border: 2px solid;
    color: ${colors.black};
    transition: all 0.4s;
    text-decoration: none;
  }
  a.contact.specialOffers {
    border-color: ${colors.specialOffers};
  }
  &:hover a.contact.specialOffers {
    background-color: ${colors.specialOffers};
    color: ${colors.white};
  }
  a.contact.giftVouchers {
    border-color: ${colors.giftVouchers};
  }
  &:hover a.contact.giftVouchers {
    background-color: ${colors.giftVouchers};
    color: ${colors.white};
  }
  a.contact.tickets {
    border-color: ${colors.tickets};
  }
  &:hover a.contact.tickets {
    background-color: ${colors.tickets};
    color: ${colors.white};
  }
`

const specialOffers = (
  <>
    <i>Run online promotions</i>
    <ul>
      <li>Sell time-limited offers</li>
      <li>Fill capacity</li>
      <li>Lock in revenue</li>
    </ul>
    <a
      target="_blank"
      className="info"
      href="https://www.one-tree.net/special-offers/"
      rel="noreferrer"
    >
      Find Out More
    </a>
    <a
      target="_blank"
      className="contact specialOffers"
      href="mailto:support@one-tree.net?subject=Special%Offers%20Enquiry&body=Please%20contact%20me%20with%20more%20information%20about%20how%20to%sell%20special%20offers."
      rel="noreferrer"
    >
      Contact Us
    </a>
  </>
)
const giftVouchers = (
  <>
    <i>Sell online 24/7</i>
    <ul>
      <li>Improved security</li>
      <li>Monetary &amp; experiences</li>
      <li>Track voucher life cycle</li>
    </ul>
    <a
      target="_blank"
      className="info"
      href="https://www.one-tree.net/gift-voucher-system/"
      rel="noreferrer"
    >
      Find Out More
    </a>
    <a
      target="_blank"
      className="contact giftVouchers"
      href="mailto:support@one-tree.net?subject=Gift%20Vouchers%20Enquiry&body=Please%20contact%20me%20with%20more%20information%20about%20how%20to%20automate%20gift%20voucher%20sales."
      rel="noreferrer"
    >
      Contact Us
    </a>
  </>
)
const tickets = (
  <>
    <i>Stylish event tickets</i>
    <ul>
      <li>Sell from your website</li>
      <li>Countdown ticker</li>
      <li>Add attendee questions</li>
    </ul>
    <a
      target="_blank"
      className="info"
      href="https://www.one-tree.net/selling-tickets-online/"
      rel="noreferrer"
    >
      Find Out More
    </a>
    <a
      target="_blank"
      className="contact tickets"
      href="mailto:support@one-tree.net?subject=Tickets%20Enquiry&body=Please%20contact%20me%20with%20more%20information%20about%20selling%20event%20tickets%20online."
      rel="noreferrer"
    >
      Contact Us
    </a>
  </>
)

function CardPlaceholder(props: IFormatCard): ReactElement {
  const { icon, title, placeholder } = props

  const getPlaceholderItem = (): ReactElement => {
    switch (placeholder) {
    case OrganisationFormat.SpecialOffers:
      return specialOffers
    case OrganisationFormat.Tickets:
      return tickets
    default:
      return giftVouchers
    }
  }

  return (
    <StyledCard icon={icon} title={title}>
      <div className="content">{getPlaceholderItem()}</div>
    </StyledCard>
  )
}
export default CardPlaceholder
