import React, { ReactElement, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from '../context/AuthProvider'
import { useOrganisation } from '../context/OrganisationProvider'
import { RoutePath } from '../types/Routes'

function Logout(): ReactElement {
  const { clearAuth } = useAuth()
  const { clearOrganisation } = useOrganisation()

  useEffect(() => {
    sessionStorage.clear()
    clearAuth()
    clearOrganisation()
  })

  return <Redirect to={RoutePath.Login} />
}

export default Logout
