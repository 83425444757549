import { colors, IGiftCardResponse } from '@one-tree/library'
import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { isGiftCardValid } from '../../../helpers/GiftCardHelper'
import useResponsive from '../../../helpers/isResponsive'

const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
  tbody tr {
    :first-child {
      td,
      th {
        padding-top: 30px;
      }
    }
    :last-child {
      td,
      th {
        padding-bottom: 30px;
      }
    }
  }
  tbody tr.card-status-title {
    :last-child {
      td,
      th {
        padding-bottom: 0;
      }
    }
  }
  td,
  th {
    vertical-align: top;
  }
  th {
    padding: 5px 9px 5px 30px;
    width: 0;
    text-align: right;
    border-right: 2px solid ${colors.gray};
    white-space: nowrap;
  }
  td {
    padding: 5px 30px 5px 9px;
  }
  .card-status {
    text-transform: uppercase;
    &.card-status-Valid {
      color: ${colors.green};
    }
    &.card-status-Invalid {
      color: ${colors.red};
      font-weight: bold;
    }
    &.card-status-Redeemed {
      color: ${colors.blue};
    }
  }
  .table-hidden tr:first-child {
    th,
    td {
      border-top: 2px solid ${colors.lightestGray};
    }
  }

  .card-status-date-expired,
  .card-status-date-expired input {
    color: ${colors.red};
    font-weight: bold;
  }
  ${(props): string => props.theme.isSmallDesktop && 'font-size: 0.9rem;'}
`

interface IResultTableProps {
  giftCard: IGiftCardResponse
  justRedeemed: boolean
  children: ReactNode
}

function ResultTable(props: IResultTableProps): ReactElement {
  const { children, giftCard, justRedeemed } = props

  const giftCardStatusText = (): string => {
    if (justRedeemed) return 'Redeemed'
    if (isGiftCardValid(giftCard.status)) return 'Valid'
    return 'Invalid'
  }

  return (
    <StyledTable theme={useResponsive()}>
      <tbody>
        <tr className="card-status-title">
          <th>
            <h2>This voucher is</h2>
          </th>
          <td>
            <h2 className={`card-status card-status-${giftCardStatusText()}`}>
              {giftCardStatusText()}
            </h2>
          </td>
        </tr>
      </tbody>
      {children}
    </StyledTable>
  )
}
export default ResultTable
