import React, { ReactElement, SVGProps } from 'react'
import styled from 'styled-components'
import { colors, IOrganisationResponse, theme } from '@one-tree/library'
import { PropsWithChildren } from '../../types/Types'
import Card from './FormatCard'

const StyledCard = styled(Card)`
  .card-organisation-list {
    width: 100%;
    ${theme.scrollbar}
    overflow-y: auto;
    margin-top: 10px;
    padding-bottom: 10px;
  }
  button {
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSize};
    color: ${colors.black};
    width: 100%;
    padding: 15px 0 15px 10px;
    cursor: pointer;
    border: none;
    background: none;
    transition: color 0.2s;
    :hover {
      color: ${colors.blue};
    }
  }
`

interface OrganisationListProps {
  icon: SVGProps<SVGElement>
  title: string
  organisations: IOrganisationResponse[]
  handleLogin: (organisation: IOrganisationResponse) => void
}
function OrganisationList(props: PropsWithChildren<OrganisationListProps>): ReactElement {
  const {
    icon, title, organisations, handleLogin,
  } = props

  return (
    <StyledCard icon={icon} title={title}>
      <div className="card-organisation-list">
        {organisations.map((organisation: IOrganisationResponse, index) => {
          const key = `${organisation.id}-${index}`
          return (
            <button
              key={key}
              type="button"
              onClick={(): void => handleLogin(organisation)}
            >
              {organisation.displayName}
            </button>
          )
        })}
      </div>
    </StyledCard>
  )
}

export default OrganisationList
