import { IGiftItemResponse, IOrganisationResponse, ISelectOption } from '@one-tree/library'
import { ICategoryResponse } from '../types/API'

export const sortCategories = (
  categories: ICategoryResponse[],
): ICategoryResponse[] => {
  function compare(a: ICategoryResponse, b: ICategoryResponse): number {
    if (a.order < b.order) return -1
    if (a.order > b.order) return 1
    return 0
  }
  // ensure everything is in correct order and make a shallow copy
  const sortedCategories = categories.slice().sort(compare)

  // add all the top-level categories to a new array
  const newArray: ICategoryResponse[] = []
  sortedCategories.forEach((category) => {
    if (!category.parent) newArray.push(category)
  })

  // then, if a category has a parent, find the parent, and splice this category underneath it
  // reverse() since we are inserting `parent + 1`
  sortedCategories.reverse().forEach((category) => {
    if (category.parent) {
      const indexOfParent = newArray.findIndex(
        (parentCategory: ICategoryResponse) => category.parent === parentCategory.id,
      )
      newArray.splice(indexOfParent + 1, 0, category)

      // and add 'isParent' flag to the parent
      if (newArray[indexOfParent]) newArray[indexOfParent].isParent = true
    }
  })

  return newArray
}

export const getCategoriesAsOptions = (
  categories: ICategoryResponse[],
): ISelectOption<number>[] => sortCategories(categories).map((category: ICategoryResponse) => {
  const thisCategoryParent = categories.find(
    (results: ICategoryResponse) => category.parent === results.id,
  )
  return {
    value: category.id,
    label:
      category.parent && thisCategoryParent
        ? `${thisCategoryParent.title} / ${category.title}`
        : category.title,
  }
})

export const getGiftVoucherCategories = (
  giftVoucher: IGiftItemResponse,
  categories: ICategoryResponse[],
): ISelectOption<number>[] => {
  if (giftVoucher.categoryIds) {
    return giftVoucher.categoryIds?.map(
      (id: number): ISelectOption<number> => ({
        value: id,
        label: categories.find(
          (results: ICategoryResponse) => id === results.id,
        )?.title,
      }),
    )
  }
  return []
}

export const getOrder = (
  sourceIndex: number,
  targetIndex: number,
  isIndented: boolean,
  categoriesAbove: ICategoryResponse[],
  allCategories: ICategoryResponse[],
): number => {
  // if user drags item out of screen, put item at end of outdented categories
  if (!allCategories[targetIndex]) {
    return allCategories.filter((category) => category.parent === 0).length
  }

  // if it's dropped in first position, put it first regardless of indent
  if (targetIndex === 0) {
    return 1
  }
  // if it's indented, drop below nearest parent
  if (isIndented) {
    return categoriesAbove.map((category) => category.parent).indexOf(0) + 1
  }
  // if it's outdented but dropped on an indented...
  if (!isIndented && allCategories[targetIndex].parent !== 0) {
    // ...put it below (1+) the nearest parent
    return (
      1
      + (categoriesAbove.find((category) => category.parent === 0)?.order || 0)
    )
  }
  // if none of the above special cases apply, get our order
  let { order } = allCategories[targetIndex]
  const isOutdenting = !isIndented && allCategories[sourceIndex].parent !== 0

  // outdenting adds our current item to the list, so when moving lower need to add 1
  if (isOutdenting && targetIndex > sourceIndex) order += 1

  return order
}

export const getParent = (
  targetIndex: number,
  isIndented: boolean,
  categoriesAbove: ICategoryResponse[],
  allCategories: ICategoryResponse[],
): number => {
  // if not at top, and are indented, and have a target...
  if (targetIndex !== 0 && isIndented && allCategories[targetIndex]) {
    // ...find nearest outdented and make our parent
    const parentId = categoriesAbove.find(
      (category) => category.parent === 0,
    )?.id
    return parentId || 0
  }
  return 0
}

// Only required for 'top-level', 'categoryImages enabled organisation' categories
export const requiresImage = (
  category: ICategoryResponse | null,
  organisation: IOrganisationResponse | null,
): boolean => (category
  && organisation
  && !category.parent
  && organisation.categoryImages
)
  || false
