import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Switch, Route, useLocation, useHistory,
} from 'react-router-dom'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { colors } from '@one-tree/library'
import { RoutePath } from '../../types/Routes'
import UserLogin from './UserLogin'
import RequestPassword from './RequestPassword'
import ChangePassword from './ChangePassword'
import ContactGroup from '../../components/page/ContactGroup'
import Splash from './Splash'
import { ILoginMessages } from '../../types/Types'
import { useOrganisation } from '../../context/OrganisationProvider'
import { useAuth } from '../../context/AuthProvider'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { tokenLogin } from '../../helpers/APIHelper'
import { IDecodedToken } from '../../types/API'
import useResponsive from '../../helpers/isResponsive'

const StyledContainer = styled.div`
  display: flex;

  .left-column {
    background-color: ${colors.white};
    color: ${colors.black};
    width: 25%;
    min-width: 256px;
    transition: width 600ms;
    display: grid;
    grid-template-rows: 2fr 1fr 1fr 1fr;
    height: 100vh;
    ${(props): string => props.theme.isTablet && 'width: 100%;'}

    .logo-group {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      svg {
        width: 80%;
        max-width: 300px;
        fill: ${colors.logo};
      }
    }
    .login-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .form {
        width: 80%;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        input {
          margin-bottom: 10px;
        }
        button {
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
    .message-group {
      .message {
        background: ${colors.red};
        color: ${colors.white};
        padding: 10px 5px;
        text-align: center;
        text-wrap: balance;
      }
      .message__error {
        background: ${colors.red};
      }
      .message__success {
        background: ${colors.green};
      }
    }

    transition: opacity 0.2s ease-out;
    &.login-fade-out {
      opacity: 0;
    }
  }

  .right-column {
    width: 75%;
    height: 100vh;
    ${(props): string => props.theme.isTablet && 'display: none;'}
  }

  .login-animated-swipe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: ${colors.white};
    width: 0%;
    transition: width 0.7s;
    &.login-fade-out {
      width: 100%;
    }
  }
`

function Login(): ReactElement | null {
  const location = useLocation()
  const { selectOrganisation } = useOrganisation()
  const history = useHistory()

  const initMessages: ILoginMessages = {
    success: '',
    error: '',
    email: '',
    password: '',
    passwordRepeat: '',
  }
  const [messages, setMessages] = useState(initMessages)
  const { clearAuth } = useAuth()
  const { clearOrganisation } = useOrganisation()

  const handleMessages = (value: ILoginMessages | undefined): void => {
    if (!value) {
      setMessages(initMessages)
    } else {
      setMessages(value)
    }
  }

  const renderError = (): ReactElement => (
    <div className="message message__error" data-testid="message">
      <div>{messages.error}</div>
      <div>{messages.email}</div>
      <div>{messages.password}</div>
      <div>{messages.passwordRepeat}</div>
    </div>
  )

  const [animating, setAnimating] = useState(false)
  const loginAnimation = async (): Promise<void> => new Promise((resolve) => {
    setAnimating(true)
    setTimeout(resolve, 1000)
  })

  useEffect(() => {
    (async (): Promise<void> => {
      // Logout the user if we're visiting the login page
      sessionStorage.clear()
      clearAuth()
      clearOrganisation()

      const urlParams: URLSearchParams = new URLSearchParams(location.search)
      const tokenParam = urlParams.get('token')

      // User is masquerading so log them in now
      if (tokenParam) {
        const loginResult = await tokenLogin({ token: tokenParam })

        if (loginResult) {
          const decodedToken: IDecodedToken = jwt_decode(loginResult.token)
          // Organisation ID here is a string rather than a number
          const result = await selectOrganisation(Number(decodedToken.id), {
            token: loginResult.token,
            refreshToken: loginResult.refreshToken,
          })

          if (result) {
            const voucherId = urlParams.get('voucherId')

            if (voucherId) {
              history.push(`${RoutePath.VoucherDetail}/${voucherId}`)
            } else {
              history.push(RoutePath.Home, { state: 'animate' })
            }
          }
        }
      }
    })()
  }, [])

  const responsive = useResponsive()

  return (
    <StyledContainer theme={responsive}>
      <div
        className={`login-animated-swipe${animating ? ' login-fade-out' : ''}`}
      />
      <div className={`left-column${animating ? ' login-fade-out' : ''}`}>
        <div className="logo-group">
          <Logo />
        </div>
        <div className="login-group">
          <Switch>
            <Route
              path={RoutePath.RequestPassword}
              render={(): ReactElement => (
                <RequestPassword
                  sendMessages={handleMessages}
                  receiveMessages={messages}
                />
              )}
            />
            <Route
              path={RoutePath.ChangePassword}
              render={(): ReactElement => (
                <ChangePassword
                  sendMessages={handleMessages}
                  receiveMessages={messages}
                />
              )}
            />
            <Route
              path={RoutePath.Login}
              render={(): ReactElement => (
                <UserLogin
                  sendMessages={handleMessages}
                  receiveMessages={messages}
                  loginAnimation={loginAnimation}
                />
              )}
            />
          </Switch>
        </div>
        <div className="message-group">
          {(messages.error
            || messages.email
            || messages.password
            || messages.passwordRepeat)
            && renderError()}
          {messages.success && (
            <div className="message message__success" data-testid="message">
              {messages.success}
            </div>
          )}
        </div>
        <ContactGroup />
      </div>
      <div className={`right-column${animating ? ' login-fade-out' : ''}`}>
        <Splash />
      </div>
    </StyledContainer>
  )
}

export default Login
