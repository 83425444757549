import React, { ReactElement, useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import styled from 'styled-components'
import { CSVLink } from 'react-csv'
import {
  Loading,
  Button,
  CurrencyCode,
  IItemisedReportResponse,
  ReportItemisation,
  ReportMetric,
  ReportMetricType,
  ReportingWidget,
} from '@one-tree/library'
import { capitalize, limitChars } from '../../../helpers/DataTransformer'
import {
  getItemisationColor,
  getReducedItemisationData,
  getItemisationCsv,
  getItemiseName,
} from './BreakdownHelper'
import { renderTotal } from '../ReportingHelper'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { voucherWord } from '../../../helpers/FormatHelper'
import { OrganisationFormat } from '../../../types/Types'
import logo from '../../../assets/watermark.png'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  svg {
    width: 300px;
    height: 300px;
  }
  table {
    white-space: nowrap;
    text-align: left;
    .item-label {
      text-align: right;
      font-weight: bold;
    }
    th,
    td {
      padding: 0 10px 20px;
    }
  }
`
interface IItemisationProps {
  currency: CurrencyCode
  loading: boolean
  data: IItemisedReportResponse | undefined
  itemiseBy: ReportItemisation
  metric: ReportMetric
  metricType: ReportMetricType
}
function Itemisation(props: IItemisationProps): ReactElement {
  const {
    currency, loading, data, itemiseBy, metric, metricType,
  } = props
  const [hovered, setHovered] = useState<number>()
  const { organisation } = useOrganisation()

  const itemiseByWording = organisation?.format !== OrganisationFormat.Tickets
    ? `- by ${getItemiseName(itemiseBy)}`
    : ''

  const title = `${capitalize(
    voucherWord(organisation),
  )} ${metric} by ${metricType} ${itemiseByWording}`

  const reducedData = data ? getReducedItemisationData(data.itemisedData) : []

  const pieChartData = reducedData.map((item, index) => {
    const { label, total } = item
    return {
      title: label,
      value: total,
      color: getItemisationColor(index, hovered),
    }
  })

  reducedData.push({
    label: 'Total',
    total: reducedData.reduce(
      (runningTotal, current) => runningTotal + current.total,
      0,
    ),
  })

  const renderData = data
    && reducedData.map((item, index) => {
      const { label, total } = item

      const percentage = Math.round((total / data.total) * 100) || 0
      const color = getItemisationColor(index, hovered, reducedData.length)

      const key = `${label + index}`

      return (
        <tr style={{ color }} key={key}>
          <td className="item-label" title={label}>
            {limitChars(label, 40, true)}
          </td>
          <td>{renderTotal(total, metricType, currency, 0)}</td>
          <td>{`${percentage}%`}</td>
        </tr>
      )
    })

  const controls = data && (
    <CSVLink
      filename={`${title}.csv`}
      data={getItemisationCsv(title, data, metricType)}
      target="_blank"
    >
      <Button>Download data</Button>
    </CSVLink>
  )

  return (
    <ReportingWidget
      title={title}
      controls={controls}
      downloadPng="reporting-breakdown"
      logo={logo}
      style={{ minWidth: '650px' }}
    >
      {!data || loading ? (
        <Loading fullPage={true} />
      ) : (
        <Container>
          <table>
            <tbody>
              <tr>
                <th aria-label="Metrics">&nbsp;</th>
                <th>{capitalize(metricType)}</th>
                <th>% {capitalize(metricType)}</th>
              </tr>
              {renderData}
            </tbody>
          </table>
          <PieChart
            data={pieChartData}
            startAngle={-90}
            lengthAngle={-360}
            segmentsStyle={{
              transition: '0.4s',
              transitionDelay: '0.1s',
              cursor: 'pointer',
            }}
            segmentsShift={(index): number => {
              if (index === hovered) return 5
              return 0
            }}
            radius={45}
            onMouseOver={(e, index): void => setHovered(index)}
            onMouseOut={(): void => setHovered(undefined)}
          />
        </Container>
      )}
    </ReportingWidget>
  )
}
export default Itemisation
