import axios from 'axios'
import {
  api,
  GiftCardStatus,
  GiftItemStatus,
  IGiftCardResponse,
  IGiftItemResponse,
  ImageType,
  IOrganisationResponse,
  ITableData,
  ITableRequest,
  ITicket,
  Status,
} from '@one-tree/library'
import {
  Resource,
  IUserResponse,
  IUserPostBody,
  IInvoiceResponse,
  IImageUploadResponse,
  IGiftCardPatchBody,
  IOrganisationPatchBody,
  ICategoryResponse,
  ICategoryPostBody,
  IContactResponse,
  IContactPatchBody,
  ISplash,
} from '../types/API'
import { stripEmptyFromObject } from './DataTransformer'
import {
  ITokenPair,
  IPasswordRequest,
  PortalTicketQuestion,
  SubmitTicketQuestion,
  TicketPrice,
} from '../types/Types'

const {
  Authenticate,
  Categories,
  ChangePassword,
  Contacts,
  GiftCardCode,
  GiftCards,
  GiftCardsExport,
  LicenseInvoices,
  OrderInvoices,
  OrderInvoicesExport,
  Organisations,
  Questions,
  RefreshToken,
  RequestPassword,
  Splash,
  TicketItems,
  TokenLogin,
  Upload,
  Users,
} = Resource

const { Created, OK } = Status

export const authenticate = async (data: {
  email: string
  password: string
}): Promise<ITokenPair | false> => {
  const request = axios.post(Authenticate, data)
  // `true` silences the API's error notification - login errors are handled inline.
  return api(request, Created, true)
}

export const tokenLogin = async (data: {
  token: string
}): Promise<ITokenPair | false> => {
  const request = axios.post(TokenLogin, data)
  return api(request, Created)
}

export const requestPassword = async (data: {
  email: string
}): Promise<IPasswordRequest | false> => {
  const request = axios.post(RequestPassword, data)
  return api(request, OK)
}

export const changePassword = async (data: {
  hash: string
  password: string
  confirmation: string
}): Promise<{ message: string } | false> => {
  const request = axios.post(ChangePassword, data)
  return api(request, OK)
}

export const listOrganisations = async (): Promise<
  IOrganisationResponse[] | false
> => {
  const request = axios.get(Organisations)
  const res = await api(request, OK)
  // Returns paginated response, but currently returns all anyway, so just return res.data
  return res.data
}

export const getOrgToken = async (data: {
  orgId: number
}): Promise<ITokenPair | false> => {
  const request = axios.post(`${Organisations}/${data.orgId}/token`)
  return api(request, Created)
}

export const getRefreshToken = async (data: {
  refreshToken: string
}): Promise<ITokenPair | false> => {
  const request = axios.post(RefreshToken, data)
  return api(request, Created)
}

export const getOrgAccessToken = async (): Promise<string | false> => {
  const request = axios.post(`${Organisations}/access-token`)
  const { token } = await api(request, Created)
  return token
}

export const allUsers = async (
  data: ITableRequest<IUserResponse>,
): Promise<ITableData<IUserResponse>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(Users, { params })
  return api(request, OK)
}

export const orderInvoices = async (
  data: ITableRequest<IInvoiceResponse>,
): Promise<ITableData<IInvoiceResponse>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(OrderInvoices, { params })
  return api(request, OK)
}

export const licenseInvoices = async (
  data: ITableRequest<IInvoiceResponse>,
): Promise<ITableData<IInvoiceResponse>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(LicenseInvoices, { params })
  return api(request, OK)
}

export const checkUserExists = async (data: {
  emailAddress: string
  organisationId: number
}): Promise<IUserResponse | false> => {
  const request = axios.post(`${Users}/check-user-exists`, data)
  return api(request, OK)
}

export const getUser = async (data: {
  userId: number
}): Promise<IUserResponse | false> => {
  const request = axios.get(`${Users}/${data.userId}`)
  return api(request, OK)
}

export const deleteUser = async (data: {
  userId: number
}): Promise<boolean> => {
  const request = axios.delete(`${Users}/${data.userId}`)
  return api(request, OK)
}

export const updateUser = async (data: IUserPostBody): Promise<boolean> => {
  const request = axios.patch(`${Users}/${data.userId}`, data)
  return api(request, OK)
}

export const createUser = async (data: IUserPostBody): Promise<boolean> => {
  const request = axios.post(Users, data)
  return api(request, Created)
}

export const addUserToOrg = async (data: {
  userId: number
}): Promise<IUserResponse | false> => {
  const request = axios.post(`${Users}/${data.userId}/add-to-organisation`)
  return api(request, OK)
}

export const searchGiftCards = async (
  data: ITableRequest<IGiftCardResponse>,
): Promise<ITableData<IGiftCardResponse>> => {
  const params = stripEmptyFromObject(data)
  const request = axios.get(GiftCards, { params })
  return api(request, OK)
}

export const searchGiftCardCode = async (data: {
  code: string
}): Promise<IGiftCardResponse | false> => {
  const request = axios.get(`${GiftCardCode}/${data.code}`)
  return api(request, OK)
}

export const patchGiftCard = async (data: {
  cardId: string
  patchOptions: IGiftCardPatchBody
}): Promise<IGiftCardResponse | false> => {
  const request = axios.patch(`${GiftCards}/${data.cardId}`, data.patchOptions)
  return api(request, OK)
}

export const redeemGiftCard = async (data: {
  cardId: string
}): Promise<IGiftCardResponse | false> => {
  const request = axios.post(`${GiftCards}/${data.cardId}/redeem`, {
    status: GiftCardStatus.Redeemed,
  })
  return api(request, OK)
}

export const extendGiftCard = async (data: {
  cardId: string
  reason: string
  expiryDate: number
}): Promise<IGiftCardResponse | false> => {
  const { cardId, reason, expiryDate } = data
  const request = axios.post(`${GiftCards}/${cardId}/extend`, {
    reason,
    expiryDate,
  })
  return api(request, OK)
}

export const exportGiftCards = async (data: {
  search?: string
  orderBy?: string
  orderDirection?: string
  fromDate?: number
  toDate?: number
  status?: GiftCardStatus
}): Promise<void | false> => {
  const request = axios.get(GiftCardsExport, { params: data })
  return api(request, OK)
}

export const get = async <T>(data: {
  resource: Resource
  urlPath?: string[]
}): Promise<T | false> => {
  const { resource, urlPath } = data
  const path = urlPath ? `${resource}/${urlPath.join('/')}` : resource
  const request = axios.get(path)
  return api(request, OK)
}

export const post = async <T>(data: {
  resource: Resource
  urlPath: string[]
}): Promise<T | false> => {
  const { resource, urlPath } = data
  const path = `${resource}/${urlPath.join('/')}`
  const request = axios.post(path)
  return api(request, OK)
}

export const uploadImage = async (data: {
  image: Blob
  imageType: ImageType
}): Promise<IImageUploadResponse | false> => {
  const formData = new FormData()
  formData.append('upload', data.image)
  formData.append('imageType', data.imageType)

  const request = axios.post(Upload, formData)
  return api(request, Created)
}

export const patchCategory = async (data: {
  categoryId: number
  patchOptions: ICategoryPostBody
}): Promise<ICategoryResponse | false> => {
  const request = axios.patch(
    `${Categories}/${data.categoryId}`,
    data.patchOptions,
  )
  return api(request, OK)
}

export const createCategory = async (data: {
  category: ICategoryPostBody
}): Promise<ICategoryResponse | false> => {
  const request = axios.post(Categories, data.category)
  return api(request, Created)
}

export const deleteCategory = async (data: {
  categoryId: number
}): Promise<boolean> => {
  const request = axios.delete(`${Categories}/${data.categoryId}`)
  return api(request, OK)
}

export const patchOrganisation = async (data: {
  organisationId: number
  patchOptions: IOrganisationPatchBody
}): Promise<IOrganisationResponse | false> => {
  const request = axios.patch(
    `${Organisations}/${data.organisationId}`,
    data.patchOptions,
  )
  return api(request, OK)
}

export const exportOrderInvoices = async (data: {
  fromDate?: number
  toDate?: number
  search?: string
}): Promise<void | false> => {
  const request = axios.get(OrderInvoicesExport, { params: data })
  return api(request, OK)
}

export const getContacts = async (): Promise<IContactResponse | false> => {
  const request = axios.get(Contacts)
  return api(request, OK)
}

export const patchContact = async (data: {
  contactId: number
  patchOptions: IContactPatchBody
}): Promise<IContactResponse | false> => {
  const request = axios.patch(
    `${Contacts}/${data.contactId}`,
    data.patchOptions,
  )
  return api(request, OK)
}

export const getSplash = async (): Promise<ISplash | false> => {
  const request = axios.get(Splash)
  return api(request, OK)
}

export const addQuestion = async (data: {
  orgId: number
  questions: Partial<SubmitTicketQuestion>[]
}): Promise<PortalTicketQuestion[] | false> => {
  const request = axios.post(Questions, {
    questions: data.questions,
  })
  return api(request, OK)
}

export const deleteQuestion = async (data: {
  orgId: number
  questionId: number
}): Promise<string | false> => {
  const request = axios.delete(`${Questions}/${data.questionId}`)
  return api(request, OK)
}

export const addTicketPrice = async (data: {
  ticketId: number
  price: Partial<TicketPrice>
}): Promise<ITicket | false> => {
  const request = axios.post(
    `${TicketItems}/${data.ticketId}/prices`,
    data.price,
  )
  return api(request, Created)
}

export const archiveTicketPrice = async (data: {
  id: number
}): Promise<IGiftItemResponse | false> => {
  const request = axios.delete(`${TicketItems}/${data.id}`)
  return api(request, OK)
}

export const patchTicketPriceStatus = async (data: {
  id: number
  status: GiftItemStatus
}): Promise<IGiftItemResponse | false> => {
  const request = axios.patch(`${TicketItems}/${data.id}`, {
    status: data.status,
  })
  return api(request, OK)
}
