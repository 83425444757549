import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  InputField,
  InputType,
  Checkbox,
  ISelectOption,
  SelectField,
  CATEGORY_IMAGE_DIMENSIONS,
  getCategoryImage,
  IOrganisationResponse,
  UploadImageField,
  ImageType,
} from '@one-tree/library'
import { get, uploadImage } from '../../../helpers/APIHelper'
import { getCategoriesAsOptions } from '../../../helpers/CategoryHelper'
import {
  ICategoryPostBody,
  ICategoryResponse,
  IImageUploadResponse,
  Resource,
} from '../../../types/API'
import CategoryPreview from './CategoryPreview'

const Flex = styled.div`
  display: flex;
  section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .category-design {
    width: 100%;
  }
  .category-preview {
    width: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 15px;
  }
`

interface ICategoryDesignProps {
  category: ICategoryResponse | null
  changeCategory: (changes: Partial<ICategoryResponse>) => void
  id: string
  organisation: IOrganisationResponse | null
}

function CategoryDesign(props: ICategoryDesignProps): ReactElement {
  const { category, changeCategory, organisation } = props
  const [categoryOptions, setCategoryOptions] = useState<ISelectOption<number>[]>()
  const [isParent, setIsParent] = useState(false)

  useEffect(() => {
    (async (): Promise<void> => {
      let res = await get<ICategoryResponse[]>({
        resource: Resource.Categories,
      })
      if (res) {
        // if we are editing an existing category
        if (category) {
          // find out if this category has children (then cannot be sub-category)
          setIsParent(Boolean(res.find((item) => item.parent === category.id)))
          // remove this category from the list of selectable categories
          res = res.filter((item) => item.title !== category.title)
        }
        // filter to only top-level categories to choose from
        const topLevelOnly = res.filter((item) => !item.parent)
        setCategoryOptions(getCategoriesAsOptions(topLevelOnly))
      }
    })()
  }, [])

  const showUploadImage = !category?.parent && organisation?.categoryImages

  const handleChange = <T extends unknown>(
    value: T,
    key: keyof ICategoryPostBody,
  ): void => {
    changeCategory({
      ...(category || {}),
      [key]: value,
    })
  }

  const handleImageChange = (response: IImageUploadResponse): void => {
    changeCategory({
      ...(category || {}),
      imageFileId: response.id,
      imageUrl: response.url,
    })
  }

  const handleParentChange = (value: boolean): void => {
    if (categoryOptions) {
      changeCategory({
        ...(category || {}),
        parent: value ? categoryOptions[0].value : 0,
      })
    }
  }

  return (
    <Flex>
      <section className="category-design">
        <InputField
          type={InputType.Text}
          label="Name"
          placeholder="Add a name for the category..."
          value={category?.title || ''}
          onChange={(value): void => handleChange(value, 'title')}
          maxChars={80}
          hideMaxChars={true}
        />
        {!isParent && categoryOptions && Boolean(categoryOptions.length) && (
          <Checkbox
            label="Sub-category?"
            onChange={handleParentChange}
            value={Boolean(category?.parent)}
          />
        )}
        {Boolean(category?.parent) && (
          <SelectField
            label="Select parent"
            options={categoryOptions || []}
            onChange={(option): void => {
              if (option) {
                handleChange(option.value, 'parent')
              }
            }}
            value={
              categoryOptions?.filter(
                (item) => item.value === category?.parent,
              )[0]
            }
          />
        )}
        {showUploadImage && (
          <UploadImageField
            label="Category image"
            imageType={ImageType.GiftCategoryImage}
            onSuccess={handleImageChange}
            cropPixelAspect={CATEGORY_IMAGE_DIMENSIONS}
            previewUrl={
              category?.imageUrl && getCategoryImage(category.imageUrl)
            }
            subtitle="This image appears on your website to display the categories. For best results, please upload a jpg measuring 300 x 300 px. A larger image may be uploaded (max 2048 x 2048 px) and edited here. Crop and zoom using the slider to change the size of the image. Images smaller than 300 x 300 px will not upload."
            upload={uploadImage}
          />
        )}
      </section>
      {showUploadImage
        && organisation
        && category
        && category.imageUrl
        && category.title
        && (
          <section className="category-preview">
            <CategoryPreview
              image={category.imageUrl}
              title={category.title}
              organisation={organisation}
            />
          </section>
        )}
    </Flex>
  )
}
export default CategoryDesign
