import {
  GiftItemStatus,
  IGiftItemResponse,
  IOrganisationResponse,
} from '@one-tree/library'
import { IGiftItemFilters, IGiftItemPostBody } from '../types/GiftItemTypes'
import { isGiftItemValid } from './GiftItemValidityHelper'

export const filterGiftItems = (
  input: IGiftItemResponse[],
  filters: IGiftItemFilters,
  organisation: IOrganisationResponse | null,
): IGiftItemResponse[] => {
  const { Archived } = GiftItemStatus

  return input.filter((item: IGiftItemResponse) => {
    const withStatus = item.status === filters.status
    const withType = item.type === filters.type
    const withCategory = item.categoryIds
      && filters.category
      && item.categoryIds.includes(filters.category)
    const withUnfinished = !isGiftItemValid(item, organisation)

    // don't show archived by default
    const toggleArchived = (filters.status !== Archived && item.status !== Archived)
      || (filters.status === Archived && item.status === Archived)

    // status and unfinished can't be set at the same time
    if (filters.status && filters.type && filters.category) {
      return withStatus && withType && withCategory && toggleArchived
    }
    if (filters.unfinished && filters.type && filters.category) {
      return withUnfinished && withType && withCategory && toggleArchived
    }

    if (filters.status && filters.type) {
      return withStatus && withType && toggleArchived
    }
    if (filters.status && filters.category) {
      return withStatus && withCategory && toggleArchived
    }

    if (filters.unfinished && filters.type) {
      return withUnfinished && withType && toggleArchived
    }
    if (filters.unfinished && filters.category) {
      return withUnfinished && withCategory && toggleArchived
    }

    if (filters.type && filters.category) {
      return withType && withCategory && toggleArchived
    }

    // if we are filtering on status only, toggleArchived is redundant
    if (filters.status) {
      return withStatus
    }
    if (filters.unfinished) {
      return withUnfinished && toggleArchived
    }
    if (filters.type) {
      return withType && toggleArchived
    }
    if (filters.category) {
      return withCategory && toggleArchived
    }

    return input && toggleArchived
  })
}

export const typeHandler = (data: IGiftItemResponse[] | false): string[] => {
  const typeSet = new Set<string>()
  if (data) {
    for (let i = 0; i < data.length; i += 1) {
      const { type } = data[i]
      if (type) typeSet.add(type)
    }
  }
  const typeArray = Array.from(typeSet)
  return typeArray.sort()
}

export const publishSwitchHandler = (
  publish: boolean,
  changeItem: (changes: IGiftItemPostBody) => void,
  setForcePublish: (how: boolean) => void,
): void => {
  changeItem({
    status: publish ? GiftItemStatus.Published : GiftItemStatus.Unpublished,
  })
  setForcePublish(publish)
}
