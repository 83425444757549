import React, { ReactElement, ReactNode, useState } from 'react'
// eslint-disable-next-line camelcase
import { useHistory } from 'react-router-dom'
import {
  alertError,
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  colors,
  FormattedDateTime,
  getRoleText,
  IColumn,
  InputField,
  InputType,
  Modal,
  Order,
  Table,
  validateEmail,
} from '@one-tree/library'
import styled from 'styled-components'
import {
  addUserToOrg,
  allUsers,
  checkUserExists,
} from '../../helpers/APIHelper'
import Page from '../../hoc/Page'
import { IUserResponse } from '../../types/API'
import Card, { CardSize } from '../../components/page/Card'
import CardHeader from '../../components/page/CardHeader'
import CardContent from '../../components/page/CardContent'
import { RoutePath } from '../../types/Routes'
import { useOrganisation } from '../../context/OrganisationProvider'

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
const Exists = styled.div`
  font-weight: bold;
  div {
    color: ${colors.blue};
    margin-bottom: 3px;
    overflow-wrap: break-word;
  }
`
export default function Users(): ReactElement {
  const history = useHistory()
  const [addUser, setAddUser] = useState(false)
  const [email, setEmail] = useState('')
  const [userExists, setUserExists] = useState<IUserResponse>()
  const { organisation } = useOrganisation()
  const [resetTable, setResetTable] = useState(false)

  const getDate = ({ lastLoginTimestamp }: IUserResponse): ReactNode => {
    if (!lastLoginTimestamp) return '-'
    return <FormattedDateTime timestamp={lastLoginTimestamp} />
  }

  const goEditUser = (user?: IUserResponse): void => {
    history.push(`${RoutePath.EditUser}${user ? `/${user.userId}` : ''}`, {
      email,
    })
  }

  const checkUser = async (): Promise<void> => {
    const invalidReason = validateEmail(email)
    if (invalidReason) {
      alertError(invalidReason)
      return
    }

    if (!organisation) return

    const res = await checkUserExists({
      emailAddress: email,
      organisationId: organisation.id,
    })

    setAddUser(false)

    if (res && res.userId) {
      setUserExists(res)
    } else {
      goEditUser()
    }
  }

  const getExistsText = (): ReactElement => {
    const text = userExists?.inOrganisation
      ? 'This user already exists in this organisation. Edit this user?'
      : 'This user already exists in another organisation. Add them to this organisation as well?'

    return (
      <>
        <div>{email}</div>
        {text}
      </>
    )
  }

  const handleClose = (): void => {
    setAddUser(false)
    setUserExists(undefined)
    setEmail('')
  }

  const handleUserExists = async (user?: IUserResponse): Promise<void> => {
    if (!user) return
    if (!userExists?.inOrganisation) {
      const res = await addUserToOrg({ userId: user.userId })
      if (res) {
        alertSuccess('User added to this organisation')
        handleClose()
        setResetTable((p) => !p)
        return
      }
    }
    goEditUser(user)
  }

  const columns: IColumn<IUserResponse>[] = [
    {
      accessor: 'userId',
      header: 'User ID',
    },
    {
      accessor: 'firstName',
      header: 'First',
      parent: 'Full name',
    },
    {
      accessor: 'lastName',
      header: 'Last',
      parent: 'Full name',
    },
    {
      accessor: 'role',
      header: 'Role',
      display: ({ role }): string => getRoleText(role),
      sorting: false,
    },
    {
      accessor: 'lastLoginTimestamp',
      header: 'Last login',
      display: getDate,
    },
    {
      accessor: 'emailAddress',
      header: 'Email',
    },
  ]

  return (
    <Page>
      <Card cardSize={CardSize.Full}>
        <CardHeader title="Manage users">
          <Button
            buttonStyle={ButtonStyle.Action}
            onClick={(): void => setAddUser(true)}
          >
            Add a new user
          </Button>
        </CardHeader>
        <CardContent>
          <p>Add or delete system users as required.</p>
          <br />
          <Table
            fetch={allUsers}
            columns={columns}
            options={{
              orderBy: 'userId',
              orderDirection: Order.Desc,
            }}
            rowLink={{
              route: RoutePath.EditUser,
              slug: 'userId',
            }}
            search={true}
            reset={resetTable}
          />
        </CardContent>
      </Card>
      <Modal
        modalOpen={addUser}
        onModalClose={handleClose}
        style={{ width: '270px' }}
      >
        <h3 style={{ marginTop: '5px' }}>Add a new user</h3>
        <InputField
          placeholder="Add an email address..."
          type={InputType.Email}
          value={email}
          onChange={setEmail}
          isLong={true}
        />
        <Buttons>
          <Button buttonStyle={ButtonStyle.Secondary} onClick={handleClose}>
            Cancel
          </Button>
          <AsyncButton onClick={checkUser} type="submit">
            Continue
          </AsyncButton>
        </Buttons>
      </Modal>
      <Modal
        modalOpen={Boolean(userExists)}
        onModalClose={(): void => setUserExists(undefined)}
        style={{ width: '350px' }}
      >
        <Exists>{getExistsText()}</Exists>
        <Buttons>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => setUserExists(undefined)}
          >
            Cancel
          </Button>
          <AsyncButton
            onClick={(): Promise<void> => handleUserExists(userExists)}
            type="submit"
          >
            Continue
          </AsyncButton>
        </Buttons>
      </Modal>
    </Page>
  )
}
