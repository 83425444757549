import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  Modal, Button, ButtonStyle,
} from '@one-tree/library'
import { StateAction } from '../types/Aliases'
import { Code } from '../helpers/FormatHelper'

const ModalContents = styled.div`
  width: 700px;
  h3 {
    margin: 0;
  }
  .embed-header {
    margin: 0;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
`
export interface IEmbedProps {
  embedUrl: string | undefined
  modalOpen: boolean
  setModalOpen: StateAction<boolean>
  customTitle?: string
  linkText?: ReactElement
}
function Embed({
  embedUrl,
  modalOpen,
  setModalOpen,
  customTitle,
  linkText,
}: IEmbedProps): ReactElement {
  return (
    <Modal modalOpen={modalOpen} onModalClose={(): void => setModalOpen(false)}>
      <ModalContents>
        <div className="embed-header">
          <h3>{customTitle || 'Embed this on your website'}</h3>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => setModalOpen(false)}
          >
            Close
          </Button>
        </div>
        <p>
          The content frame is served over SSL (using https) for security. The
          page you embed the iframe on should also have a security certificate.
        </p>
        <p>
          To embed the frame into your website, place the following snippet
          between the
          <strong>{' <body> '}</strong>
          tags of your page:
        </p>
        <Code>
          {`<script defer="defer" type="text/javascript" src="${embedUrl}"></script><div id="panel-gift-items"></div>`}
        </Code>
        <p>
          If you need to embed the iframe using a CMS and a WYSIWYG editor, be
          sure to paste the snippet as HTML. Only one content frame can be
          embedded on a single page.
        </p>
        {linkText}
      </ModalContents>
    </Modal>
  )
}
export default Embed
