import { SelectField } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useOrganisation } from '../../context/OrganisationProvider'
import {
  getAllOrganisationOptions,
  getOrganisationOption,
} from '../../helpers/OrganisationHelper'
import { RoutePath } from '../../types/Routes'
import LoadingCover from '../page/LoadingCover'

const StyledSelectField = styled(SelectField)`
  transition: opacity 0.2s;
  .select__control {
    height: 46px;
  }
  .select__menu {
    right: 0;
  }
  &.switch-loading {
    opacity: 0.7;
  }
  &.switch-disabled {
    .select__indicators {
      display: none;
    }
    pointer-events: none;
  }
`

function OrganisationSwitcher(): ReactElement {
  const {
    organisations,
    organisation: currentOrganisation,
    organisationLoading,
    setOrganisationLoading,
    selectOrganisation,
  } = useOrganisation()

  const history = useHistory()
  const location = useLocation()

  const switchHandler = (organisationId: number): void => {
    // This is set to `false` in Page.tsx when warnBeforeExit is used to avoid loading forever
    setOrganisationLoading(true)
    // Clear any data for this org, then reinstate the org list so we can switch to other orgs
    sessionStorage.clear()
    sessionStorage.setItem('organisations', JSON.stringify(organisations))
    // Switching must happen on `Home` to prevent bugs and user access issues
    if (location.pathname === RoutePath.Home) {
      selectOrganisation(organisationId)
    } else {
      history.push(RoutePath.Home, { switchToOrgId: organisationId })
    }
  }

  const isLoading = organisationLoading ? 'switch-loading' : ''
  const isDisabled = organisations.length < 2 ? 'switch-disabled' : ''

  return (
    <>
      {currentOrganisation && (
        <StyledSelectField
          className={isLoading + isDisabled}
          options={getAllOrganisationOptions(
            organisations,
            currentOrganisation,
            switchHandler,
          )}
          value={getOrganisationOption(currentOrganisation)}
          isLong={true}
          disabled={organisationLoading || organisations.length < 2}
          isSearchable={false}
        />
      )}
      <LoadingCover loading={organisationLoading} />
    </>
  )
}
export default OrganisationSwitcher
