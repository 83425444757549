import React, { ReactElement, useEffect, useState } from 'react'
import {
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  InputGroup,
  TextArea,
} from '@one-tree/library'
import styled from 'styled-components'
import { patchGiftCard } from '../../../../helpers/APIHelper'
import { IEditGiftCardProps } from '../../../../types/API'
import EditIcon from './EditIcon'

const StyledCell = styled.td`
  padding-left: 3px !important;
`

function EditNotes(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props

  const [notes, setNotes] = useState(giftCard.notes)
  const [isEditing, setIsEditing] = useState(false)
  const [error, setError] = useState(false)
  const handleChangeNotes = async (): Promise<void> => {
    setError(false)
    const result = await patchGiftCard({
      cardId: giftCard.id,
      patchOptions: {
        notes,
      },
    })
    if (result) {
      alertSuccess('Notes updated')
      setNotes(result.notes)
      setGiftCard(result)
      setIsEditing(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    setNotes(giftCard.notes)
  }, [giftCard.notes])

  return (
    <tr>
      <th>Notes</th>
      <StyledCell>
        <InputGroup>
          <TextArea
            placeholder="Add notes"
            onChange={setNotes}
            value={notes}
            isError={error}
            disabled={!isEditing}
          />
          {isEditing ? (
            <>
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => {
                  setError(false)
                  setIsEditing(false)
                  setNotes(giftCard.notes || '')
                }}
              >
                Cancel
              </Button>
              &nbsp;
              <AsyncButton
                buttonStyle={ButtonStyle.Action}
                onClick={handleChangeNotes}
              >
                Save
              </AsyncButton>
            </>
          ) : (<EditIcon onClick={(): void => setIsEditing(true)} />)}
        </InputGroup>
      </StyledCell>
    </tr>
  )
}
export default EditNotes
