import React, { ReactElement, useEffect, useState } from 'react'
import {
  alertSuccess, GiftCardStatus, ISelectOption, SelectField,
} from '@one-tree/library'
import GiftCardStatusBox, {
  StatusBoxSize,
} from '../../../../components/GiftCardStatusBox'
import { useOrganisation } from '../../../../context/OrganisationProvider'
import { patchGiftCard } from '../../../../helpers/APIHelper'
import { voucherWord } from '../../../../helpers/FormatHelper'
import { createStatusOptions } from '../../../../helpers/GiftCardHelper'
import { IEditGiftCardProps } from '../../../../types/API'
import EditableTableRow from './EditableTableRow'

function EditStatus(props: IEditGiftCardProps): ReactElement {
  const { giftCard, setGiftCard } = props
  const [status, setStatus] = useState(giftCard.status)
  const { organisation } = useOrganisation()

  useEffect(() => {
    setStatus(giftCard.status)
  }, [giftCard])

  const [changeStatusReason, setChangeStatusReason] = useState('')
  const [statusIsEditing, setStatusIsEditing] = useState(false)
  const handleChangeStatus = async (): Promise<void> => {
    const result = await patchGiftCard({
      cardId: giftCard.id,
      patchOptions: {
        status,
        reason: changeStatusReason,
      },
    })
    if (result) {
      alertSuccess('Status updated')
      setStatusIsEditing(false)
      setChangeStatusReason('')
      setGiftCard(result)
    }
  }

  // available options change depending on current voucher status
  const getOptions = (currentStatus: GiftCardStatus): ISelectOption[] => {
    switch (currentStatus) {
    case GiftCardStatus.Booked:
    case GiftCardStatus.Paid:
    case GiftCardStatus.Redeemed:
      return createStatusOptions([
        GiftCardStatus.Delivered,
        GiftCardStatus.Cancelled,
      ])
    case GiftCardStatus.Cancelled:
      return createStatusOptions([GiftCardStatus.Delivered])
    case GiftCardStatus.Delivered:
    default:
      return createStatusOptions([GiftCardStatus.Cancelled])
    }
  }

  const cancelMessage = `Are you sure you want to cancel this ${voucherWord(
    organisation,
  )}? This makes the ${voucherWord(
    organisation,
  )} invalid for use but does not issue a refund. To give a refund, please ask your accounts department to locate the transaction in your Internet Payment Gateway account (Stripe, PayPal or Trust Payments) and process the refund from there. The payment will be credited to the same card used for the initial purchase.`

  return (
    <EditableTableRow
      title="Status"
      isEditing={statusIsEditing}
      setIsEditing={setStatusIsEditing}
      editReason={changeStatusReason}
      setEditReason={setChangeStatusReason}
      saveAction={(): Promise<void> => handleChangeStatus()}
      cancelAction={(): void => {
        setChangeStatusReason('')
        setStatus(giftCard.status)
      }}
      showConfirmation={status === GiftCardStatus.Cancelled}
      confirmationMessage={cancelMessage}
    >
      <SelectField
        options={getOptions(status)}
        onChange={(option): void => {
          if (option) setStatus(option.value)
        }}
        value={{
          value: status,
          label: (
            <GiftCardStatusBox status={status} size={StatusBoxSize.Dropdown} />
          ),
        }}
        isLong={true}
      />
    </EditableTableRow>
  )
}
export default EditStatus
