import React, { ReactElement, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  alertError,
  Button,
  ButtonStyle,
  hasRole,
  Role,
  Animate,
} from '@one-tree/library'
import Page from '../../hoc/Page'
import Card, { CardSize } from '../../components/page/Card'
import UserSearch from './UserSearch'
import RedeemerSearch from './RedeemerSearch'
import { useOrganisation } from '../../context/OrganisationProvider'
import { RoutePath } from '../../types/Routes'
import { createQueryString } from '../../helpers/DataTransformer'
import CardHeader from '../../components/page/CardHeader'
import CardContent from '../../components/page/CardContent'
import { searchGiftCardCode } from '../../helpers/APIHelper'
import { voucherWord } from '../../helpers/FormatHelper'
import useResponsive from '../../helpers/isResponsive'

const StyledCard = styled(Card)`
  .home-button-container {
    display: flex;
    justify-content: flex-end;
  }
  &.card-size-small {
    ${(props): string => props.theme.isMobile && 'width: 330px'}
  }
`

function Home(): ReactElement {
  const location = useLocation<{ switchToOrgId?: number; state: string }>()
  const history = useHistory()
  const { organisation, organisationUser, selectOrganisation } = useOrganisation()

  useEffect(() => {
    if (location.state?.switchToOrgId) {
      selectOrganisation(location.state.switchToOrgId)
    }
  }, [])

  const [purchaserName, setPurchaserName] = useState('')
  const [recipientName, setRecipientName] = useState('')
  const [orderId, setOrderId] = useState('')
  const [voucherId, setVoucherId] = useState('')
  const [voucherCode, setVoucherCode] = useState('')

  // Clear the fields on org change
  useEffect(() => {
    setPurchaserName('')
    setRecipientName('')
    setOrderId('')
    setVoucherId('')
    setVoucherCode('')
  }, [organisation?.id])

  const searchParams = { purchaserName, recipientName, orderId }

  const hasUserRole = organisationUser && hasRole(organisationUser.role, Role.OrganisationUser)

  const searchGiftCard = async (): Promise<void> => {
    if (
      !Object.values(searchParams).find((param) => param.length > 0)
      && !voucherId
      && !voucherCode
    ) {
      if (hasUserRole) {
        alertError('You must include a search term')
      } else {
        alertError('You must include a 16 character code')
      }
    } else if (voucherId) {
      history.push(`${RoutePath.VoucherDetail}/${voucherId}`)
    } else if (voucherCode) {
      // FIXME: Temp code to handle old Lumley codes
      const isLumley = organisation?.id === 556
      const isShortLumleyCode = voucherCode.endsWith('--')
      const lumleyCode = isLumley && isShortLumleyCode
        ? voucherCode.slice(0, -2)
        : false

      if (lumleyCode || voucherCode.replace(/[^0-9a-zA-Z]/g, '').length >= 12) {
        const res = await searchGiftCardCode({
          code: lumleyCode || voucherCode,
        })
        if (res) {
          if (hasUserRole) {
            history.push(`${RoutePath.VoucherDetail}/${res.id}`)
          } else {
            history.push(RoutePath.VoucherRedeem, { giftCard: res })
          }
        }
      } else {
        alertError('The code is too short')
      }
    } else if (/[A-Za-z]/g.test(searchParams.orderId)) {
      alertError('Order ID must be a number')
    } else {
      history.push(
        `${RoutePath.SearchResults}?${createQueryString(searchParams)}`,
      )
    }
  }

  const responsive = useResponsive()

  return (
    <Animate isAnimated={location.state?.state === 'animate'}>
      <Page>
        <StyledCard cardSize={CardSize.Small} theme={responsive}>
          {hasUserRole ? (
            <UserSearch
              searchGiftCard={searchGiftCard}
              purchaserName={purchaserName}
              setPurchaserName={setPurchaserName}
              recipientName={recipientName}
              setRecipientName={setRecipientName}
              orderId={orderId}
              setOrderId={setOrderId}
              voucherId={voucherId}
              setVoucherId={setVoucherId}
              voucherCode={voucherCode}
              setVoucherCode={setVoucherCode}
            />
          ) : (
            <RedeemerSearch
              voucherCode={voucherCode}
              setVoucherCode={setVoucherCode}
              searchGiftCard={searchGiftCard}
            />
          )}
        </StyledCard>
        {hasUserRole && (
          <StyledCard cardSize={CardSize.Small} theme={responsive}>
            <CardHeader title={`Sell a ${voucherWord(organisation)}`} />
            <CardContent>
              <p>
                {`Issue a ${voucherWord(
                  organisation,
                )} in-house. You will need to take an off-line card or cash payment.`}
              </p>
              <div className="home-button-container">
                <Button
                  buttonStyle={ButtonStyle.Action}
                  onClick={(): void => history.push(RoutePath.ManualOrder)}
                >
                  Manual order
                </Button>
              </div>
            </CardContent>
          </StyledCard>
        )}
      </Page>
    </Animate>
  )
}

export default Home
