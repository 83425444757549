import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

const Styles = styled.div`
  position: relative;
  padding: 10px 25px 25px;
  p {
    max-width: fit-content;
  }
`

export interface ICardContentProps {
  children: ReactNode
  className?: string
  style?: object
}
function CardContent(props: ICardContentProps): ReactElement {
  const { children, className, style } = props

  return (
    <Styles className={className} style={style}>
      {children}
    </Styles>
  )
}
export default CardContent
