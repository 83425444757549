import { Moment } from 'moment'
import React, { ReactElement } from 'react'
import {
  enumKeyByValue,
  enumToOptions,
  InputGroup,
  SelectField,
  ReportItemisation,
  ReportMetric,
  ReportMetricTickets,
  ReportMetricType,
  RangeSelector,
  StateAction,
} from '@one-tree/library'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { OrganisationFormat } from '../../../types/Types'

interface IControlsProps {
  itemiseBy: ReportItemisation
  setItemiseBy: (metric: ReportItemisation) => void
  metric: ReportMetric
  setMetric: (metric: ReportMetric) => void
  metricType: ReportMetricType
  setMetricType: (metricType: ReportMetricType) => void
  fromDate: Moment
  setFromDate: StateAction<Moment>
  toDate: Moment
  setToDate: StateAction<Moment>
}
function Controls(props: IControlsProps): ReactElement {
  const {
    itemiseBy,
    setItemiseBy,
    metric,
    setMetric,
    metricType,
    setMetricType,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  } = props

  const { organisation } = useOrganisation()
  const ifNotTickets = organisation?.format !== OrganisationFormat.Tickets
  const ifGiftVouchers = organisation?.format === OrganisationFormat.GiftVouchers

  return (
    <InputGroup>
      {ifNotTickets ? (
        <SelectField
          label="Status"
          options={enumToOptions(ReportMetric)}
          value={{
            value: metric,
            label: enumKeyByValue(ReportMetric, metric),
          }}
          onChange={(option): void => {
            if (option) setMetric(option.value)
          }}
        />
      ) : (
        <SelectField
          label="Status"
          options={enumToOptions(ReportMetricTickets)}
          value={{
            value: metric,
            label: enumKeyByValue(ReportMetricTickets, metric),
          }}
          onChange={(option): void => {
            if (option) setMetric(option.value)
          }}
        />
      )}
      {ifGiftVouchers && (
        <SelectField
          label="Itemise by"
          options={enumToOptions(ReportItemisation)}
          value={{
            value: itemiseBy,
            label: enumKeyByValue(ReportItemisation, itemiseBy),
          }}
          onChange={(option): void => {
            if (option) setItemiseBy(option.value)
          }}
        />
      )}
      <SelectField
        label="Data type"
        options={enumToOptions(ReportMetricType)}
        value={{
          value: metricType,
          label: enumKeyByValue(ReportMetricType, metricType),
        }}
        onChange={(option): void => {
          if (option) setMetricType(option.value)
        }}
      />
      <RangeSelector
        fromState={[fromDate, setFromDate]}
        toState={[toDate, setToDate]}
      />
    </InputGroup>
  )
}
export default Controls
