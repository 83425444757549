import React, { FormEvent, ReactElement, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import {
  InputField,
  InputType,
  Button,
  ButtonStyle,
  Buttonlink,
  Role,
} from '@one-tree/library'
import { RoutePath } from '../../types/Routes'
import { useAuth } from '../../context/AuthProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { authenticate, listOrganisations } from '../../helpers/APIHelper'
import { ILoginMessages } from '../../types/Types'
import { validateEmail, validatePassword } from '../../helpers/ValidationHelper'

interface ILoginUserProps {
  sendMessages: (object?: ILoginMessages) => void
  receiveMessages: ILoginMessages
  loginAnimation: () => Promise<unknown>
}

function LoginUser(props: ILoginUserProps): ReactElement {
  const { sendMessages, receiveMessages, loginAnimation } = props

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const { token, role, saveAccessToken } = useAuth()
  const { selectOrganisation, saveOrganisations } = useOrganisation()

  const history = useHistory()

  const handleLogin = async (
    event: FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()

    setLoading(true)

    sendMessages()
    if (validateEmail(email)) {
      sendMessages({
        email: validateEmail(email),
      })
      setLoading(false)
      return
    }

    if (validatePassword(password)) {
      sendMessages({
        password: validatePassword(password),
      })
      setLoading(false)
      return
    }

    const result = await authenticate({ email, password })

    if (result) {
      saveAccessToken(result)

      // Redirect super administrators to the dashboard
      if (token && role === Role.SuperAdmin) {
        history.push(RoutePath.Dashboard)
        return
      }

      // Get organisations
      const organisationsResult = await listOrganisations()

      if (organisationsResult) {
        saveOrganisations(organisationsResult)

        // If there is just one organisation then skip selection and go straight to the app
        if (organisationsResult.length === 1) {
          const orgResult = await selectOrganisation(organisationsResult[0].id)
          if (orgResult) {
            await loginAnimation()
            history.push(RoutePath.Home, { state: 'animate' })
            return
          }
          sendMessages({ error: 'Organisation not found' })
          setLoading(false)
          return
        }

        // Otherwise go to select organisation (if no organisations, still go through to view the
        // product ads)
        await loginAnimation()
        history.push(RoutePath.SelectOrganisation)
        return
      }
    }
    sendMessages({ error: 'The email address or password is incorrect' })
    setLoading(false)
  }

  return (
    <>
      <p>Welcome back!</p>
      <form className="form" noValidate={true} onSubmit={handleLogin}>
        <InputField
          type={InputType.Email}
          name="email"
          onChange={setEmail}
          value={email}
          isError={Boolean(receiveMessages.email)}
          placeholder="Email address"
        />
        <InputField
          type={InputType.Password}
          name="password"
          onChange={setPassword}
          value={password}
          isError={Boolean(receiveMessages.password)}
          placeholder="Password"
        />
        <Button
          type="submit"
          buttonStyle={ButtonStyle.Primary}
          loading={loading}
        >
          Login
        </Button>
      </form>
      <Link
        to={RoutePath.RequestPassword}
        onClick={(): void => {
          sendMessages()
          setEmail('')
          setPassword('')
        }}
      >
        <Buttonlink>Reset password</Buttonlink>
      </Link>
    </>
  )
}

export default LoginUser
