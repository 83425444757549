import EmailValidator from 'email-validator'

export const validateEmail = (input: string): string => {
  if (!input) {
    return 'Must include email address'
  }
  if (!EmailValidator.validate(input)) {
    return 'Email invalid'
  }
  return ''
}
export const validatePassword = (input: string): string => {
  if (!input) {
    return 'Must include password'
  }
  if (input.length < 6) {
    return 'Password minimum 6 characters'
  }
  return ''
}
export const validateUrl = (input: string): string | false => {
  try {
    const validUrl = new URL(input)
    return validUrl.href
  } catch (error) {
    return false
  }
}
