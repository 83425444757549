import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  alertSuccess,
  Role,
  hasRole,
  IGiftCardResponse,
  GiftCardStatus,
} from '@one-tree/library'
import { RoutePath } from '../../types/Routes'
import { redeemGiftCard } from '../../helpers/APIHelper'
import { isGiftCardValid } from '../../helpers/GiftCardHelper'
import { StateAction } from '../../types/Aliases'
import { useOrganisation } from '../../context/OrganisationProvider'

interface IGiftCardActions {
  giftCard: IGiftCardResponse
  setJustRedeemed: StateAction<boolean>
}

function GiftCardRedeem(props: IGiftCardActions): ReactElement {
  const { giftCard, setJustRedeemed } = props
  const history = useHistory()

  const handleRedeem = async (cardId: string): Promise<void> => {
    const result = await redeemGiftCard({ cardId })
    if (result) {
      setJustRedeemed(true)
      alertSuccess('Redeem succeeded')
    }
  }

  const { organisationUser } = useOrganisation()
  const isUser = organisationUser
    && hasRole(organisationUser.role, Role.OrganisationUser)

  return isGiftCardValid(giftCard.status) ? (
    <AsyncButton
      buttonStyle={ButtonStyle.Action}
      onClick={(): Promise<void> => handleRedeem(giftCard.id)}
    >
      Redeem
    </AsyncButton>
  ) : (
    <>
      <Button
        buttonStyle={ButtonStyle.Secondary}
        onClick={(): void => history.push(RoutePath.Home)}
      >
        Home
      </Button>
      {giftCard.status === GiftCardStatus.Expired && isUser && (
        <AsyncButton
          buttonStyle={ButtonStyle.Danger}
          onClick={(): Promise<void> => handleRedeem(giftCard.id)}
          showConfirmation={true}
        >
          Redeem anyway
        </AsyncButton>
      )}
    </>
  )
}
export default GiftCardRedeem
