import { hasRole, Role } from '@one-tree/library'
import React, { ComponentType, ReactElement } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../context/AuthProvider'
import { useOrganisation } from '../../context/OrganisationProvider'
import { RoutePath } from '../../types/Routes'

export interface IOrganisationRouteProps {
  component: ComponentType
  path: string
  requiredRole?: Role
}
function OrganisationRoute(props: IOrganisationRouteProps): ReactElement {
  const { component, path, requiredRole } = props
  const { token } = useAuth()
  const { organisation, organisationUser } = useOrganisation()

  const isAllowed = requiredRole
    ? organisationUser && hasRole(organisationUser.role, requiredRole)
    : true

  return token && organisation && isAllowed ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect
      to={{ pathname: RoutePath.Login, state: { referer: document.referrer } }}
    />
  )
}

export default OrganisationRoute
