import React, { ReactElement } from 'react'
import {
  enumKeyByValue,
  PeriodSelector,
  enumToOptions,
  InputGroup,
  SelectField,
  ReportMetric,
  ReportMetricTickets,
  ReportMetricType,
} from '@one-tree/library'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { OrganisationFormat } from '../../../types/Types'

interface IControlsProps {
  metric: ReportMetric
  setMetric: (metric: ReportMetric) => void
  metricType: ReportMetricType
  setMetricType: (metricType: ReportMetricType) => void
  fromMonth: number
  setFromMonth: (month: number) => void
  fromYear: number
  setFromYear: (year: number) => void
}
function Controls(props: IControlsProps): ReactElement {
  const {
    metric,
    setMetric,
    metricType,
    setMetricType,
    fromMonth,
    setFromMonth,
    fromYear,
    setFromYear,
  } = props
  const { organisation } = useOrganisation()
  const ifNotTickets = organisation?.format !== OrganisationFormat.Tickets

  return (
    <InputGroup>
      {ifNotTickets ? (
        <SelectField
          label="Status"
          options={enumToOptions(ReportMetric)}
          value={{
            value: metric,
            label: enumKeyByValue(ReportMetric, metric),
          }}
          onChange={(option): void => {
            if (option) setMetric(option.value)
          }}
        />
      ) : (
        <SelectField
          label="Status"
          options={enumToOptions(ReportMetricTickets)}
          value={{
            value: metric,
            label: enumKeyByValue(ReportMetricTickets, metric),
          }}
          onChange={(option): void => {
            if (option) setMetric(option.value)
          }}
        />
      )}
      <SelectField
        label="Data type"
        options={enumToOptions(ReportMetricType)}
        value={{
          value: metricType,
          label: enumKeyByValue(ReportMetricType, metricType),
        }}
        onChange={(option): void => {
          if (option) setMetricType(option.value)
        }}
      />
      <PeriodSelector
        fromMonth={fromMonth}
        setFromMonth={setFromMonth}
        fromYear={fromYear}
        setFromYear={setFromYear}
      />
    </InputGroup>
  )
}
export default Controls
