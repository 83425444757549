import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  colors, Loading, theme, ZIndex,
} from '@one-tree/library'

const Styles = styled.div`
  z-index: ${ZIndex.Cover};
  pointer-events: none;
  position: fixed;
  top: ${theme.headerHeight};
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s;
  &.loading-cover-showing {
    pointer-events: all;
    opacity: 1;
  }
`
interface ILoadingCoverProps {
  loading: boolean
}
function LoadingCover(props: ILoadingCoverProps): ReactElement {
  const { loading } = props

  return (
    <Styles className={`${loading ? 'loading-cover-showing' : ''}`}>
      <Loading />
    </Styles>
  )
}
export default LoadingCover
