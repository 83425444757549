import { IOrganisationResponse, ISelectOption } from '@one-tree/library'
import React from 'react'
import OrganisationOption from '../components/header/OrganisationOption'
import { sortAlphabeticallyByKey } from './DataTransformer'

export const getOrganisationOption = (
  organisation: IOrganisationResponse,
  onClick?: (organisationId: number) => void,
): ISelectOption<number> => ({
  value: organisation.id,
  label: (
    <OrganisationOption
      onClick={(): void => onClick && onClick(organisation.id)}
      organisation={organisation}
    />
  ),
})

export const getAllOrganisationOptions = (
  allOrganisations: IOrganisationResponse[],
  thisOrganisation: IOrganisationResponse,
  onClick: (organisationId: number) => void,
): ISelectOption<number>[] => allOrganisations
  .filter((organisation) => organisation.id !== thisOrganisation.id)
  .sort((a, b) => sortAlphabeticallyByKey(a, b, 'name'))
  .map((organisation) => getOrganisationOption(organisation, onClick))

export const getOrganisationColors = (
  organisation: IOrganisationResponse | null,
): {} => ({
  colourBodyText: organisation?.colourBodyText,
  colourHeaderText: organisation?.colourHeaderText,
  colourHighlight: organisation?.colourHighlight,
  colourPageBackground: organisation?.colourPageBackground,
  colourPriceText: organisation?.colourPriceText,
  colourTextOnBackground: organisation?.colourTextOnBackground,
  colourTextOnHighlight: organisation?.colourTextOnHighlight,
})
