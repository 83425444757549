import React, { ReactElement } from 'react'
import {
  IGiftItemResponse, InputField, InputType, IOrganisationResponse,
} from '@one-tree/library'
import {
  IGiftItemPostBody,
} from '../../../../types/GiftItemTypes'
import { nameTest } from '../../../../helpers/GiftItemValidityHelper'
import { itemWord } from '../../../../helpers/FormatHelper'

export interface IItemNameProps {
  label: string
  value: string
  giftItem: IGiftItemResponse | null
  changeItem: (changes: IGiftItemPostBody) => void
  isEditing: boolean
  organisation: IOrganisationResponse | null
}

function ItemName(props: IItemNameProps): ReactElement {
  const {
    label, value, changeItem, isEditing, giftItem, organisation,
  } = props

  return (
    <InputField
      type={InputType.Text}
      label={label}
      value={value}
      placeholder={`Add a name that describes the ${itemWord(organisation)}...`}
      onChange={(newValue): void => {
        changeItem({ name: newValue })
      }}
      maxChars={50}
      isError={isEditing && Boolean(nameTest({ giftItem, organisation }))}
      subtitle={
        (isEditing && nameTest({ giftItem, organisation })) || undefined
      }
    />
  )
}
export default ItemName
