import React, { ReactElement } from 'react'
import {
  getGiftItemImage,
  IGiftItemResponse,
  ImageType,
  IOrganisationResponse,
  ITEM_IMAGE_DIMENSIONS,
  UploadImageField,
} from '@one-tree/library'
import { imageTest } from '../../../../helpers/GiftItemValidityHelper'
import { IGiftItemPostBody } from '../../../../types/GiftItemTypes'
import { uploadImage } from '../../../../helpers/APIHelper'

export interface IItemImageProps {
  label?: string
  previewUrl: string
  giftItem: IGiftItemResponse | null
  changeItem: (changes: IGiftItemPostBody) => void
  changePreview: (newUrl: { [url: string]: string }) => void
  isEditing: boolean
  organisation: IOrganisationResponse | null
  isOverlay?: true
  subtitle?: string
}

export default function ItemImage(props: IItemImageProps): ReactElement {
  const {
    label,
    previewUrl,
    giftItem,
    changeItem,
    changePreview,
    isEditing,
    organisation,
    isOverlay,
    subtitle,
  } = props

  const imageType = isOverlay
    ? ImageType.GiftItemOverlay
    : ImageType.GiftItemImage

  const id = isOverlay ? 'overlayImageFileId' : 'imageFileId'
  const url = isOverlay ? 'overlayImageUrl' : 'imageUrl'

  const test = imageTest({ giftItem, organisation })
  const isError = !isOverlay && isEditing && Boolean(test)
  const renderSubtitle = (!isOverlay && isEditing && test) || subtitle || undefined

  return (
    <UploadImageField
      label={label}
      imageType={imageType}
      onSuccess={(response): void => {
        changeItem({
          [id]: response.id,
        })
        changePreview({ [url]: response.url })
      }}
      previewUrl={previewUrl && getGiftItemImage(previewUrl)}
      cropPixelAspect={ITEM_IMAGE_DIMENSIONS}
      isError={isError}
      subtitle={renderSubtitle}
      upload={uploadImage}
      isPng={isOverlay}
    />
  )
}
