import {
  alertError,
  alertSuccess,
  AsyncButton,
  Button,
  ButtonStyle,
  Card,
  CardContent,
  CardHeader,
  CardSize,
  colors,
  getRoleText,
  InputField,
  InputType,
  ISelectOption,
  Loading,
  onlyChanges,
  Role,
  SelectField,
} from '@one-tree/library'
import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import Page from '../../hoc/Page'
import { IDecodedToken, IUserResponse } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import { useAuth } from '../../context/AuthProvider'
import {
  createUser,
  deleteUser,
  getUser,
  updateUser,
} from '../../helpers/APIHelper'
import { getUserErrors, isUserValid } from './userValidation'

const Styles = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
`
const User = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  span {
    color: ${colors.blue};
  }
`
const Error = styled.div`
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export default function EditUser(): ReactElement {
  const { id }: { id: string } = useParams()
  const location = useLocation()
  const { email } = location.state || {}
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const { token } = useAuth()
  const decodedToken: IDecodedToken | null = token ? jwt_decode(token) : null
  const isOwnUser = decodedToken?.sub === id
  const canDelete = id && !isOwnUser

  const allowedRoles: ISelectOption[] = [
    {
      value: 'organisation-redeem',
      label: getRoleText(Role.OrganisationRedeemer),
    },
    {
      value: 'organisation-user',
      label: getRoleText(Role.OrganisationUser),
    },
    {
      value: 'organisation-admin',
      label: getRoleText(Role.OrganisationAdmin),
    },
  ]

  const [userResponse, setUserResponse] = useState<Partial<IUserResponse>>({})
  const [changes, setChanges] = useState<Partial<IUserResponse>>({})

  const fetchUser = async (): Promise<void> => {
    setLoading(true)
    const res = await getUser({ userId: Number(id) })
    if (res) setUserResponse(res)
    setLoading(false)
  }
  useEffect(() => {
    if (id) fetchUser()
  }, [])

  const [user, setUser] = useState({
    emailAddress: email || userResponse.emailAddress,
    ...userResponse,
    ...changes,
  })
  useEffect((): void => {
    setUser({
      emailAddress: email || userResponse.emailAddress,
      ...userResponse,
      ...changes,
    })
  }, [userResponse, changes])

  const change = <T extends unknown>(
    key: keyof Partial<IUserResponse>,
    value: T,
  ): void => {
    setChanges((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const save = async (): Promise<void> => {
    if (!isUserValid(user)) {
      alertError(getUserErrors(user)[0])
      return
    }

    const patchUser = { ...changes }
    delete patchUser.organisations

    if (id) {
      const res = await updateUser({ userId: Number(id), ...patchUser })
      if (res) {
        alertSuccess('User updated')
        history.push(RoutePath.Users)
      }
    } else {
      const res = await createUser({ emailAddress: email, ...patchUser })
      if (res) {
        alertSuccess('User created')
        history.push(RoutePath.Users)
      }
    }
  }

  const renderFields = (
    <Styles>
      {!isOwnUser ? (
        <>
          <SelectField
            placeholder="Select role"
            options={allowedRoles}
            value={
              user.role
                ? { label: getRoleText(user.role), value: user.role }
                : undefined
            }
            onChange={(option): void => {
              if (option) change('role', option.value)
            }}
            isClearable={false}
          />
          <div />
        </>
      ) : null}
      <InputField
        placeholder="First name"
        type={InputType.Text}
        value={user.firstName}
        onChange={(value): void => change('firstName', value)}
      />
      <InputField
        placeholder="Last name"
        type={InputType.Text}
        value={user.lastName}
        onChange={(value): void => change('lastName', value)}
      />
    </Styles>
  )

  const renderPage = user.emailAddress ? (
    <>
      <CardHeader title={id ? 'Edit user' : 'Add user'}>
        {canDelete && (
          <AsyncButton
            buttonStyle={ButtonStyle.Danger}
            onClick={async (): Promise<void> => {
              await deleteUser({ userId: Number(id) })
              history.push(RoutePath.Users)
            }}
            showConfirmation={true}
          >
            Remove user
          </AsyncButton>
        )}
        <Button
          buttonStyle={ButtonStyle.Secondary}
          onClick={(): void => history.push(RoutePath.Users)}
        >
          Back
        </Button>
        <AsyncButton
          buttonStyle={ButtonStyle.Action}
          onClick={save}
          disabled={!onlyChanges(userResponse, changes)}
        >
          Save
        </AsyncButton>
      </CardHeader>
      <CardContent>
        <User>
          Email: <span>{user.emailAddress}</span>
        </User>
        {renderFields}
      </CardContent>
    </>
  ) : (
    <Error>User not found.</Error>
  )
  return (
    <Page>
      <Card cardSize={CardSize.Small}>
        {loading ? <Loading fullPage={true} /> : renderPage}
      </Card>
    </Page>
  )
}
