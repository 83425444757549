import React from 'react'
import {
  capitalize,
  formatCurrency,
  FormattedDateTime,
  GiftCardStatus,
  IColumn,
  IFilters,
  IGiftCardResponse,
  IOrganisationResponse,
  OrganisationFormat,
  RequestWithFilters,
} from '@one-tree/library'
import GiftCardStatusBox, {
  StatusBoxSize,
} from '../components/GiftCardStatusBox'
import { itemWord } from './FormatHelper'
import { statusEnumToOptions } from './GiftCardHelper'

export type SalesTableState = RequestWithFilters<
  IGiftCardResponse,
  IFilters<IGiftCardResponse>[]
>

export const salesColumns = (
  organisation: IOrganisationResponse | null,
  showRedeem: boolean,
): IColumn<IGiftCardResponse>[] => {
  if (!organisation) return []

  const showRecipient = organisation.format === OrganisationFormat.GiftVouchers
  const isTicket = organisation.format === OrganisationFormat.Tickets

  const name = `${capitalize(itemWord(organisation))} name`
  const { currencyCode } = organisation

  const columns: IColumn<IGiftCardResponse>[] = [
    {
      accessor: 'status',
      header: 'Status',
      sorting: false,
      display: ({ status }) => (
        <GiftCardStatusBox status={status} size={StatusBoxSize.Table} />
      ),
    },
    {
      accessor: 'id',
      header: 'ID',
      sorting: false,
    },
    {
      accessor: 'deliveryFormat',
      header: 'Dispatch',
      display: ({ deliveryFormat }) => capitalize(deliveryFormat),
    },
    {
      accessor: 'giftItemName',
      header: name,
      display: ({ giftItemName, priceName }) => (priceName ? `${giftItemName} (${priceName})` : giftItemName),
    },
  ]

  const giftItemStart: IColumn<IGiftCardResponse> = {
    accessor: 'giftItemStart',
    header: 'Date (and time)',
    sorting: false,
  }

  const purchaseColumns: IColumn<IGiftCardResponse>[] = [
    {
      accessor: 'value',
      header: 'Value',
      display: ({ value }) => formatCurrency(value, currencyCode),
    },
    {
      accessor: 'purchaseDate',
      header: 'Purchase date',
      display: ({ purchaseDate }) => (
        <FormattedDateTime timestamp={purchaseDate} />
      ),
    },
  ]

  const purchaserColumns: IColumn<IGiftCardResponse>[] = [
    {
      accessor: 'purchaserFirstName',
      parent: 'Purchaser name',
      header: 'First',
    },
    {
      accessor: 'purchaserLastName',
      parent: 'Purchaser name',
      header: 'Last',
    },
  ]

  const recipientColumns: IColumn<IGiftCardResponse>[] = [
    {
      accessor: 'deliveryFirstName',
      parent: 'Recipient name',
      header: 'First',
    },
    {
      accessor: 'deliveryLastName',
      parent: 'Recipient name',
      header: 'Last',
    },
  ]

  const redeemColumns: IColumn<IGiftCardResponse>[] = [
    {
      accessor: 'redeemUser',
      header: 'Redeemed by',
    },
    {
      accessor: 'redeemDate',
      header: 'Redeemed on',
      display: ({ redeemDate }) => <FormattedDateTime timestamp={redeemDate} />,
    },
  ]

  const defaultColumns = isTicket
    ? [...columns, giftItemStart, ...purchaseColumns]
    : [...columns, ...purchaseColumns]

  if (showRedeem) {
    return [...defaultColumns, ...redeemColumns]
  }
  if (showRecipient) {
    return [...defaultColumns, ...purchaserColumns, ...recipientColumns]
  }
  return [...defaultColumns, ...purchaserColumns]
}

export const salesFilters: IFilters<IGiftCardResponse>[] = [
  {
    accessor: 'status',
    options: statusEnumToOptions(GiftCardStatus),
  },
]
