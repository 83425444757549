import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  AsyncButton,
  Button,
  ButtonStyle,
  alertSuccess,
  ItemGridContainer,
  limitChars,
  IGiftItemResponse,
  colors,
} from '@one-tree/library'
import Embed from '../../components/Embed'

import Card, { CardSize } from '../../components/page/Card'
import CardContent from '../../components/page/CardContent'
import CardHeader from '../../components/page/CardHeader'
import { useOrganisation } from '../../context/OrganisationProvider'
import { patchOrganisation } from '../../helpers/APIHelper'
import { voucherWord } from '../../helpers/FormatHelper'
import Page from '../../hoc/Page'
import { IOrganisationPatchBody } from '../../types/API'
import { RoutePath } from '../../types/Routes'
import WebShopTheme from './WebShopTheme'
import WebShopDetails from './WebShopText'
import {
  ExampleGiftItem,
  ExampleSpecialOfferItem,
  ExampleTicketItem,
} from './ExampleGiftItem'
import { OrganisationFormat } from '../../types/Types'
import WebShopAnalytics from './WebShopAnalytics'
import WebShopBar from './WebShopBar'

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`
const Preview = styled(ItemGridContainer)`
  width: 287px;
  height: fit-content;
  border: 1px solid ${colors.lightGray};
`

function WebShop(): ReactElement {
  const history = useHistory()
  const { organisation, selectOrganisation } = useOrganisation()
  const [changes, setChanges] = useState<IOrganisationPatchBody | null>(null)
  const [showEmbed, setShowEmbed] = useState(false)

  const testObject = { ...organisation, ...changes }
  const deliveryError = !testObject?.emailDelivery && !testObject?.postDelivery

  const handleSaveWebShop = async (): Promise<void> => {
    if (organisation && changes) {
      // when patching, only send what has changed
      const res = await patchOrganisation({
        organisationId: organisation.id,
        patchOptions: {
          ...changes,
        },
      })
      if (res && (await selectOrganisation(res.id))) {
        setChanges(null)
        alertSuccess('Web shop saved')
      }
    }
  }

  const handleChangeOrganisation = (updates: IOrganisationPatchBody): void => {
    setChanges({
      ...changes,
      ...updates,
    })
  }

  const orgColors = {
    colourBodyText: changes?.colourBodyText ?? organisation?.colourBodyText,
    colourHeaderText:
      changes?.colourHeaderText ?? organisation?.colourHeaderText,
    colourHighlight: changes?.colourHighlight ?? organisation?.colourHighlight,
    colourPageBackground:
      changes?.colourPageBackground ?? organisation?.colourPageBackground,
    colourPriceText: changes?.colourPriceText ?? organisation?.colourPriceText,
    colourTextOnBackground:
      changes?.colourTextOnBackground ?? organisation?.colourTextOnBackground,
    colourTextOnHighlight:
      changes?.colourTextOnHighlight ?? organisation?.colourTextOnHighlight,
    transparentIframe:
      changes?.transparentIframe ?? organisation?.transparentIframe,
    colourHeaderBackground:
      changes?.colourHeaderBackground ?? organisation?.colourHeaderBackground,
  }

  const font = changes?.font ?? organisation?.font
  const introText = limitChars(
    (changes?.introductoryText ?? organisation?.introductoryText) || '',
    50,
    true,
  )

  const getExampleItem = (): IGiftItemResponse[] => {
    switch (organisation?.format) {
    case OrganisationFormat.Tickets:
      return [ExampleTicketItem]
    case OrganisationFormat.SpecialOffers:
      return [ExampleSpecialOfferItem]
    case OrganisationFormat.GiftVouchers:
    default:
      return [ExampleGiftItem]
    }
  }

  return (
    <Page warnBeforeExit={Boolean(changes)}>
      <Card cardSize={CardSize.Medium}>
        <CardHeader title="Customise your web shop">
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => history.push(RoutePath.Home)}
          >
            Cancel
          </Button>
          <AsyncButton
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => setShowEmbed(true)}
            disabled={!organisation}
          >
            Embed
          </AsyncButton>
          <AsyncButton
            buttonStyle={ButtonStyle.Action}
            disabled={!changes || deliveryError}
            onClick={handleSaveWebShop}
          >
            Save
          </AsyncButton>
        </CardHeader>
        <CardContent>
          {organisation && (
            <>
              <Grid>
                <div>
                  <WebShopTheme
                    values={{
                      ...organisation,
                      ...changes,
                    }}
                    changeOrganisation={handleChangeOrganisation}
                  />
                  <WebShopBar
                    values={{
                      ...organisation,
                      ...changes,
                    }}
                    changeOrganisation={handleChangeOrganisation}
                  />
                </div>
                <Preview
                  giftItems={getExampleItem()}
                  currency={organisation.currencyCode}
                  organisationColors={orgColors}
                  font={font}
                  introductoryText={introText}
                  introductoryTextSize={
                    changes?.textOnBackgroundSize
                    || organisation.textOnBackgroundSize
                  }
                />
              </Grid>
              <WebShopDetails
                values={{
                  ...organisation,
                  ...changes,
                }}
                changeOrganisation={handleChangeOrganisation}
                deliveryError={deliveryError}
              />
              <WebShopAnalytics
                values={{
                  ...organisation,
                  ...changes,
                }}
                changeOrganisation={handleChangeOrganisation}
              />
            </>
          )}
        </CardContent>
      </Card>
      <Embed
        modalOpen={showEmbed}
        setModalOpen={setShowEmbed}
        embedUrl={organisation?.embedUrl}
        customTitle={`Embed the ${voucherWord(
          organisation,
        )} shop on your website.`}
      />
    </Page>
  )
}
export default WebShop
