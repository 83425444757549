import React, { ReactElement } from 'react'
import styled from 'styled-components'
import {
  getCategoryImage, addHash, colors, IOrganisationResponse,
} from '@one-tree/library'

const Styles = styled.div`
  position: relative;
  width: 262px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
`
const Image = styled.div`
  background: url(${(props): string => props.theme});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 270px;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  padding: 12px;
  background-color: ${(props): string => props.theme[0] ?? colors.blue};
  color: ${(props): string => props.theme[1] ?? colors.white};
  font-family: ${(props): string => props.theme[2] ?? 'Arial'};
`

export interface ICategoryPreviewProps {
  image: string
  title: string
  organisation: IOrganisationResponse
}

function CategoryPreview(props: ICategoryPreviewProps): ReactElement {
  const { image, title, organisation } = props

  return (
    <Styles>
      <Image theme={image && getCategoryImage(image)} />
      <Title
        theme={[
          addHash(organisation.colourHighlight),
          addHash(organisation.colourTextOnHighlight),
          organisation.font,
        ]}
      >
        {title}
      </Title>
    </Styles>
  )
}
export default CategoryPreview
