export const stripHTML = (HTMLInput: string): string => {
  if (!HTMLInput) return ''
  const temporaryElement = document.createElement('div')
  temporaryElement.innerHTML = HTMLInput
  return temporaryElement.textContent || temporaryElement.innerText || ''
}

export const limitChars = (
  input: string,
  limit: number,
  ellipsis?: Boolean,
): string => {
  if (input.length > limit) {
    return `${input.substring(0, limit)}${ellipsis ? '...' : ''}`
  }
  return input
}

export const concatObjectValues = (
  object: Object,
  separator: string,
): string => {
  const values = Object.values(object)
  return values.reduce((a, b) => a + separator + b)
}

export const createQueryString = (object: object): string => {
  const stripEmpty = Object.fromEntries(
    Object.entries(object).filter(([, value]) => value !== ''),
  )
  const searchParams = new URLSearchParams(stripEmpty)
  return searchParams.toString()
}

export const toSentenceCase = (string: string): string => string.charAt(0).toUpperCase()
  + string
    .slice(1)
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()

export const stripEmptyFromObject = (object: object): Object => Object.fromEntries(
  Object.entries(object).filter(
    ([, value]) => value !== '' && value !== undefined && value !== null && value !== ' ',
  ),
)

// want this to work with any array
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stripEmptyFromArray = (array: any[]): any[] => array.filter(
  (value) => value !== '' && value !== undefined && value !== null && value !== ' ',
)

export const onlyString = (input: string): string => input.replace(/[^a-zA-Z0-9]/g, '')

export const truncate = (input: string, limit: number): string => {
  if (input.length > limit) {
    return input.slice(0, limit).concat('...')
  }
  return input
}

// to ensure HTML codes like `&#163;` aren't escaped
export const parseString = (input: string | undefined): string => new DOMParser().parseFromString(`<!doctype html><body>${input}`, 'text/html')
  .body.textContent || ''

export const capitalize = (string: string | undefined): string => (string ? string[0].toUpperCase() + string.substring(1) : '')

export const sortAlphabeticallyByKey = <T>(
  a: T,
  b: T,
  key: keyof T,
): number => {
  if (a[key] < b[key]) return -1
  if (a[key] > b[key]) return 1
  return 0
}

export const toSentence = (...strings: (string | undefined)[]): string => stripEmptyFromArray(strings).join(' ')
