import { colors } from '@one-tree/library'
import styled from 'styled-components'

export const SimpleTable = styled.table`
  border: 1px solid ${colors.gray};
  border-collapse: collapse;
  th,
  td {
    padding: 5px 8px;
  }
  th {
    text-align: left;
    border: 1px solid ${colors.gray};
    padding-bottom: 3px;
  }
  td {
    font-size: 0.9rem;
    line-height: 1.2rem;
    vertical-align: top;
  }
  tr:nth-child(even) {
    background-color: ${colors.lightGray};
  }
`
