import axios from 'axios'
import React, {
  createContext,
  useContext,
  useState,
  ReactElement,
  useEffect,
  ReactNode,
  useMemo,
} from 'react'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import { Role } from '@one-tree/library'
import { ITokenPair } from '../types/Types'
import { IDecodedToken } from '../types/API'
import { getRefreshToken } from '../helpers/APIHelper'

interface IState {
  token: string | null
  role: Role | null
  saveAccessToken: (tokenPair: ITokenPair) => void
  clearAuth: () => void
}

let refreshTimer: ReturnType<typeof setTimeout> | null = null

const AuthContext = createContext<IState | undefined>(undefined)

function AuthProvider({ children }: { children: ReactNode }): ReactElement {
  const [token, setToken] = useState<string | null>(
    sessionStorage.getItem('token') || null,
  )
  const [role, setRole] = useState<Role | null>(
    JSON.parse(sessionStorage.getItem('role') || 'null'),
  )
  const [refreshToken, setRefreshToken] = useState<string | null>(null)

  // Save the token and role in session storage
  const saveAccessToken = (tokenPair: ITokenPair): void => {
    sessionStorage.removeItem('token')
    sessionStorage.setItem('token', tokenPair.token)
    setToken(tokenPair.token)

    // Save the refresh token if there is one
    if (tokenPair.refreshToken) {
      setRefreshToken(tokenPair.refreshToken)
    }

    const decodedToken: IDecodedToken = jwt_decode(tokenPair.token)

    if (decodedToken.role) {
      setRole(decodedToken.role)
      sessionStorage.setItem('role', JSON.stringify(decodedToken.role))
    }
  }

  // Remove the token and clear sessionStorage
  const clearAuth = (): void => {
    sessionStorage.clear()

    setToken(null)
    setRefreshToken(null)

    // Remove the token from Axios
    delete axios.defaults.headers.common.Authorization
    setRole(null)
  }

  const onTimerExpiry = async (): Promise<void> => {
    if (refreshToken) {
      const response = await getRefreshToken({ refreshToken })
      if (response) {
        saveAccessToken(response)
      }
    }
  }

  if (token) {
    // Set the token in Axios headers
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  useEffect(() => {
    if (token && refreshToken) {
      const decodedToken: IDecodedToken = jwt_decode(token)

      // The token expiry should always be in the future
      if (decodedToken.exp > moment().unix()) {
        // Clear old timer first so we don't have multiple running
        if (refreshTimer) {
          clearTimeout(refreshTimer)
        }

        // Set a timeout for 30 seconds before the token expires to refresh it with a new one
        const refreshSeconds = decodedToken.exp - moment().unix() - 30
        refreshTimer = setTimeout(onTimerExpiry, refreshSeconds * 1000)
      } else {
        // Remove the token if it has expired, this shouldn't happen
        clearAuth()
      }
    }
  }, [token, refreshToken])

  const value = useMemo(
    () => ({
      token,
      role,
      saveAccessToken,
      clearAuth,
    }),
    [token, role],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth(): IState {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
