import {
  IGiftItemResponse,
  IOrganisationResponse,
  RichField,
} from '@one-tree/library'
import React, { ReactElement } from 'react'
import { termsTest } from '../../../../helpers/GiftItemValidityHelper'
import { IGiftItemPostBody } from '../../../../types/GiftItemTypes'

export interface IItemExtendedDescriptionProps {
  value: string
  giftItem: IGiftItemResponse | null
  changeItem: (changes: IGiftItemPostBody) => void
  label: string
  isEditing: boolean
  organisation: IOrganisationResponse | null
  maxChars: number
  maxLines?: number
  placeholder: string
}

function ItemExtendedDescription(
  props: IItemExtendedDescriptionProps,
): ReactElement {
  const {
    value,
    giftItem,
    changeItem,
    label,
    isEditing,
    organisation,
    maxChars,
    maxLines,
    placeholder,
  } = props

  const handleChange = (content: string): void => {
    changeItem({ terms: content })
  }

  return (
    <RichField
      label={label}
      maxChars={maxChars}
      maxLines={maxLines}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      isError={isEditing && Boolean(termsTest({ giftItem, organisation }))}
      subtitle={
        (isEditing && termsTest({ giftItem, organisation })) || undefined
      }
    />
  )
}
export default ItemExtendedDescription
