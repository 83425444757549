import React, { ReactElement, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Loading, AsyncButton, Button, ButtonStyle, alertSuccess, colors, IGiftItemResponse, voucherWord,
} from '@one-tree/library'
import Embed from '../../../components/Embed'
import Card, { CardSize } from '../../../components/page/Card'
import CardContent from '../../../components/page/CardContent'
import CardHeader from '../../../components/page/CardHeader'
import { useOrganisation } from '../../../context/OrganisationProvider'
import {
  createCategory,
  deleteCategory,
  get,
  patchCategory,
} from '../../../helpers/APIHelper'
import { getGiftItems } from '../../../helpers/APIItemHelper'
import { requiresImage } from '../../../helpers/CategoryHelper'
import { capitalize } from '../../../helpers/DataTransformer'
import { Code, itemWord } from '../../../helpers/FormatHelper'
import { filterGiftItems } from '../../../helpers/GiftItemHelper'
import Page from '../../../hoc/Page'
import {
  Resource,
  ICategoryPostBody,
  ICategoryResponse,
} from '../../../types/API'
import { RoutePath } from '../../../types/Routes'
import GiftItemGrid from '../giftItemGrid/GiftItemGrid'
import CategoryDesign from './CategoryDesign'

function EditCategory(): ReactElement {
  const { id }: { id: string } = useParams()
  const history = useHistory()
  const [
    categoryChanges,
    setCategoryChanges,
  ] = useState<ICategoryPostBody | null>(null)
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState<ICategoryResponse | null>(null)
  const [showEmbed, setShowEmbed] = useState(false)

  const { organisation } = useOrganisation()
  const [giftItemData, setGiftItemData] = useState<IGiftItemResponse[]>()

  useEffect(() => {
    (async (): Promise<void> => {
      if (id) {
        const res = await get<ICategoryResponse>(
          { resource: Resource.Categories, urlPath: [id] },
        )
        if (res) {
          setCategory(res)
        } else history.push(RoutePath.Home)
      }
    })()
  }, [])

  const handleSaveCategory = async (): Promise<void> => {
    if (category && categoryChanges) {
      let res: ICategoryResponse | false
      if (id && category.id) {
        // when patching, only send what has changed
        res = await patchCategory(
          { categoryId: category.id, patchOptions: { ...categoryChanges } },
        )
      } else {
        res = await createCategory({ category: categoryChanges })
      }

      if (res) {
        setCategoryChanges(null)
        alertSuccess('Category saved')
        setTimeout(() => {
          history.push(`${RoutePath.ManageCategories}`)
        }, 1000)
      }
    }
  }

  const handleChangeCategory = (
    updatedCategory: Partial<ICategoryResponse>,
  ): void => {
    if (category) {
      setCategory({ ...category, ...updatedCategory })
    } else {
      // In the case we're creating a new category we'll be missing some fields
      // eslint-disable-next-line no-type-assertion/no-type-assertion
      setCategory(updatedCategory as ICategoryResponse)
    }

    setCategoryChanges({
      title: updatedCategory.title ?? undefined,
      parent: updatedCategory.parent ?? undefined,
      order: updatedCategory.order ?? undefined,
      imageFileId: updatedCategory.imageFileId ?? undefined,
      status: updatedCategory.status ?? undefined,
    })
  }

  const handleDeleteCategory = async (): Promise<void> => {
    if (category) {
      const res = await deleteCategory({ categoryId: category.id })
      if (res) {
        alertSuccess('Category deleted')
        history.push(RoutePath.ManageCategories)
      }
    }
  }

  const fetchGiftItems = async (): Promise<void> => {
    if (id) {
      setLoading(true)
      const res = organisation && (await getGiftItems({ format: organisation.format }))
      if (res && category) {
        const filteredResults = filterGiftItems(res, {
          category: category.id,
          status: false,
          type: false,
          unfinished: false,
        }, organisation)
        setGiftItemData(filteredResults)
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (category && !giftItemData) fetchGiftItems()
  }, [category])

  const renderCategoryItems = giftItemData && giftItemData.length ? (
    <GiftItemGrid
      giftItemData={giftItemData}
      fetchGiftItems={fetchGiftItems}
    />
  ) : (
    <b style={{ color: colors.red }}>
      {`Please add the ${itemWord(
        organisation,
      )}s that you would like to display in this category.`}
    </b>
  )

  // When editing existing category (id), and the category hasn't loaded yet (!category):
  const doNotRenderCategoryDesign = id && !category

  // No saving when no title or no changes have been made
  const noTitle = !(category?.title?.length)
  const disableSaveCategory = noTitle || !categoryChanges

  return (
    <Page warnBeforeExit={Boolean(categoryChanges)}>
      <Card
        cardSize={
          requiresImage(category, organisation)
            ? CardSize.Medium
            : CardSize.Small
        }
      >
        <CardHeader title={id ? 'Edit category' : 'Create new category'}>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={(): void => history.push(RoutePath.ManageCategories)}
          >
            Cancel
          </Button>
          {id && (
            <>
              <AsyncButton
                buttonStyle={ButtonStyle.Secondary}
                onClick={(): void => setShowEmbed(true)}
              >
                Embed
              </AsyncButton>
              <AsyncButton
                buttonStyle={ButtonStyle.Danger}
                onClick={(): Promise<void> => handleDeleteCategory()}
                showConfirmation={true}
                confirmationMessage="Are you sure? Any Gift Items left without any categories will be unpublished."
              >
                Delete
              </AsyncButton>
            </>
          )}
          <AsyncButton
            buttonStyle={ButtonStyle.Action}
            onClick={handleSaveCategory}
            disabled={disableSaveCategory}
          >
            Save
          </AsyncButton>
        </CardHeader>
        {!doNotRenderCategoryDesign ? (
          <CardContent>
            <CategoryDesign
              category={category}
              changeCategory={handleChangeCategory}
              id={id}
              organisation={organisation}
            />
          </CardContent>
        ) : (
          <Loading fullPage={true} />
        )}
      </Card>
      {id && (
        <Card cardSize={CardSize.Big}>
          <CardHeader
            title={`${capitalize(itemWord(organisation))}s in this category`}
          />
          <CardContent>
            {!loading && giftItemData ? (
              renderCategoryItems
            ) : (
              <Loading fullPage={true} />
            )}
          </CardContent>
        </Card>
      )}
      <Embed
        modalOpen={showEmbed}
        setModalOpen={setShowEmbed}
        embedUrl={category?.embedUrl}
        linkText={(
          <>
            <br />
            <h3>Create a link to this category</h3>
            <p>Add the following suffix to your {voucherWord(organisation?.format)} shop URL:</p>
            <Code>
              ?category={category?.id}
            </Code>
            <p>E.g. https://yourcompanyname.co.uk/gift-vouchers?category=XXX</p>
          </>
        )}
      />
    </Page>
  )
}
export default EditCategory
