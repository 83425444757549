import { colors } from '@one-tree/library'
import React, { ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavLink = styled(NavLink)`
  position: relative;
  background-color: ${colors.gray};
  color: ${colors.black};
  display: block;
  padding: 6px 20px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  border-top: 3px solid transparent;
  transition: background-color 0.2s;
  :hover {
    background-color: ${colors.darkGray};
  }
  &.active {
    transition: none;
    background-color: ${colors.white};
    border-top: 3px solid ${colors.blue};
  }
`
interface ICardTabProps {
  title: string
  to: string
}
function CardTab(props: ICardTabProps): ReactElement {
  const { title, to } = props
  return (
    <StyledNavLink exact={true} to={to}>
      {title}
    </StyledNavLink>
  )
}
export default CardTab
