import React, { ReactElement, useMemo } from 'react'
import { AxisOptions, Chart as ReactGraph } from 'react-charts'
import styled, { createGlobalStyle } from 'styled-components'
import {
  colors,
  CurrencyCode,
  Loading,
  IGraphData,
  IItemisedData,
  ISeries,
  ReportingWidget,
} from '@one-tree/library'
import { useOrganisation } from '../../../context/OrganisationProvider'
import { shortVoucherWord } from '../../../helpers/FormatHelper'
import { formatCurrency } from '../ReportingHelper'
import logo from '../../../assets/watermark.png'

const StyledReportingWidget = styled(ReportingWidget)`
  min-width: 300px;
`
const GraphWrapper = styled.div`
  min-height: 300px;
  margin: 20px;
  .tick {
    line {
      display: none;
    }
    text {
      /* needed to override react-charts inline styles  */
      font-size: 1rem !important;
    }
  }
`
// It is ridiculous that we have to do this, but there is zero documentation from react-charts on
// how to access the tooltip for styling, and the tooltip itself is devoid of classNames.
const TooltipStyles = createGlobalStyle`
  #react-charts-portal .Cursor div {
    font-size: 1rem !important;
  }
`
interface IGraphProps {
  currency: CurrencyCode
  loading: boolean
  data: IItemisedData[] | undefined
}
function Graph(props: IGraphProps): ReactElement {
  const { currency, loading, data } = props

  const xAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      getValue: (datum: IGraphData): string => datum.label,
    }),
    [],
  )
  const yAxis = useMemo(
    (): AxisOptions<IGraphData> => ({
      min: 0,
      elementType: 'bar',
      getValue: (datum: IGraphData): number => datum.total,
      formatters: {
        scale: (value: number): string => formatCurrency(value, currency, 0),
      },
    }),
    [],
  )

  const seriesData = {
    label: 'Yearly value',
    data:
      !data || !data.length
        ? [{ label: '', total: 0 }]
        : data.map((bin) => ({
          label: bin.label,
          total: bin.total,
        })),
  }

  const series: ISeries[] = useMemo(() => [seriesData], [props])

  const title = `Total ${shortVoucherWord(
    useOrganisation().organisation,
  )} sales - year on year`

  return (
    <StyledReportingWidget title={title} downloadPng="total-sales" logo={logo}>
      <GraphWrapper>
        {!data || loading ? (
          <Loading fullPage={true} />
        ) : (
          <ReactGraph
            options={{
              data: series,
              primaryAxis: xAxis,
              secondaryAxes: [yAxis],
              defaultColors: [colors.blue],
              tooltip: false,
            }}
          />
        )}
      </GraphWrapper>
      <TooltipStyles />
    </StyledReportingWidget>
  )
}
export default Graph
