import moment from 'moment'

export enum TransformDateFormat {
  LongWithTime = 'DD MMMM YYYY [at] HH:mm',
  ShortWithTime = 'DD MMM YYYY [at] HH:mm',
  LongWithoutTime = 'DD MMMM YYYY',
  ShortWithoutTime = 'DD MMM YYYY',
  NumbersAndSlashes = 'DD[/]MM[/]YYYY',
  NumbersAndSlashesWithTime = 'DD[/]MM[/]YYYY [at] HH:mm',
  OnlyYear = 'YYYY',
  MonthAndYear = 'MMM YYYY',
  OnlyTime = 'HH:mm',
  Quarter = 'quarter',
}

export const transformDate = (
  timestamp: number,
  format: TransformDateFormat,
  ignoreTimezone?: boolean,
): string => {
  const momentTime = ignoreTimezone
    ? moment.unix(timestamp).utcOffset(1)
    : moment.unix(timestamp)

  if (format === TransformDateFormat.Quarter) {
    return `Q${momentTime.quarter()} ${momentTime.format('YYYY')}`
  }
  return momentTime.format(format)
}

export const isDateBeforeToday = (timestamp: number): boolean => moment.unix(timestamp) < moment()

export const dateToTimestamp = (date: Date): number => {
  const dateWithTimezone = date.getTime() - date.getTimezoneOffset() * 60000
  return parseInt((dateWithTimezone / 1000).toFixed(0), 10)
}

export const formatInvoiceDate = (date?: number): string => (date ? transformDate(date, TransformDateFormat.NumbersAndSlashes) : '-')

export const formatInvoiceDateWithTime = (date?: number): string => (date
  ? transformDate(date, TransformDateFormat.NumbersAndSlashesWithTime)
  : '-')
